import * as React from 'react'
import { View, Animated, Easing, Text, TouchableOpacity } from 'react-native'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'

import RectangleButton from '@components/button/RectangleButton'
import Icon from '@components/icons/Icons'

import ReactDOM from 'react-dom'

interface AlertButton {
  label: string
  action: () => void
}

interface Props {
  title: string
  text: string
  buttons: AlertButton[]
  cancelable?: boolean
}

const Component = ({ title, text, buttons, cancelable }: Props) => {
  const anim = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 150, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Animated.timing(anim.current, { toValue: 0, duration: 150, easing: Easing.linear, useNativeDriver: true }).start(
      Alert.close
    )
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <Back activeOpacity={1} onPress={cancelable ? close : undefined} />

      <PopupContainer>
        <CloseButton activeOpacity={0.9} onPress={close}>
          <Icon name="close" color="white" size={24} />
        </CloseButton>
        <Title>{title}</Title>
        <ContentText>{text}</ContentText>

        <Buttons>
          {buttons.map(b => (
            <ButtonContainer key={b.label}>
              <RectangleButton label={b.label} color="blue" size="tall" onPress={b.action} />
            </ButtonContainer>
          ))}
        </Buttons>
      </PopupContainer>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
`

const Back = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => `${props.theme.colors.primary}88`};
`

// COMPONENT

const PopupContainer = styled(View)`
  background-color: white;
  border-radius: 32px;
  padding: 32px 24px;
  max-width: 700px;
  min-width: 200px;
  justify-content: center;
  margin: 24px;
`

const CloseButton = styled(TouchableOpacity)`
  background-color: ${props => props.theme.colors.primary};
  position: absolute;
  right: -8;
  top: -16;
  width: 64;
  height: 64;
  border-radius: 32;
  justify-content: center;
  align-items: center;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 35px;
`

const ContentText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  margin-bottom: 42px;
`

const Buttons = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const ButtonContainer = styled(View)`
  margin: 0px 10px;
`

let alertRoot: any

const Alert = {
  open: (conf: Props) => {
    alertRoot = document.getElementById('alert_root')
    if (alertRoot) {
      ReactDOM.render(
        <ThemeProvider theme={Theme}>
          <Component {...conf} />
        </ThemeProvider>,
        alertRoot
      )
    }
  },
  close: () => {
    if (alertRoot) {
      ReactDOM.unmountComponentAtNode(alertRoot)
    }
  },
}

export default Alert
