import ws from '@api/webservice'
import config from '@app/config'

const api = {
  list: (): Promise<RestaurantDetails[]> => {
    return ws<RestaurantDetails[]>(config.ws.restauration.list, 'GET')
  },
  get: (restaurantId: string): Promise<RestaurantDetails> => {
    return ws<RestaurantDetails>(`${config.ws.restauration.get.replace('{restaurantId}', restaurantId)}`, 'GET')
  },
  token: (): Promise<SodexoToken> => {
    return ws<SodexoToken>(config.ws.restauration.token, 'GET')
  },
  badgeCredit: (sodexoToken: string, amount: string): Promise<Url> => {
    return ws<Url>(`${config.ws.restauration.badgeCredit}?amount=${amount}`, 'GET', undefined, true, sodexoToken)
  },
  balance: (sodexoToken: string): Promise<Balance> => {
    return ws<Balance>(`${config.ws.restauration.balance}`, 'GET', undefined, true, sodexoToken)
  },
  history: (sodexoToken: string): Promise<AmountHistory[]> => {
    return ws<AmountHistory[]>(`${config.ws.restauration.history}`, 'GET', undefined, true, sodexoToken)
  },
}

export default api
