import config from '@app/config'
import ws from '@api/webservice'

const api = {
  all: (): Promise<ArticleList> => {
    return ws(`${config.ws.news.all}`, 'GET')
  },
  get: (id: string): Promise<ArticleDetail> => {
    return ws(`${config.ws.news.get.replace('{id}', id)}`, 'GET')
  },
  like: (id: string): Promise<void> => {
    return ws(`${config.ws.news.like.replace('{id}', id)}`, 'POST')
  },
  dislike: (id: string): Promise<void> => {
    return ws(`${config.ws.news.dislike.replace('{id}', id)}`, 'DELETE')
  },
}

export default api
