import * as React from 'react'
import { FlatList, TouchableOpacity, Linking, View, Text, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Root from '@components/root/Root'
import HomeNewsItem from './HomeNewsItem'
import CircleIcon from '@components/icons/CircleIcon'
import Icon from '@components/icons/Icons'
import Animators from '@components/root/Animators'
import HTML from '@components/html/HTMLContent'
import ResponsiveMainContainer from '@components/responsive/ResponsiveMainContainer'
import AnimatedCarousel, { OFFSET } from '@components/image/AnimatedCarousel'

import { useStore } from 'effector-react'
import useReducer from '@store/useReducer'
import * as newsStore from '@store/news/news'
import useI18n from '@store/i18n/useI18n'

import api from '@api/api'

import config from '@app/config'

import { RenderDateAndHourText } from './HomeUtils'

interface Props {
  newsId: string
  news: ArticleDetail
  navigator: Navigation
}

const PICTURE_OFFSET = 300

const NewsDetail = ({ navigator, newsId }: Props) => {
  const i18n = useI18n()
  const articles = useStore(newsStore.store).news
  const [news, setNews] = React.useState<ArticleDetail>()
  const likedNews = useReducer(newsStore.store, s => s.likedNews)
  const isLiked = likedNews.find(n => n === newsId)
  const [nbLike, setnbLike] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [waitingLike, setWaitingLike] = React.useState(false)

  const [width, setWidth] = React.useState(0)

  React.useEffect(() => {
    setNews(undefined)
    setLoading(true)
    api.news
      .get(newsId)
      .then(res => {
        setNews(res)
        setnbLike(res.nbLikes)
        setLoading(false)
      })
      .catch()
  }, [newsId])

  const share = () => {
    Linking.openURL(
      `mailto:?body=${i18n.t('screens.home.actu.share', {
        link: `${config.SERVER_PREFIX}/newsDetail/${news && news.id}`,
      })}`
    )
  }

  const toggleLike = () => {
    setWaitingLike(true)
    if (isLiked) {
      api.news
        .dislike(newsId)
        .then(() => {
          newsStore.actions.dislike(newsId)
          setnbLike(nbLike - 1)
          setWaitingLike(false)
        })
        .catch(() => setWaitingLike(false))
    } else {
      api.news
        .like(newsId)
        .then(() => {
          newsStore.actions.like(newsId)
          setnbLike(nbLike + 1)
          setWaitingLike(false)
        })
        .catch(() => setWaitingLike(false))
    }
  }

  const renderOther = React.useCallback(() => {
    if (!news) {
      return undefined
    }

    return (
      <ResponsiveMainContainer scrollable>
        <SubTitle>{i18n.t('screens.home.actu.similar')}</SubTitle>

        <FlatList
          data={articles.filter(a => a.type === news.type).filter((a, idx) => idx < 3)}
          keyExtractor={item => item.id}
          renderItem={({ item }) => (
            <HomeNewsItem
              news={item}
              i18n={i18n}
              onItemPressed={() => {
                if (navigator) {
                  navigator.pushPath(`/newsDetail/${item.id}`)
                }
              }}
            />
          )}
        />
      </ResponsiveMainContainer>
    )
  }, [navigator, news, i18n, articles])

  if (loading) {
    return (
      <Root navigator={navigator} centered>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  if (!news) {
    return (
      <Root navigator={navigator} centered>
        <Text>{i18n.t('screens.home.actu.errorGet')}</Text>
      </Root>
    )
  }

  return (
    <Root fixed renderContent={renderOther} navigator={navigator} key={newsId}>
      <ResponsiveMainContainer scrollable showsVerticalScrollIndicator={false}>
        <Animators animation="fade_bottom">
          <PictureContainer anim={news.photos.length > 1} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
            <AnimatedCarousel
              width={width - (news.photos.length > 1 ? 2 * OFFSET : 0)}
              height={PICTURE_OFFSET}
              pictures={news.photos.map(p => `${config.SERVER_PREFIX}${p.url}`)}
            />
          </PictureContainer>

          <HeaderInfos>
            <TagContainer>
              <Tag type={news.type}>
                <TagText type={news.type}>{i18n.t(`screens.home.actu.type.${news.type}`)}</TagText>
              </Tag>
            </TagContainer>

            <ItemDate>{RenderDateAndHourText({ news: news, i18n: i18n })}</ItemDate>
          </HeaderInfos>

          <Title>{news.title}</Title>

          {/* HEADER */}
          <Infos>
            <TouchableOpacity activeOpacity={0.8} onPress={toggleLike} disabled={waitingLike}>
              {waitingLike ? (
                <IconLoaderContainer>
                  <ActivityIndicator color={Theme.colors.secondaryText} />
                </IconLoaderContainer>
              ) : (
                <CircleIcon
                  name="heart"
                  size={20}
                  bgColor={isLiked ? Theme.colors.tertiaryLight : Theme.colors.greyMenu}
                  iconColor={isLiked ? Theme.colors.info : Theme.colors.secondaryText}
                  offset={20}
                />
              )}
            </TouchableOpacity>

            <Likes>{i18n.t('screens.home.actu.like', { count: nbLike })}</Likes>

            <Share activeOpacity={0.9} onPress={share}>
              <Icon name="share" color={Theme.colors.primary} size={16} />
              <ShareText>{i18n.t('actions.share')}</ShareText>
            </Share>
          </Infos>

          {/* CONTENT */}
          <Content>
            <HTML html={news.description} />
          </Content>
        </Animators>
      </ResponsiveMainContainer>
    </Root>
  )
}

export default NewsDetail

const PictureContainer = styled(View)<{ anim?: boolean }>`
  padding-left: ${props => (props.anim ? OFFSET : 0)}px;
  margin-bottom: 10px;
`

const TagContainer = styled(View)`
  align-items: flex-start;
`
const Tag = styled(View)<{ type: string }>`
  padding: 5px 15px;
  border-radius: 15px;
  background-color: ${props =>
    props.type === 'event' ? props.theme.colors.primaryLight : props.theme.colors.tertiaryLight};
`

const TagText = styled(Text)<{ type: string }>`
  ${props => props.theme.fonts.genericButton};
  color: ${props => (props.type === 'event' ? props.theme.colors.primary : props.theme.colors.tertiary)};
  font-weight: bold;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  margin: 15px 0px;
`

const SubTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle2};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  margin: 30px 0px;
`

const Infos = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
`

const ItemDate = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.secondaryText};
`

const Likes = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.secondaryText};
  flex: 1;
  margin: 0px 10px;
`

const Share = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  padding: 15px 25px;
  background-color: ${props => props.theme.colors.primaryLight};
`

const ShareText = styled(Text)`
  ${props => props.theme.fonts.genericButton};
  color: ${props => props.theme.colors.primary};
  margin-left: 10px;
  font-weight: bold;
`

const Content = styled(View)`
  margin: 20px 0px;
`

const HeaderInfos = styled(View)`
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
`

const IconLoaderContainer = styled(View)`
  padding: 20px;
  background-color: ${props => props.theme.colors.greyMenu};
  border-radius: 40px;
  justify-content: center;
  align-items: center;
`
