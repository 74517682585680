import * as React from 'react'
import { View, Text, Image, TouchableOpacity, FlatList, ScrollView, ImageSourcePropType } from 'react-native'
import styled from '@theme/styled-components'

import Root from '@components/root/Root'

import useI18n from '@store/i18n/useI18n'
import ImageModal from '@components/image/ImageModal'

interface Service {
  name: string
  logo?: ImageSourcePropType
  photo: ImageSourcePropType
  url?: string
}

interface Props {
  navigator: Navigation
}

const ITEM_WIDTH = 350

const services = [
  {
    name: 'building',
    photo: require('@assets/images/building_spaces.png'),
  },
  {
    name: 'sodexo',
    logo: require('@assets/images/bookingRoom/logos/sodexo.png'),
    photo: require('@assets/images/bookingRoom/sodexo.jpg'),
    url: 'https://www.sodexo-coeurdefense.com/centre-de-conferences/',
  },
  {
    name: 'wojo',
    logo: require('@assets/images/bookingRoom/logos/wojo.png'),
    photo: require('@assets/images/bookingRoom/wojo.jpg'),
    url: 'https://www.wojo.com/search/all/europe/france/ile-de-france/courbevoie',
  },
  {
    name: 'comet',
    logo: require('@assets/images/bookingRoom/logos/comet.png'),
    photo: require('@assets/images/bookingRoom/comet.jpg'),
    url: 'https://www.comet-meetings.com/lieux/comet-la-defense/',
  },
]

const BookingRoomScreen = ({ navigator }: Props) => {
  const i18n = useI18n()

  const renderService = ({ item }: { item: Service }) => {
    const { name, photo, logo, url } = item

    if (!logo || !url) {
      return (
        <Card activeOpacity={0.9} onPress={() => null}>
          <CenterContainer>
            <ImageContainer activeOpacity={0.8} onPress={() => ImageModal.open({ source: photo })}>
              <FullPicture resizeMethod="resize" resizeMode="contain" source={photo} />
            </ImageContainer>
          </CenterContainer>
        </Card>
      )
    }

    return (
      <Card key={name} activeOpacity={0.9} onPress={() => window.open(url, '_blank')}>
        <Picture resizeMethod="resize" resizeMode="cover" source={photo} />

        <InfoContainer>
          <LogoContainer>
            <Logo resizeMode="contain" source={logo} />
          </LogoContainer>
          <Name>{i18n.t(`screens.bookingRoom.${name}.name`)}</Name>
          <Description>{i18n.t(`screens.bookingRoom.${name}.description`)}</Description>
        </InfoContainer>
      </Card>
    )
  }

  return (
    <React.Fragment>
      <Root fixed={false} navigator={navigator} header headerTitle={i18n.t('screens.bookingRoom.title')}>
        <ScrollView>
          <Abstract>{i18n.t('screens.bookingRoom.abstract')}</Abstract>
          <CardsContainer>
            <FlatList
              data={services}
              keyExtractor={item => item.name}
              showsVerticalScrollIndicator={false}
              numColumns={2}
              renderItem={renderService}
            />
          </CardsContainer>
        </ScrollView>
      </Root>
    </React.Fragment>
  )
}

export default BookingRoomScreen

const CardsContainer = styled(View)`
  padding: 20px;
`

const Abstract = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryDark};
  margin: 10px 24px 0px;
`

//CARD

const Card = styled(TouchableOpacity)`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 350px;
  margin-right: 50px;
`

const InfoContainer = styled(View)`
  padding: 43px 24px 25px;
`

const Name = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 12px;
`

const CenterContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const FullPicture = styled(Image)`
  height: 350px;
  width: ${ITEM_WIDTH}px;
`

const Picture = styled(Image)`
  height: 150px;
  width: ${ITEM_WIDTH}px;
`

const Description = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.secondaryText};
`

const LogoContainer = styled(View)`
  width: 56px;
  height: 56px;
  position: absolute;
  left: 16px;
  top: -28px;

  border-radius: 28px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.counterPrimary};
`

const Logo = styled(Image)`
  width: 56px;
  height: 56px;
`
const ImageContainer = styled(TouchableOpacity)`
  flex: 1;
`
