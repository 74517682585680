import * as React from 'react'
import { View, Text, TouchableOpacity, Animated } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'

import { RouteResult, RouteNavigation } from './visioweb'
import { PlaceItem } from './types'
import { ItineraryLockPoint } from './MapScreen'

import Icon from '@components/icons/Icons'
import CircleIcon from '@components/icons/CircleIcon'
import Animators from '@components/root/Animators'
import MapSearch from './MapSearch'

const INITIAL_OFFSET = 100
const SEARCH_OFFSET = 140
const FINAL_OFFSET = 325

interface Props {
  places: Place[]
  startDirection: boolean
  setStartDirection: (b: boolean) => void

  opened: boolean
  setOpened: (b: boolean) => void

  locked?: ItineraryLockPoint
  setLocked: (s?: ItineraryLockPoint) => void

  to?: Place
  setTo: (v?: Place) => void

  destination?: any
  computeRoute: (from: string, to: string, pmr: boolean, lng: string) => Promise<RouteResult>
  nav?: RouteNavigation
  stopRoute: () => void
  nextRoute: () => void
  previousRoute: () => void
}

const MapHeader = ({
  places,
  startDirection,
  setStartDirection,
  opened,
  setOpened,
  locked,
  to,
  setTo,
  setLocked,
  destination,
  computeRoute,
  nav,
  stopRoute,
  nextRoute,
  previousRoute,
}: Props) => {
  const i18n = useI18n()
  const headerAnim = React.useRef(new Animated.Value(INITIAL_OFFSET))

  const [from, setFrom] = React.useState<Place>()

  const [step, setStep] = React.useState(-1)

  const navStep = React.useMemo(() => (nav ? nav.getNbInstructions() : 0), [nav])
  const navInstructions = React.useMemo(
    () => (nav ? Array.from(new Array(navStep)).map((_, idx) => nav.getInstructionData(idx)) : []),
    [nav, navStep]
  )
  const currentNavStep = navInstructions[step]

  const move = headerAnim.current.interpolate({ inputRange: [INITIAL_OFFSET, FINAL_OFFSET], outputRange: [0, 35] })
  const opacity = headerAnim.current.interpolate({ inputRange: [INITIAL_OFFSET, FINAL_OFFSET], outputRange: [0, 1] })

  React.useEffect(() => {
    if (destination) {
      setTo(destination)
      setStartDirection(true)
    }
  }, [destination])

  React.useEffect(() => {
    if (startDirection) {
      setOpened(true)
      Animated.timing(headerAnim.current, { toValue: FINAL_OFFSET, duration: 300 }).start()
    } else {
      setOpened(false)
      Animated.timing(headerAnim.current, { toValue: INITIAL_OFFSET, duration: 300 }).start()
    }
  }, [startDirection])

  React.useEffect(() => {
    if (nav) {
      setStep(0)
      Animated.timing(headerAnim.current, { toValue: SEARCH_OFFSET, duration: 300 }).start()
    } else {
      setStep(-1)
      if (startDirection) {
        Animated.timing(headerAnim.current, { toValue: FINAL_OFFSET, duration: 300 }).start()
      } else {
        Animated.timing(headerAnim.current, { toValue: INITIAL_OFFSET, duration: 300 }).start()
      }
    }
  }, [nav])

  const next = () => {
    if (nav && step < navStep - 1) {
      nextRoute()
      setStep(step + 1)
    }
  }

  const previous = () => {
    if (nav && step > 0) {
      previousRoute()
      setStep(step - 1)
    }
  }

  const invert = (prevFrom?: PlaceItem, prevTo?: PlaceItem) => {
    if (locked === 'FROM') {
      setLocked('TO')
    } else if (locked === 'TO') {
      setLocked('FROM')
    }

    setFrom(prevTo)
    setTo(prevFrom)
  }

  const openSearch = () => {
    MapSearch.open({ i18n, onItemSelected: item => (locked === 'FROM' ? setTo(item) : setFrom(item)), places: places })
  }

  return (
    <HeaderContainer style={{ height: headerAnim.current }}>
      <Back style={{ opacity }} navStarted={!!nav}>
        <BackButton
          activeOpacity={0.9}
          onPress={() => {
            setStartDirection(false)
            setFrom(undefined)
            setTo(undefined)
            if (nav) {
              stopRoute()
            }
          }}
        >
          <Icon name="back" size={25} />
        </BackButton>
      </Back>

      <Title style={{ transform: [{ translateY: move }] }}>
        {i18n.t(`screens.map.${startDirection ? 'itinerary' : 'title'}`)}
      </Title>

      {opened &&
        (!nav ? (
          <Animators animation="fade_right">
            <InputLine navStarted={!!nav}>
              <InputContainer>
                <Input disabled={locked === 'FROM'} activeOpacity={0.9} onPress={openSearch}>
                  <InputText disabled={!from && locked === 'TO'}>
                    {from ? from.name : i18n.t('screens.map.start')}
                  </InputText>
                </Input>
                <InputSeparator />

                <Input disabled={locked === 'TO'} activeOpacity={0.9} onPress={openSearch}>
                  <InputText disabled={locked === 'FROM'}>{to ? to.name : i18n.t('screens.map.end')}</InputText>
                </Input>
              </InputContainer>

              <Invert activeOpacity={0.9} onPress={() => invert(from, to)}>
                <CircleIcon
                  name="invert"
                  size={20}
                  offset={10}
                  iconColor={Theme.colors.primary}
                  bgColor={Theme.colors.primaryLight}
                />
              </Invert>
            </InputLine>

            <Action
              activeOpacity={0.9}
              disabled={!from || !to}
              onPress={() => {
                if (from && to) {
                  computeRoute(from.id, to.id, true, i18n.lang)
                }
              }}
            >
              <ActionText>{i18n.t('actions.itinerary')}</ActionText>
            </Action>
          </Animators>
        ) : (
          !!currentNavStep && (
            <NavContainer>
              <ItineraryContainer>
                <RouteText>{currentNavStep.detail}</RouteText>
              </ItineraryContainer>

              <Points>
                {Array.from(new Array(navStep)).map((p, i) => (
                  <Point active={i === step} key={i} />
                ))}
              </Points>

              <IconsContainer>
                <IconContainer activeOpacity={0.9} onPress={previous} disabled={!(step > 0)}>
                  <CircleIcon
                    name="chevronLeft"
                    size={20}
                    offset={10}
                    iconColor={step > 0 ? Theme.colors.primary : Theme.colors.disable}
                    bgColor={step > 0 ? Theme.colors.primaryLight : Theme.colors.disableItem}
                  />
                </IconContainer>

                <IconContainer activeOpacity={0.9} onPress={next} disabled={!(step < navStep - 1)}>
                  <CircleIcon
                    name="chevronRight"
                    size={20}
                    offset={10}
                    iconColor={step < navStep - 1 ? Theme.colors.primary : Theme.colors.disable}
                    bgColor={step < navStep - 1 ? Theme.colors.primaryLight : Theme.colors.disableItem}
                  />
                </IconContainer>

                <IconContainer activeOpacity={0.9} onPress={stopRoute}>
                  <CircleIcon
                    name="close"
                    size={20}
                    offset={10}
                    iconColor={Theme.colors.primary}
                    bgColor={Theme.colors.primaryLight}
                  />
                </IconContainer>
              </IconsContainer>
            </NavContainer>
          )
        ))}
    </HeaderContainer>
  )
}

// CONTAINERS

const HeaderContainer = styled(Animated.View)`
  flex-direction: column;
  padding: 30px 24px;
`
const Back = styled(Animated.View)<{ navStarted: boolean }>`
  position: absolute;
  top: ${props => (props.navStarted ? '10px' : '25px')};
  left: 19px;
`
const BackButton = styled(TouchableOpacity)`
  padding: 5px;
`
const InputLine = styled(View)<{ navStarted: boolean }>`
  flex-direction: row;
  align-items: center;
  margin-top: ${props => (props.navStarted ? '10px' : '60px')};
`
const InputContainer = styled(View)`
  flex: 1;
`
const Input = styled(TouchableOpacity)`
  height: 40px;
  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 7px;
  border: 1px solid ${props => props.theme.colors.inputBorder};
  padding: 0px 20px;
  justify-content: center;
`
const Invert = styled(TouchableOpacity)`
  padding: 15px;
`
const Action = styled(TouchableOpacity)`
  padding: 15px;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 12px;
  margin: 25px 0px;

  ${props => props.theme.constants.shadow};
`
const NavContainer = styled(View)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  align-items: center;
  justify-content: center;
`
const IconsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 24px;
  right: 18px;
`

const IconContainer = styled(TouchableOpacity)`
  margin: 0px 6px;
`
const ItineraryContainer = styled(View)`
  max-width: 500px;
  width: 100%;
`

// TEXTES

const Title = styled(Animated.Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`
const InputText = styled(Text)<{ disabled: boolean }>`
  ${props => props.theme.fonts.genericText};
  color: ${props => (props.disabled ? props.theme.colors.disable : props.theme.colors.primaryText)};
`
const ActionText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.counterPrimary};
  font-weight: bold;
`
const RouteText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryText};
`

// AUTRES

const InputSeparator = styled(View)`
  height: 12px;
`
const Points = styled(View)`
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin-top: 20px;
`
const Point = styled(View)<{ active: boolean }>`
  margin: 0px 8px;
  background-color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.disable)};
  height: ${props => (props.active ? 15 : 10)}px;
  width: ${props => (props.active ? 15 : 10)}px;
  border-radius: 15px;
`

export default MapHeader
