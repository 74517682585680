import * as React from 'react'
import { Text } from 'react-native'

import Root from '@components/root/Root'

interface Props {
  navigator: Navigation
}

const TodoScreen = ({ navigator }: Props) => {
  return (
    <Root centered navigator={navigator}>
      <Text>Cette fonctionnalité sera disponible prochainement.</Text>
    </Root>
  )
}

export default TodoScreen
