import { createEvent, createStore } from 'effector'

export const actions = {
  setNews: createEvent<Article[]>('setNews'),
  like: createEvent<string>('like'),
  dislike: createEvent<string>('dislike'),
  setDrawerHeight: createEvent<number>('setDrawerHeight'),
}

const initialState: NewsState = {
  news: [],
  likedNews: [],
  drawerHeight: 0,
}

export const store = createStore<NewsState>(initialState)
  .on(actions.setNews, (s, news) => ({ ...s, news }))
  .on(actions.like, (s, id) => ({
    ...s,
    news: s.news.map(n => (n.id === id ? { ...n, nbLikes: n.nbLikes + 1 } : n)),
    likedNews: [...s.likedNews, id],
  }))
  .on(actions.dislike, (s, id) => ({
    ...s,
    news: s.news.map(n => (n.id === id ? { ...n, nbLikes: n.nbLikes - 1 } : n)),
    likedNews: s.likedNews.filter(s => s !== id),
  }))
  .on(actions.setDrawerHeight, (s, newHeight) => ({ ...s, drawerHeight: newHeight }))
