import * as React from 'react'
import { View, Animated, Easing, Text, TouchableOpacity, ScrollView } from 'react-native'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'

import RectangleButton from '@components/button/RectangleButton'
import Icon from '@components/icons/Icons'
import HTML from '@components/html/HTMLContent'

import ReactDOM from 'react-dom'

interface Props {
  title: string
  text: string
  label: string
  onClose: () => void
  textInHtml?: boolean
}

const Component = ({ title, text, label, onClose, textInHtml }: Props) => {
  const anim = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const onPress = () => {
    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      () => {
        onClose()
        Popup.close()
      }
    )
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <Back />
      <Container>
        <PopupContainer>
          <CloseButton activeOpacity={0.9} onPress={onPress}>
            <Icon name="close" color="white" size={24} />
          </CloseButton>
          <Title>{title}</Title>
          {textInHtml ? (
            <ContentHtml>
              <HTML html={text} />
            </ContentHtml>
          ) : (
            <ContentView>
              <ContentText>{text}</ContentText>
            </ContentView>
          )}
          <RectangleButton label={label} color="blue" size="tall" onPress={onPress} />
        </PopupContainer>
      </Container>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
`

const Back = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.primary};
  opacity: 0.5;
`

// COMPONENT

const Container = styled(View)`
  flex: 1;
  justify-content: center;
`

const PopupContainer = styled(View)`
  background-color: white;
  border-radius: 32px;
  padding: 32px 24px;
  max-height: 60%;
  justify-content: center;
  margin: 24px;
`

const CloseButton = styled(TouchableOpacity)`
  background-color: ${props => props.theme.colors.primary};
  position: absolute;
  right: -8;
  top: -16;
  width: 64;
  height: 64;
  border-radius: 32;
  justify-content: center;
  align-items: center;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 35px;
`

const ContentText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  margin-bottom: 42px;
`

const ContentView = styled(ScrollView)`
  margin-bottom: 10px;
`

const ContentHtml = styled(ScrollView)`
  margin: 10px 0px;
`

let alertRoot: any

const Popup = {
  open: (title: string, text: string, label: string, onClose: () => void, textInHtml?: boolean) => {
    alertRoot = document.getElementById('alert_root')
    if (alertRoot) {
      ReactDOM.render(
        <ThemeProvider theme={Theme}>
          <Component title={title} text={text} label={label} onClose={onClose} textInHtml={textInHtml} />
        </ThemeProvider>,
        alertRoot
      )
    }
  },
  close: () => {
    if (alertRoot) {
      ReactDOM.unmountComponentAtNode(alertRoot)
    }
  },
}

export const openPopUp = (title: string, text: string, label: string, onClose: () => void, textInHtml?: boolean) => {
  Popup.open(title, text, label, onClose, textInHtml)
}

export default Popup
