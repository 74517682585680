import * as React from 'react'
import { View, Text, ActivityIndicator, TouchableOpacity, ScrollView, FlatList } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'

import api from '@api/api'

import { addDays, isSameDay } from 'date-fns'
import { getExcludedDays, getFirstSelectableDate } from './utils'
import { Animation } from '@components/root/Animators'

import Root from '@components/root/Root'
import CalendarDatePicker from '@components/picker/CalendarDatePicker'
import Picker from '@components/select/Picker'
import Icon from '@components/icons/Icons'
import Button from '@components/button/RectangleButton'
import Alert from '@components/alert/Alert'

interface Props {
  navigator: Navigation
  proId: string
  proName: string
}

interface CalendarByActivity {
  activity: string
  calendar: CalendarItem[]
}

const BookingProfessional = ({ navigator, proId, proName }: Props) => {
  const i18n = useI18n()

  const [calendar, setCalendar] = React.useState<CalendarByActivity[]>([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [numberOfDayBookable, setNumberOfDayBookable] = React.useState<number>(1)

  const [dateValue, setDateValue] = React.useState<string | Date>()
  const [selectedActivity, setSelectedActivity] = React.useState<string>()
  const [selectedTimeSlotId, setSelectedTimeSlotId] = React.useState<string>()

  const [scrollViewHeight, setScrollViewHeight] = React.useState(0)
  const [viewHeight, setViewHeight] = React.useState(0)

  const tomorrow = addDays(new Date(), 1)

  const allActivities = calendar.map(c => c.activity)

  /* Tous les objets calendrier que renvoi le serveur, qui correspondent à l'activité sélectionné */
  const allAvailableCalendar = React.useMemo(
    () => (selectedActivity ? calendar.filter(item => item.activity === selectedActivity)[0].calendar : []),
    [selectedActivity, calendar]
  )

  /* Id de la date sélectionnée */
  const calendarId = dateValue
    ? allAvailableCalendar.find(c => c.date === i18n.t('screens.booking.dateString', { date: new Date(dateValue) }))?.id
    : undefined

  React.useEffect(() => {
    getProsCalendar()
  }, [])

  const buttonIsAbsolutePos = React.useMemo(
    () =>
      viewHeight <=
      scrollViewHeight -
        71 /* 71 est la taille du bouton + les paddings de celui-ci + la distance en plus à laisser entre le bouton et la liste */,
    [scrollViewHeight, viewHeight]
  )

  /* Trie une array d'objets CalendarItem selon l'activité */
  const sortByActivityAndDate = (calendar: CalendarItem[]) =>
    calendar.reduce((acc, cur) => {
      cur.timeSlots.map(t => {
        const index = acc.findIndex(c => c.activity === t.activity)

        if (index > -1) {
          const dateIndex = acc[index].calendar.findIndex(c => c.date === cur.date)

          if (dateIndex > -1) {
            acc[index].calendar[dateIndex].timeSlots.push(t)
          } else {
            acc[index].calendar.push({ id: cur.id, date: cur.date, timeSlots: [t] })
          }
        } else {
          acc.push({ activity: t.activity, calendar: [{ id: cur.id, date: cur.date, timeSlots: [t] }] })
        }
      })
      return acc
    }, [] as CalendarByActivity[])

  const getProsCalendar = () => {
    resetValue('start')
    setLoading(true)
    api.booking
      .getCalendar(proId)
      .then(res => {
        setCalendar(sortByActivityAndDate(res.calendars))
        setNumberOfDayBookable(res.numberOfDayBookable)
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  /* Permet de réinitialiser les valeur du formulaire lorsque des entrées précédentes sont modifiées */
  const resetValue = (currentStep: 'start' | 'activity' | 'date') => {
    switch (currentStep) {
      case 'start':
        /* L'ordre est important pour le cas de 'start' mais pas pour les deux autres cas */
        setSelectedTimeSlotId(undefined)
        setDateValue(undefined)
        setSelectedActivity(undefined)
        break
      case 'activity':
        setDateValue(undefined)
      case 'date':
        setSelectedTimeSlotId(undefined)
        break
    }
  }

  const openActivityPicker = () =>
    Picker.open({
      title: i18n.t('screens.booking.selectActivity'),
      onItemSelected: opt => {
        resetValue('activity')
        setSelectedActivity(opt.value)
      },
      data: allActivities.map(a => ({ label: a, value: a })),
    })

  const openCalendarPicker = () => {
    CalendarDatePicker.open({
      defaultSelected: getFirstSelectableDate(
        i18n,
        allAvailableCalendar.map(c => c.date)
      ),
      selectedDate: dateValue,
      onSelect: d => {
        resetValue('date')
        setDateValue(d)
      },
      minDate: tomorrow,
      maxDate: addDays(tomorrow, numberOfDayBookable - 1),
      excludedDates: getExcludedDays(
        i18n,
        i18n.t('screens.booking.dateString', { date: tomorrow }),
        numberOfDayBookable,
        allAvailableCalendar.map(c => c.date)
      ),
    })
  }

  const onTimeSlotPress = (selectedId: string, selected: boolean) => {
    if (selected) {
      setSelectedTimeSlotId(undefined)
    } else {
      setSelectedTimeSlotId(selectedId)
    }
  }

  const createBooking = () => {
    if (selectedTimeSlotId && dateValue && calendarId) {
      api.booking
        .createBooking({ idCalendar: calendarId, idTimeSlot: selectedTimeSlotId })
        .then(() => {
          Alert.open({
            title: i18n.t('screens.booking.bookingConfirmed'),
            text: i18n.t('screens.booking.confirmedText'),
            cancelable: true,
            buttons: [{ label: i18n.t('common.close'), action: Alert.close }],
            centerButtons: true,
            darkBackground: true,
          })
          navigator.goBack()
        })
        .catch(() => {
          Alert.open({
            title: i18n.t('common.warning'),
            text: i18n.t('errors.tryAgain'),
            buttons: [
              {
                label: i18n.t('common.ok'),
                action: Alert.close,
              },
            ],
            cancelable: true,
            centerButtons: true,
            darkBackground: true,
          })
        })
    }
  }

  const renderPicker = (title: string, onSelect: () => void, label: string, unselected?: boolean) => (
    <Animation>
      <SelectorTitle>{title}</SelectorTitle>
      <PickerContainer activeOpacity={0.9} onPress={onSelect}>
        <PickerText empty={!!unselected}>{label}</PickerText>
        <Icon name="chevronDown" color={Theme.colors.primary} size={24} />
      </PickerContainer>
    </Animation>
  )

  const renderTimeSlot = ({ item }: { item: TimeSlotItem }) => {
    const selected = selectedTimeSlotId === item.id

    return (
      <Animation key={item.id}>
        <TimeSlotContainer
          selected={selected}
          onPress={() => onTimeSlotPress(item.id, selected)}
          disabled={!!item.booked}
          booked={!!item.booked}
        >
          <PickerText selected={selected} booked={!!item.booked}>
            {i18n.t('screens.booking.scheduleTime', { date1: new Date(item.startHour), date2: new Date(item.endHour) })}
          </PickerText>

          <PriceText selected={selected} booked={!!item.booked}>
            {i18n.t('screens.booking.price', { price: item.price })}
          </PriceText>
        </TimeSlotContainer>
      </Animation>
    )
  }

  if (loading) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  if (error) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')} headerSubTitle={proName}>
        <EmptyContainer>
          <ErrorText>{i18n.t('errors.tryAgain')}</ErrorText>
        </EmptyContainer>
      </Root>
    )
  }

  return (
    <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')} headerSubTitle={proName}>
      <>
        <MainContainer>
          <ScrollView
            showsVerticalScrollIndicator={false}
            onLayout={evt => setScrollViewHeight(evt.nativeEvent.layout.height)}
          >
            <View onLayout={evt => setViewHeight(evt.nativeEvent.layout.height)}>
              {/* Activity Picker */}
              {renderPicker(
                i18n.t('screens.booking.activity'),
                openActivityPicker,
                !!selectedActivity ? selectedActivity : i18n.t('screens.booking.selectActivity'),
                !selectedActivity
              )}

              {/* Date Picker */}
              {!!selectedActivity &&
                renderPicker(
                  i18n.t('screens.booking.date'),
                  openCalendarPicker,
                  !!dateValue
                    ? i18n.t('screens.booking.dateFormatted', { date: new Date(dateValue) })
                    : i18n.t('screens.booking.noDate'),
                  !dateValue
                )}

              {/* Time Slot Picker */}
              {!!dateValue && (
                <>
                  <SelectorTitle>{i18n.t('screens.booking.timeSlot')}</SelectorTitle>
                  <FlatList
                    data={
                      allAvailableCalendar.filter(item => isSameDay(new Date(item.date), new Date(dateValue)))[0]
                        .timeSlots
                    }
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={false}
                    renderItem={renderTimeSlot}
                  />
                </>
              )}

              {!buttonIsAbsolutePos && (
                <ButtonContainer>
                  <Button
                    label={i18n.t('actions.validate')}
                    color="blue"
                    size="tall"
                    onPress={createBooking}
                    disabled={!selectedTimeSlotId}
                    boldText
                  />
                </ButtonContainer>
              )}
            </View>
          </ScrollView>
        </MainContainer>

        {buttonIsAbsolutePos && (
          <AbsoluteButtonContainer>
            <Button
              label={i18n.t('actions.validate')}
              color="blue"
              size="tall"
              onPress={createBooking}
              disabled={!selectedTimeSlotId}
              boldText
            />
          </AbsoluteButtonContainer>
        )}
      </>
    </Root>
  )
}

// CONTAINERS

const MainContainer = styled(View)`
  flex: 1;
  padding: 0px 24px;
`
const PickerContainer = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 12px;
  border: 1px solid ${props => props.theme.colors.disableItem};

  padding: 13px 14px 13px 16px;
  margin-bottom: 10px;
`
const AbsoluteButtonContainer = styled(View)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 15px;
  padding: 0px 20px;
`
const ButtonContainer = styled(View)`
  margin: 14px 0px 15px;
`
const TimeSlotContainer = styled(TouchableOpacity)<{ selected: boolean; booked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${props =>
    props.booked
      ? props.theme.colors.greyMenu
      : props.selected
      ? props.theme.colors.primary
      : props.theme.colors.counterPrimary};
  padding: 22px 22px 22px 21px;
  border-radius: 12px;
  margin-bottom: 16px;
`
const EmptyContainer = styled(View)`
  padding: 24px;
  justify-content: center;
  align-items: center;
`

// TEXTES

const SelectorTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryDark};
  margin: 26px 0px 20px;
`
const PickerText = styled(Text)<{ selected?: boolean; booked?: boolean; empty?: boolean }>`
  ${props => props.theme.fonts.genericBody3};
  color: ${props =>
    props.empty
      ? props.theme.colors.secondaryText
      : !!props.booked
      ? props.theme.colors.disable
      : !!props.selected
      ? props.theme.colors.counterPrimary
      : props.theme.colors.primaryDark};
`
const PriceText = styled(Text)<{ selected?: boolean; booked?: boolean }>`
  ${props => props.theme.fonts.genericBody2};
  color: ${props =>
    !!props.booked
      ? props.theme.colors.disable
      : !!props.selected
      ? props.theme.colors.counterPrimary
      : props.theme.colors.primaryDark};
`
const ErrorText = styled(Text)`
  ${props => props.theme.fonts.genericText};
`

export default BookingProfessional
