// ThemeWeb is empty for mobile and contains overrides for web
import ThemeWeb from './ThemeWeb'

const Fonts = {
  beVietnamRegular: 'BeVietnam-Regular',
  beVietnamBold: 'BeVietnam-Bold',
  interRegular: 'Inter-Regular',
  interBold: 'Inter-Bold',
}

const theme: Theme = {
  colors: {
    // main colors
    primary: '#3D61E0',
    primaryLight: '#E2E7FA',
    primaryDark: '#22304A',
    counterPrimary: '#FFFFFF',

    secondary: '#26D18A',
    secondaryLight: '#E0F8EE',

    tertiary: '#E0783D',
    tertiaryLight: '#FAEBE2',

    info: '#E8415F',
    infoLight: '#FFF1F0',

    background: '#F6F9FC',
    webBackground: '#F6F9FC',
    drawerBackground: '#F6F9FC',
    disable: '#727B8B',
    disableItem: '#DEDEDE',
    separator: '#EFEFEF',
    darkenedBackground: '#00000088',
    greyMenu: '#EDEEF1',
    error: '#F88C6A',
    inputPlaceholder: '#ABB3C2',
    authInfo: '#9bade8',
    inputBorder: '#dedede',

    open: '#26D18A',
    closed: '#E8415F',

    sublineBlue: '#d6d7da',
    boxNServices: '#FF5D4E',

    // text
    primaryText: '#22304A',
    secondaryText: '#727B8B',

    ...ThemeWeb.colors,
  },
  fonts: {
    genericText: {
      fontSize: 12,
      fontFamily: Fonts.interRegular,
      color: '#22304A',
    },
    menuTitle: {
      fontFamily: Fonts.beVietnamBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 34,
      lineHeight: 50,
      letterSpacing: -1.5,
      color: '#22304A',
    },
    genericTitle1: {
      fontSize: 34,
      lineHeight: 38,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -1.5,
      color: '#C4C4C4',
    },
    genericTitle2: {
      fontSize: 24,
      lineHeight: 35,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -1,
      color: '#C4C4C4',
    },
    genericTitle3: {
      fontSize: 20,
      lineHeight: 24,
      fontFamily: Fonts.beVietnamRegular,
      letterSpacing: -0.5,
      color: '#C4C4C4',
    },
    genericTitle3Bold: {
      fontSize: 20,
      lineHeight: 24,
      fontFamily: Fonts.beVietnamBold,
      letterSpacing: -0.5,
      color: '#C4C4C4',
    },
    genericTitle4: {
      fontSize: 18,
      lineHeight: 24,
      fontFamily: Fonts.beVietnamRegular,
      letterSpacing: -0.5,
      color: '#C4C4C4',
    },
    genericTitle4Bold: {
      fontSize: 18,
      lineHeight: 24,
      fontFamily: Fonts.beVietnamBold,
      letterSpacing: -0.5,
      color: '#C4C4C4',
    },
    genericBody1: {
      fontSize: 12,
      lineHeight: 14,
      fontFamily: Fonts.interRegular,
      color: '#C4C4C4',
    },
    genericBody2: {
      fontSize: 14,
      lineHeight: 24,
      fontFamily: Fonts.interRegular,
      color: '#C4C4C4',
    },
    genericBody3: {
      fontSize: 16,
      lineHeight: 24,
      fontFamily: Fonts.interRegular,
      color: '#C4C4C4',
    },
    genericBody3Bold: {
      fontSize: 16,
      lineHeight: 24,
      fontFamily: Fonts.interBold,
      color: '#C4C4C4',
    },

    genericButton: {
      fontSize: 14,
      lineHeight: 14,
      fontFamily: Fonts.interRegular,
      color: '#C4C4C4',
    },
    genericLabel: {
      fontSize: 12,
      lineHeight: 14,
      fontFamily: Fonts.interBold,
      fontWeight: 'bold',
      color: '#C4C4C4',
    },
    genericLabelSmall: {
      fontSize: 10,
      lineHeight: 11,
      fontFamily: Fonts.interBold,
      fontWeight: 'bold',
      color: '#C4C4C4',
    },
    amount: {
      fontSize: 48,
      lineHeight: 70,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#fff',
    },
    amountUnit: {
      fontSize: 24,
      lineHeight: 70,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#fff8',
    },
    amountInput: {
      fontSize: 48,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#fff',
    },
    codeDigit: {
      fontFamily: Fonts.beVietnamBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 48,
      lineHeight: 70,
      letterSpacing: -2,
      color: '#fff',
    },
    buttonLabel: {
      fontSize: 16,
      lineHeight: 24,
      fontFamily: Fonts.interBold,
      color: '#C4C4C4',
    },
    accountPercent: {
      fontSize: 32,
      lineHeight: 47,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#3D61E0',
    },
    accountUnit: {
      fontSize: 16,
      lineHeight: 41,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -1,
      color: '#3d61e099',
    },
    accountInput: {
      fontSize: 48,
      lineHeight: 70,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#3d61e099',
    },
    tagLabel: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: Fonts.interBold,
      fontWeight: 'bold',
      color: '#C4C4C4',
    },
    amountDetail: {
      fontSize: 23,
      lineHeight: 34,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#fff',
    },
    sliderAmount: {
      fontSize: 28,
      lineHeight: 30,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#9aaae6',
    },
    sliderUnit: {
      fontSize: 16,
      lineHeight: 20,
      fontFamily: Fonts.beVietnamBold,
      fontWeight: 'bold',
      letterSpacing: -2,
      color: '#9aaae6',
    },

    ...ThemeWeb.fonts,
  },
  constants: {
    shadow: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,

      elevation: 4,
    },

    lowerShadow: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,

      elevation: 2,
    },

    smallScreenBreakpoint: 700,

    ...ThemeWeb.constants,
  },
}

export default theme
