import * as React from 'react'
import { ActivityIndicator, Linking, Text, TextInput, TouchableOpacity, View } from 'react-native'

import BadgeHistory from '@components/reload/BadgeHistory'
import Alert from '@components/alert/Alert.web'
import SubmitButton from '@components/reload/SubmitButton'
import Root from '@components/root/Root'
import ResponsiveMainContainer from '@components/responsive/ResponsiveMainContainer'
import Animators, { Animation } from '@components/root/Animators'
import Card from '@screens/badge/Card'
import { Component as BadgeInputComponent } from './BadgeInput'

import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as userStore from '@store/user/user'
import * as restoStore from '@store/resto/resto'

import { formatAmount } from '@utils/NumberUtils'

import api from '@api/api'

import useBreakPoints from '@layout/breakpoint'

interface Props {
  navigator: Navigation
}
const AMOUNTS = [20, 30, 50, 100]

const BadgeScreen = ({ navigator }: Props) => {
  const i18n = useI18n()
  const [breakPoints] = useBreakPoints()

  const [badgeAvailable, setBadgeAvailable] = React.useState(true)
  const [history, setHistory] = React.useState<AmountHistory[]>([])
  const [loading, setLoading] = React.useState(true)
  const [value, setValue] = React.useState('')
  const balance = useReducer(restoStore.store, s => s.balance)

  const user = useReducer(userStore.store, s => s.user)
  const badge = user && user.badge

  React.useEffect(() => {
    api.resto.token().then(token => {
      api.resto
        .balance(token.token)
        .then(balance => {
          if (balance.balance === undefined || balance.balance === null) {
            // Une balance à undefined ou à null indique un badge invalide
            setBadgeAvailable(false)
            restoStore.actions.resetBalance()
          } else {
            restoStore.actions.setBalance(balance.balance)
          }
        })
        .catch(() => setBadgeAvailable(false))
        .finally(() => setLoading(false))

      api.resto.history(token.token).then(setHistory)
    })
  }, [badge])

  const updateBadge = (value: string) => {
    const next: UserUpdate = { ...user, badge: value }

    api.account.update(next).then(() => api.account.get().then(userStore.actions.setUser))
  }

  const renderContent = () => {
    if (!badgeAvailable) {
      return <BadgeInputComponent i18n={i18n} onValidate={updateBadge} value={badge || ''} />
    } else {
      return <BadgeHistory history={history} />
    }
  }

  const onChange = (val: string) => {
    setValue(val.replace(/[^0-9]/g, ''))
  }
  const addAmount = () => {
    const val = Number(value)
    if (!isNaN(val)) {
      api.resto.token().then(token => {
        api.resto
          .badgeCredit(token.token, value)
          .then(url => Linking.openURL(url.url))
          .catch(() =>
            Alert.open({
              title: i18n.t('screens.reload.error.title'),
              text: i18n.t('screens.reload.error.message'),
              buttons: [{ label: i18n.t('common.ok'), action: () => null }],
            })
          )
      })
    }
  }

  return (
    <Root navigator={navigator} fixed>
      {loading ? (
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      ) : (
        <Dual2to1Container breakPoint={breakPoints}>
          <TwoThirdShareView breakPoint={breakPoints}>
            <ResponsiveMainContainer scrollable>
              <TitleAndCardView>
                <ScreenTitle>{i18n.t('screens.reload.title')}</ScreenTitle>
                <Animation>
                  <Card amount={formatAmount(balance || 0)} available={badgeAvailable} hideAddButton={true} />
                </Animation>
              </TitleAndCardView>
              {badgeAvailable && (
                <Animators>
                  <RefillLine>
                    <InputContainer>
                      <Input
                        maxLength={4}
                        value={value}
                        onChangeText={onChange}
                        keyboardType="numeric"
                        placeholder="0"
                        placeholderTextColor="#727B8B"
                        breakPoint={breakPoints}
                      />
                    </InputContainer>
                    <Unit> €</Unit>
                  </RefillLine>
                  <MinReloadView value={Number(value)}>
                    <MinReload>{i18n.t('screens.reload.minAmount')}</MinReload>
                  </MinReloadView>
                  {breakPoints > 800 && (
                    <Amounts>
                      {AMOUNTS.map(am => (
                        <AmountItem key={`${am}`} activeOpacity={0.9} onPress={() => setValue(`${am}`)}>
                          <AmountItemText>{`${am}€`}</AmountItemText>
                        </AmountItem>
                      ))}
                    </Amounts>
                  )}
                  <SubmitButton value={value} addAmount={addAmount} />
                </Animators>
              )}
            </ResponsiveMainContainer>
          </TwoThirdShareView>
          <Separator breakPoint={breakPoints} />
          <ThirdShareView>
            <Container breakPoint={breakPoints}>{renderContent()}</Container>
          </ThirdShareView>
        </Dual2to1Container>
      )}
    </Root>
  )
}
export default BadgeScreen
const ScreenTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
  margin-bottom: 80px;
`
const Input = styled(TextInput)<{ breakPoint: number }>`
  ${props => props.theme.fonts.amountInput};
  color: ${props => props.theme.colors.primary};
  margin: ${props => (props.breakPoint < 800 ? '5px' : '10px 0px 20px')};
  text-align: right;
  width: 150px;
`

const InputContainer = styled(View)`
  flex-shrink: 1;
  justify-content: center;
`

const Amounts = styled(View)`
  margin: 10px;
  flex-direction: row;
  justify-content: center;
`

const AmountItem = styled(TouchableOpacity)`
  align-items: center;
  justify-content: center;
  background-color: #edeef1;
  border-radius: 25px;
  padding: 12px 20px;
  margin: 0px 10px;
`

const AmountItemText = styled(Text)`
  ${props => props.theme.fonts.generic};
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

const RefillLine = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const Unit = styled(Text)`
  ${props => props.theme.fonts.amountInput};
  color: ${props => props.theme.colors.secondaryText};
  margin-left: 5px;
`
const TitleAndCardView = styled(View)`
  margin-right: 50px;
`
const Dual2to1Container = styled(View)<{ breakPoint: number }>`
  flex: 1;
  ${props => props.breakPoint > 800 && 'flex-direction: row;'};
  background-color: ${props => props.theme.colors.webBackground};
`
const TwoThirdShareView = styled(View)<{ breakPoint: number }>`
  flex: ${props => (props.breakPoint < 1200 ? '2' : '1')};
`
const ThirdShareView = styled(View)`
  flex: 1;
`
const Container = styled(View)<{ breakPoint: number }>`
  flex: 1;
  background-color: ${props => props.theme.colors.webBackground};
  ${props => (props.breakPoint > 1200 ? 'padding: 0px 40px;' : 'padding: 0px 10px;')};
`
const Separator = styled(View)<{ breakPoint: number }>`
  ${props => (props.breakPoint > 1200 ? 'width: 1px; margin: 65px 0px;' : 'height: 1px; margin: 15px 0px;')};
  background-color: #dedede;
`
const MinReloadView = styled(View)<{ value: number }>`
  ${props => (props.value >= 20 || props.value === 0) && 'visibility: hidden;'}
`
const MinReload = styled(Text)`
  ${props => props.theme.fonts.genericlabel};
  color: ${props => props.theme.colors.secondaryText};
  align-self: center;
`
