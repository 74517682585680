import * as React from 'react'
import { View, TouchableOpacity, Text, TextInput, Keyboard, Image, ScrollView, Platform } from 'react-native'

import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { CodeField, Cursor } from 'react-native-confirmation-code-field'
import Svg, { Defs, LinearGradient, Stop, Rect } from 'react-native-svg'

import styled from '@theme/styled-components'
import theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'
import * as userStore from '@store/user/user'

import RoundCheckButton from '@components/button/RoundCheckButton'
import { openPopUp } from '@components/popup/Popup'
import Animators, { Animation } from '@components/root/Animators'
import Alert from '@components/alert/Alert'

import apis from '@api/api'

import Logger from '@utils/logger'

import useBreakPoints from '@app/ui/layout/breakpoint'

interface Values {
  email: string
  password?: string
}

interface PasswordValues {
  password: string
  confirmPassword: string
}

interface SignInValues {
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
  cgvCheck: boolean
}

const Background = () => {
  return (
    <Svg height="100%" width="100%">
      <Defs>
        <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="100%">
          <Stop offset="0" stopColor="#3D61E0" stopOpacity="1" />
          <Stop offset="1" stopColor="#183FC9" stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <Rect x="0" y="0" height="100%" width="100%" fill="url(#grad)" />
      <Rect rx="7%" ry="4%" x="15" y="-10%" height="20%" width="43px" fill="rgba(255, 255, 255, 0.08)" />
      <Rect rx="7%" ry="4%" x="80%" y="80%" height="30%" width="43px" fill="rgba(255, 255, 255, 0.08)" />
    </Svg>
  )
}

const ATTEMPTS_MAX = 3

const AuthenticationScreen = () => {
  const i18n = useI18n()
  const [verifiedMail, setVerifiedMail] = React.useState<string>()
  const [codeOK, setCodeOK] = React.useState(false)
  const [codeAttempts, setCodeAttempts] = React.useState(0)
  const [modifyPassword, setModifyPassword] = React.useState(false)
  const [digitCode, setDigitCode] = React.useState('')
  const [codeError, setCodeError] = React.useState(false)
  const [codeToken, setCodeToken] = React.useState<string>()

  const [breakPoint] = useBreakPoints()

  const triggerErrorAlert = () =>
    Alert.open({
      title: i18n.t('common.warning'),
      text: i18n.t('errors.tryAgain'),
      buttons: [
        {
          label: i18n.t('common.ok'),
          action: Alert.close,
        },
      ],
      cancelable: true,
    })

  const onSubmit = (values: Values, { setFieldError, setSubmitting }: FormikHelpers<Values>) => {
    const { email, password } = values

    if (!verifiedMail) {
      apis.user
        .validateEmail(email)
        .then(res => {
          if (res === 'SIGNUP') {
            return apis.user
              .sendCode(email, 'createAccount')
              .then(res => {
                if (res.code === '200') {
                  openPopUp(
                    i18n.t('screens.login.form.validationCode'),
                    i18n.t('screens.login.codeSent', { email }),
                    i18n.t('common.ok'),
                    () => {
                      setVerifiedMail(email)
                      setCodeAttempts(1)
                    }
                  )
                }
                setSubmitting(false)
              })
              .catch(err => {
                if (err.response && err.response.data) {
                  if (err.response.data.message === 'user.not.found') {
                    setFieldError('email', i18n.t('screens.login.errors.no_matching_email'))
                  } else if (err.response.data.message === 'email.domain.not.authorized') {
                    setFieldError('email', i18n.t('screens.login.errors.domain_unauthaurized'))
                  } else if (err.response.data.message) {
                    setVerifiedMail(email)
                  } else if (err.response.data.message === 'errors.digitCode.userNotInitialized') {
                    throw err
                  } else {
                    throw err
                  }
                } else {
                  triggerErrorAlert()
                }
                setSubmitting(false)
              })
          } else if (res === 'SIGNIN') {
            setVerifiedMail(email)
          } else {
            setFieldError('email', i18n.t('screens.login.errors.domain_unauthaurized'))
          }
          setSubmitting(false)
        })
        .catch(err => {
          triggerErrorAlert()
          setSubmitting(false)
          Logger.error(err)
        })
    } else {
      if (password) {
        apis.auth
          .login(email, password)
          .then(() => {
            apis.account
              .get()
              .then(login)
              .catch(err => {
                Logger.error(err)
                triggerErrorAlert()
              })
          })
          .catch(err => {
            if (err.response && err.response.data.message && err.response.data.message === 'user.too.many.attempts') {
              setFieldError('password', i18n.t('screens.login.errors.too_many_attempts'))
            } else {
              setFieldError('password', i18n.t('screens.login.errors.passwordError'))
            }
            Logger.error('Error while login : ', err)
            setSubmitting(false)
          })
      }
    }
  }

  const onSubmitNewAccount = (user: SignInValues, { setFieldError, setSubmitting }: FormikHelpers<SignInValues>) => {
    const { password, firstName, lastName } = user

    if (verifiedMail && codeToken) {
      apis.user
        .createUser(firstName, lastName, verifiedMail, password, codeToken)
        .then(res => {
          apis.auth
            .login(verifiedMail, password)
            .then(res => {
              apis.account.get().then(login)
            })
            .catch(err => {
              setFieldError('confirmPassword', i18n.t('screens.login.authentication.wrong_password'))
              Logger.error('Error : ', err)
              setSubmitting(false)
            })
        })
        .catch(err => {
          if (err.response && err.response.data) {
            switch (err.response.data.message) {
              case 'user.not.found':
                triggerAlert('digitCode.user.notFound')
                break
              case 'user.token.decrypt.failed':
                triggerErrorAlert()
                break
              case 'user.token.content.invalid':
                triggerErrorAlert()
                break
              case 'user.password.invalid':
                setFieldError('confirmPassword', i18n.t('screens.login.errors.passwordError'))
                break
            }
          } else {
            triggerErrorAlert()
          }
          setSubmitting(false)
        })
    }
  }

  const onForgetPassword = (verifiedMail: string) => {
    apis.user
      .sendCode(verifiedMail, 'changePassword')
      .then(res => {
        if (res.code === '200') {
          openPopUp(
            i18n.t('screens.login.form.validationCode'),
            i18n.t('screens.login.codeSent', { verifiedMail }),
            i18n.t('common.ok'),
            () => {
              setCodeAttempts(1)
              setModifyPassword(true)
            }
          )
        }
      })
      .catch(err => triggerAlert('digitCode.unknownError'))
  }

  const onSubmitNewPassword = (
    values: PasswordValues,
    { setFieldError, setSubmitting }: FormikHelpers<PasswordValues>
  ) => {
    const { password, confirmPassword } = values

    if (password !== confirmPassword) {
      setFieldError('confirmPassword', i18n.t('screens.login.errors.different_passwords'))
      setSubmitting(false)
    } else if (verifiedMail && codeToken) {
      apis.user
        .changeForgottenPwd(verifiedMail, password, codeToken)
        .then(() => {
          apis.auth.login(verifiedMail, password).then(res => apis.account.get().then(login))
        })
        .catch(err => {
          if (err.response && err.response.data) {
            switch (err.response.data.message) {
              case 'user.not.found':
                triggerAlert('digitCode.user.notFound')
                break
              case 'user.token.decrypt.failed':
                triggerErrorAlert()
                break
              case 'user.token.content.invalid':
                triggerErrorAlert()
                break
              case 'user.password.invalid':
                setFieldError('confirmPassword', i18n.t('screens.login.errors.passwordError'))
                break
              default:
                triggerErrorAlert()
                break
            }
          } else {
            triggerErrorAlert()
          }
          setSubmitting(false)
        })
    }
  }

  const onDigitCodeChange = (code: string) => {
    if (code.length === 4) {
      setDigitCode('')
      Keyboard.dismiss()
      submitDigitCode(code)
    } else {
      setCodeError(false)
      setDigitCode(code)
    }
  }

  const triggerAlert = (key: string) =>
    Alert.open({
      title: i18n.t('common.warning'),
      text: i18n.t(`screens.login.errors.${key}`),
      buttons: [
        {
          label: i18n.t('common.ok'),
          action: Alert.close,
        },
      ],
      cancelable: true,
    })

  const updateAttemptsCount = (error: string, codeAttempts: number): number => {
    setCodeError(true)
    switch (error) {
      case 'user.not.found':
        triggerAlert('digitCode.user.notFound')
      case 'digitCode.not.found':
        return codeAttempts + 1
      case 'digitCode.outdated':
        triggerAlert(error)
        return codeAttempts + 1
      case 'digitCode.too.many.attempts':
        triggerAlert('digitCode.tooManyAttempts')
      case 'digitCode.not.matching':
        return codeAttempts + 1
      case 'digitCode.wrong.type':
        triggerAlert('digitCode.wrongType')
    }
    return 4
  }

  const submitDigitCode = (code: string) => {
    const type = modifyPassword ? 'changePassword' : 'createAccount'

    function handleError(err: any) {
      const newCount = updateAttemptsCount(err.response.data.message, codeAttempts)
      Logger.error('Error while sending code validation : ', err)
      if (codeAttempts === ATTEMPTS_MAX) {
        Alert.open({
          title: i18n.t('screens.login.errors.codeError'),
          text: i18n.t('screens.login.errors.digitCode.tooManyAttempts'),
          buttons: [
            {
              label: i18n.t('common.ok'),
              action: () => {
                setCodeAttempts(0)
                setVerifiedMail(undefined)
                setCodeOK(false)
                Alert.close()
              },
            },
          ],
          cancelable: true,
        })
      } else {
        setCodeAttempts(newCount)
      }
    }

    if (verifiedMail) {
      apis.user
        .validateCode(verifiedMail, code, type)
        .then(res => {
          if (!!res.token) {
            setCodeOK(true)
            setCodeToken(res.token)
          } else {
            handleError(res)
          }
        })
        .catch(err => {
          handleError(err)
        })
    }
  }

  const login = (user: UserInfo) => {
    userStore.actions.setUser(user)
    setVerifiedMail(undefined)
    setCodeOK(false)
  }

  const digitCodeRef = React.createRef<TextInput>()

  React.useEffect(() => {
    // Délai pour ne pas interférer avec la gestion du clic sur la popup
    setTimeout(() => {
      if (digitCodeRef.current) {
        digitCodeRef.current.focus()
      }
    }, 1000)
  }, [verifiedMail])

  const openCGU = () => {
    apis.user
      .cgu()
      .then(res => {
        const cgu = res.find(cgu => cgu.language === i18n.lang)
        openPopUp(
          i18n.t('screens.login.cgu'),
          cgu ? cgu.content : i18n.t('common.warning'),
          i18n.t('common.ok'),
          () => null,
          true
        )
      })
      .catch(err => {
        triggerErrorAlert()
        Logger.error(err)
      })
  }

  const renderDigitCode = () => {
    return (
      <FormContainer breakPoint={breakPoint}>
        <FieldContainer>
          <CodeDigitLabel>{i18n.t('screens.login.form.validationCode')}</CodeDigitLabel>
          <AttemptText>
            {i18n.t('screens.login.form.remainingAttempts', { count: ATTEMPTS_MAX + 1 - codeAttempts })}
          </AttemptText>
          <CodeFieldContainer>
            <CodeField
              ref={digitCodeRef}
              value={digitCode}
              onChangeText={onDigitCodeChange}
              cellCount={4}
              textInputStyle={{ height: 85 }}
              keyboardType="number-pad"
              renderCell={({ index, symbol, isFocused }) => (
                <CodeDigitContainer isEmpty={!symbol}>
                  <CodeDigit>{symbol || (isFocused && <Cursor />)}</CodeDigit>
                  {!symbol && <UnderLine error={codeError} />}
                </CodeDigitContainer>
              )}
            />
          </CodeFieldContainer>
          {codeError && (
            <ErrorCodeContainer>
              <ErrorInputText>{i18n.t('screens.login.errors.codeError')}</ErrorInputText>
            </ErrorCodeContainer>
          )}
        </FieldContainer>
      </FormContainer>
    )
  }

  const renderNewPassword = () => {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={onSubmitNewPassword}
        validationSchema={yupSchemaPasswordChange(i18n)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, touched }) => (
          <FormContainer breakPoint={breakPoint}>
            <FormSubContainer>
              <Animators>
                <FieldContainer>
                  <InputLabel>{i18n.t('screens.login.form.newPassword')}</InputLabel>
                  <InputContainer error={!!errors.password && !!touched.password}>
                    <Input
                      onChangeText={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      secureTextEntry
                      returnKeyType="next"
                      autoCapitalize="none"
                      placeholderTextColor={theme.colors.inputPlaceholder}
                      error={!!errors.password}
                      placeholder={i18n.t('screens.login.form.placeholderPassword')}
                    />
                  </InputContainer>
                </FieldContainer>

                <FieldContainer>
                  <InputLabel>{i18n.t('screens.login.form.confirmPassword')}</InputLabel>
                  <InputContainer error={!!errors.confirmPassword && !!touched.confirmPassword}>
                    <Input
                      onChangeText={handleChange('confirmPassword')}
                      onBlur={handleBlur('confirmPassword')}
                      value={values.confirmPassword}
                      secureTextEntry
                      returnKeyType="send"
                      onSubmitEditing={handleSubmit as any}
                      autoCapitalize="none"
                      placeholderTextColor={theme.colors.inputPlaceholder}
                      error={!!errors.confirmPassword}
                      placeholder={i18n.t('screens.login.form.placeholderPassword')}
                    />
                  </InputContainer>
                </FieldContainer>

                <ErrorContainer>
                  <ErrorInputText>{errors.password || errors.confirmPassword}</ErrorInputText>
                </ErrorContainer>
              </Animators>
            </FormSubContainer>
            <FormSubContainer>
              <Animation>
                <SubmitButtonContainer>
                  <SubmitButton onPress={handleSubmit as any} disabled={isSubmitting} activeOpacity={0.8}>
                    <ButtonLabel disabled={isSubmitting}>{i18n.t('screens.login.edit')}</ButtonLabel>
                  </SubmitButton>
                </SubmitButtonContainer>
              </Animation>
            </FormSubContainer>
          </FormContainer>
        )}
      </Formik>
    )
  }

  const renderLoginForm = () => {
    return (
      <Formik
        initialValues={{ email: '', password: '' } as Values}
        onSubmit={onSubmit}
        validationSchema={verifiedMail ? yupSchemaPassword(i18n) : yupSchemaMail(i18n)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, isSubmitting }) => (
          <FormContainer breakPoint={breakPoint}>
            <FormSubContainer>
              <Animation>
                <FieldContainer>
                  <InputLabel>{i18n.t('screens.login.form.mail')}</InputLabel>
                  <InputContainer disabled={!!verifiedMail} error={!!errors.email}>
                    <Input
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                      keyboardType="email-address"
                      returnKeyType="send"
                      onSubmitEditing={handleSubmit as any}
                      value={values.email}
                      editable={!verifiedMail}
                      autoCapitalize="none"
                      placeholderTextColor={theme.colors.inputPlaceholder}
                      error={!!errors.email}
                      placeholder={i18n.t('screens.login.form.placeholderMail')}
                    />
                  </InputContainer>

                  {!!errors.email && (
                    <ErrorContainer>
                      <ErrorInputText>{errors.email}</ErrorInputText>
                    </ErrorContainer>
                  )}
                </FieldContainer>
              </Animation>

              {!!verifiedMail && (
                <Animation>
                  <FieldContainer>
                    <InputLabel>{i18n.t('screens.login.form.password')}</InputLabel>
                    <InputContainer error={!!errors.password}>
                      <Input
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        returnKeyType="send"
                        onSubmitEditing={handleSubmit as any}
                        secureTextEntry
                        autoCapitalize="none"
                        placeholderTextColor={theme.colors.inputPlaceholder}
                        error={!!errors.password}
                        placeholder={i18n.t('screens.login.form.placeholderPassword')}
                      />
                    </InputContainer>

                    {!!errors.password && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.password}</ErrorInputText>
                      </ErrorContainer>
                    )}
                    <ForgottenButtonContainer>
                      <TouchableOpacity onPress={() => onForgetPassword(verifiedMail)} activeOpacity={0.9}>
                        <ForgottenPwdLabel>{i18n.t('screens.login.forgottenPassword')}</ForgottenPwdLabel>
                      </TouchableOpacity>
                    </ForgottenButtonContainer>
                  </FieldContainer>
                </Animation>
              )}
            </FormSubContainer>

            <FormSubContainer>
              <Animators>
                <SubmitButtonContainer>
                  <SubmitButton onPress={handleSubmit as any} disabled={isSubmitting} activeOpacity={0.8}>
                    <ButtonLabel disabled={isSubmitting}>{i18n.t('screens.login.login')}</ButtonLabel>
                  </SubmitButton>
                </SubmitButtonContainer>

                {!verifiedMail && <InformationText>{i18n.t('screens.login.loginInfo')}</InformationText>}
              </Animators>
            </FormSubContainer>
          </FormContainer>
        )}
      </Formik>
    )
  }

  const renderNewAccount = () => {
    return (
      <Formik
        initialValues={
          {
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            cgvCheck: false,
          } as SignInValues
        }
        validateOnChange={false}
        onSubmit={onSubmitNewAccount}
        validationSchema={yupSchemaAccountCreation(i18n)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, setFieldValue, touched }) => {
          return (
            <FormContainer breakPoint={breakPoint}>
              <FormSubContainer>
                <Animators>
                  <FieldContainer>
                    <InputLabel>{i18n.t('screens.login.form.firstName')}</InputLabel>
                    <InputContainer error={!!errors.firstName && !!touched.firstName}>
                      <Input
                        onChangeText={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        value={values.firstName}
                        returnKeyType="next"
                        autoCapitalize="none"
                        maxLength={50}
                        error={!!errors.firstName}
                        placeholderTextColor={theme.colors.inputPlaceholder}
                        placeholder={i18n.t('screens.login.form.firstName')}
                      />
                    </InputContainer>
                    {!!errors.firstName && touched.firstName && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.firstName}</ErrorInputText>
                      </ErrorContainer>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabel>{i18n.t('screens.login.form.lastName')}</InputLabel>
                    <InputContainer error={!!errors.lastName && !!touched.lastName}>
                      <Input
                        onChangeText={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        value={values.lastName}
                        returnKeyType="next"
                        autoCapitalize="none"
                        maxLength={50}
                        error={!!errors.lastName}
                        placeholderTextColor={theme.colors.inputPlaceholder}
                        placeholder={i18n.t('screens.login.form.lastName')}
                      />
                    </InputContainer>
                    {!!errors.lastName && touched.lastName && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.lastName}</ErrorInputText>
                      </ErrorContainer>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabel>{i18n.t('screens.login.form.password')}</InputLabel>
                    <InputContainer error={!!errors.password && !!touched.password}>
                      <Input
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        secureTextEntry
                        returnKeyType="send"
                        onSubmitEditing={handleSubmit as any}
                        autoCapitalize="none"
                        error={!!errors.password}
                        placeholderTextColor={theme.colors.inputPlaceholder}
                        placeholder={i18n.t('screens.login.form.placeholderPassword')}
                      />
                    </InputContainer>
                    {!!errors.password && touched.password && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.password}</ErrorInputText>
                      </ErrorContainer>
                    )}
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabel>{i18n.t('screens.login.form.confirmPassword')}</InputLabel>
                    <InputContainer error={!!errors.confirmPassword && !!touched.confirmPassword}>
                      <Input
                        onChangeText={handleChange('confirmPassword')}
                        onBlur={handleBlur('confirmPassword')}
                        value={values.confirmPassword}
                        secureTextEntry
                        returnKeyType="send"
                        onSubmitEditing={handleSubmit as any}
                        autoCapitalize="none"
                        error={!!errors.confirmPassword}
                        placeholderTextColor={theme.colors.inputPlaceholder}
                        placeholder={i18n.t('screens.login.form.placeholderPassword')}
                      />
                    </InputContainer>
                    {!!errors.confirmPassword && touched.confirmPassword && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.confirmPassword}</ErrorInputText>
                      </ErrorContainer>
                    )}
                  </FieldContainer>
                  <FieldContainer>
                    <CGVContainer>
                      <RoundCheckButton
                        onPress={() => setFieldValue('cgvCheck', !values.cgvCheck)}
                        checked={values.cgvCheck}
                      />
                      <CGVTextContainer>
                        <ForgottenPwdLabel>{i18n.t('screens.login.signIn.cgvCheck')}</ForgottenPwdLabel>
                        <TouchableOpacity activeOpacity={0.8} onPress={() => openCGU()}>
                          <LinkText>{i18n.t('screens.login.signIn.cgv')}</LinkText>
                        </TouchableOpacity>
                      </CGVTextContainer>
                    </CGVContainer>
                    {!!errors.cgvCheck && touched.cgvCheck && (
                      <ErrorContainer>
                        <ErrorInputText>{errors.cgvCheck}</ErrorInputText>
                      </ErrorContainer>
                    )}
                  </FieldContainer>
                </Animators>
              </FormSubContainer>

              <FormSubContainer>
                <Animation>
                  <SubmitButtonContainer>
                    <SubmitButton onPress={handleSubmit as any} disabled={isSubmitting} activeOpacity={0.8}>
                      <ButtonLabel disabled={isSubmitting}>{i18n.t('screens.login.signIn.signIn')}</ButtonLabel>
                    </SubmitButton>
                  </SubmitButtonContainer>
                </Animation>
              </FormSubContainer>
            </FormContainer>
          )
        }}
      </Formik>
    )
  }

  const renderBody = () => {
    if (codeOK) {
      if (modifyPassword) {
        return renderNewPassword()
      } else {
        return renderNewAccount()
      }
    }
    if (codeAttempts > 0 && codeAttempts < ATTEMPTS_MAX + 1) {
      return renderDigitCode()
    }
    return renderLoginForm()
  }

  return (
    <MainContainer>
      <BackContainer>
        <Background />
      </BackContainer>

      <ScrollContainer disabled={Platform.OS === 'web'} onPress={() => Keyboard.dismiss()} activeOpacity={1}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between' }}
          bounces={false}
          keyboardShouldPersistTaps="handled"
        >
          <Animation>
            <HeadContainer>
              {codeOK && !modifyPassword ? (
                <SignInTitle>{i18n.t('screens.login.signIn.signIn')}</SignInTitle>
              ) : (
                <Logo source={require('@assets/images/logo.png')} resizeMode="contain" />
              )}
            </HeadContainer>
          </Animation>
          {renderBody()}
        </ScrollView>
      </ScrollContainer>
    </MainContainer>
  )
}

export default AuthenticationScreen

const MainContainer = styled(View)`
  flex: 1;
`

const BackContainer = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ScrollContainer = styled(TouchableOpacity)`
  flex: 1;
`

const FormContainer = styled(View)<{ breakPoint: number }>`
  margin: ${props => (Platform.OS === 'web' && props.breakPoint > 1200 ? '0px 218px' : '0px 40px;')};
  ${Platform.OS === 'web' && 'padding-bottom: 30px;'}
  flex-direction: column;
  align-content: center;
  flex: 1;
  justify-content: space-between;
`

const FieldContainer = styled(View)`
  margin-bottom: 30px;
`

const FormSubContainer = styled(View)`
  justify-content: space-evenly;
`

const SubmitButtonContainer = styled(View)`
  align-items: center;
  margin-bottom: 20px;
`

const SubmitButton = styled(TouchableOpacity)`
  background-color: white;
  padding: 12px 40px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
`

const ButtonLabel = styled(Text)<{ disabled: boolean }>`
  ${props => props.theme.fonts.buttonLabel};
  font-weight: bold;
  line-height: 24px;
  color: ${props => (props.disabled ? props.theme.colors.primaryLight : props.theme.colors.primary)};
`

const InformationText = styled(Text)`
  ${props => props.theme.fonts.genericBody1}
  color: white;
  text-align: center;
  margin-bottom: 30px;
`

const InputContainer = styled(View)<{ error: boolean; disabled?: boolean }>`
  background-color: ${props => (props.disabled ? props.theme.colors.primaryLight : 'white')};
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.error ? props.theme.colors.error : 'transparent')};
`

const InputLabel = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: white;
  margin-bottom: 13px;
  text-transform: uppercase;
`

const Input = styled(TextInput)<{ error: boolean }>`
  padding: 13px 16px;
  color: ${props => props.theme.colors.primaryDark};
  border-radius: 10px;
`

const ErrorInputText = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.error};
`

const ErrorContainer = styled(View)`
  margin-top: 8px;
  flex-direction: row;
`

const ForgottenPwdLabel = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.authInfo};
`

const ForgottenButtonContainer = styled(View)`
  margin-top: 13px;
`

const HeadContainer = styled(View)`
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
`

const Logo = styled(Image)`
  width: 136px;
  height: 136px;
`

const SignInTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: white;
`

const LinkText = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: white;
  text-decoration: underline white;
`

const CGVTextContainer = styled(View)`
  justify-content: center;
  margin-left: 8px;
`

const CGVContainer = styled(View)`
  flex-direction: row;
  margin-bottom: 20px;
`

const AttemptText = styled(Text)`
  margin-bottom: 50px;
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.primaryLight};
  text-align: center;
`

const CodeFieldContainer = styled(View)`
  padding: 0px 40px;
`

const CodeDigitContainer = styled(View)<{ isEmpty: boolean }>`
  width: 40px;
  height: 85px;
  padding-bottom: 5px;
  justify-content: space-between;
`

const CodeDigit = styled(Text)`
  ${props => props.theme.fonts.codeDigit};
  text-align: center;
`

const CodeDigitLabel = styled(InputLabel)`
  text-align: center;
`

const UnderLine = styled(View)<{ error: boolean }>`
  background-color: ${props => (props.error ? props.theme.colors.secondaryText : 'white')};
  height: 3px;
  border-radius: 2px;
`

const ErrorCodeContainer = styled(View)`
  align-items: center;
  margin-top: 5px;
`

const yupSchemaMail = (i18n: I18nState) =>
  yup.object().shape({
    email: yup
      .string()
      .ensure()
      .email(i18n.t('screens.login.errors.mailError'))
      .required(i18n.t('screens.login.errors.field_required')),
  })

const yupSchemaPassword = (i18n: I18nState) =>
  yup.object().shape({
    password: yup.string().ensure().required(i18n.t('screens.login.errors.field_required')),
  })

const yupSchemaPasswordChange = (i18n: I18nState) =>
  yup.object().shape({
    password: yup
      .string()
      .ensure()
      .required(i18n.t('screens.login.errors.field_required'))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[$&+,:;=?@#|'<>.\\-^*()%!])(?=.*[0-9]).[a-zA-Z0-9$&+,:;=?@#|'<>.\\-^*()%!]{7,49}$/,
        i18n.t('errors.field.passwordFormat')
      ),
    confirmPassword: yup
      .string()
      .ensure()
      .oneOf([yup.ref('password'), null, ''], i18n.t('screens.login.errors.different_passwords'))
      .required(i18n.t('screens.login.errors.field_required')),
  })

const yupSchemaAccountCreation = (i18n: I18nState) =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('screens.login.errors.field_required')),
    lastName: yup.string().required(i18n.t('screens.login.errors.field_required')),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[$&+,:;=?@#|'<>.\\-^*()%!])(?=.*[0-9]).[a-zA-Z0-9$&+,:;=?@#|'<>.\\-^*()%!]{7,49}$/,
        i18n.t('errors.field.passwordFormat')
      )
      .required(i18n.t('screens.login.errors.field_required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null, ''], i18n.t('screens.login.errors.passwordError'))
      .required(i18n.t('screens.login.errors.field_required')),
    cgvCheck: yup.boolean().oneOf([true], i18n.t('screens.login.errors.field_required')),
  })
