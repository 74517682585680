import * as React from 'react'
import { TouchableOpacity } from 'react-native'

import Icon from '@components/icons/Icons'

interface Props {
  checked: boolean
  onPress: () => void
}

const RoundCheckButton = ({ checked, onPress }: Props) => {
  const name = checked ? 'checkFill' : 'checkEmpty'
  return (
    <TouchableOpacity activeOpacity={0.9} onPress={onPress}>
      <Icon size={32} name={name} />
    </TouchableOpacity>
  )
}

export default RoundCheckButton
