import config from '@app/config'
import ws from '@api/webservice'

type CodeAction = 'createAccount' | 'changePassword'

const api = {
  sendCode: (email: string, action: CodeAction): Promise<ResponseWS> => {
    return ws(`${config.ws.auth.code}/${action}`, 'POST', { email }, false)
  },
  validateCode: (email: string, code: string, action: CodeAction): Promise<ResponseWSAndToken> => {
    return ws(`${config.ws.auth.code}/${action}/token`, 'POST', { email, code }, false)
  },
  createUser: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    token: string
  ): Promise<AuthAnswer> => {
    return ws(`${config.ws.auth.create}`, 'POST', { email, firstName, lastName, password, token }, false)
  },
  cgu: (): Promise<CGUs> => {
    return ws(`${config.ws.auth.cgu}`, 'GET')
  },
  changePassword: (newPassword: string, newPasswordConfirmation: string): Promise<AuthAnswer> => {
    return ws(`${config.ws.account.update}`, 'PUT', { newPassword, newPasswordConfirmation }, true)
  },
  changeForgottenPwd: (email: string, newpassword: string, token: string): Promise<AuthAnswer> => {
    return ws(`${config.ws.auth.password}`, 'PUT', { newpassword, email, token }, false)
  },
  validateEmail: (email: string): Promise<LoginAction> => {
    return ws(`${config.ws.auth.validateEmail}?email=${email}`, 'GET', undefined, false)
  },
}

export default api
