import * as React from 'react'
import { View, Text } from 'react-native'
import styled from '@theme/styled-components'

import Animators from '@components/root/Animators'
import HTML from '@components/html/HTMLContent'
import AnimatedCarousel, { OFFSET } from '@components/image/AnimatedCarousel'
import ResponsiveMainContainer from '@components/responsive/ResponsiveMainContainer'

import config from '@app/config'

interface Props {
  info: EditorialDetail
}

const PICTURE_OFFSET = 300

const Component = ({ info }: Props) => {
  const [width, setWidth] = React.useState(0)

  return (
    <ResponsiveMainContainer scrollable hPadding={20}>
      <Animators>
        <PictureContainer anim={info.photos.length > 1} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
          <AnimatedCarousel
            width={width - (info.photos.length > 1 ? 2 * OFFSET : 0)}
            height={PICTURE_OFFSET}
            pictures={info.photos.map(p => `${config.SERVER_PREFIX}${p.url}`)}
          />
        </PictureContainer>

        <View>
          <Title>{info.title}</Title>

          {/* CONTENT */}
          <Content>
            <HTML html={info.description} />
          </Content>
        </View>
      </Animators>
    </ResponsiveMainContainer>
  )
}

const PictureContainer = styled(View)<{ anim?: boolean }>`
  padding-left: ${props => (props.anim ? OFFSET : 0)}px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  margin: 20px 25px 0px;
`

const Content = styled(View)`
  margin: 20px 25px;
`

const InfoDetail = {
  open: (conf: Props) => {
    return <Component {...conf} />
  },
}

export default InfoDetail
