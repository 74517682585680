import * as React from 'react'
import { View, Text, TouchableOpacity, BackHandler, Animated, Easing, FlatList, SafeAreaView } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'
import RootComponent from 'react-native-root-siblings'

interface Props {
  title: string
  data: string[]
  value: number
  onValidate: (value: string, index: number) => void
  onClose?: () => void
}

const Component = ({ title, value, data, onValidate, onClose }: Props) => {
  const anim = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    if (onClose) {
      onClose()
    }

    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      FieldSelect.close
    )
  }

  const renderItem = ({ item, index }: { item: string; index: number }) => {
    return (
      <InputContainer
        selected={index === value}
        activeOpacity={0.8}
        onPress={() => {
          onValidate(item, index)
          close()
        }}
      >
        <InputTextContainer>
          <InputTitle>{item}</InputTitle>
        </InputTextContainer>
        <View style={{ transform: [{ rotate: '180deg' }] }}>
          <Icon name="chevronLeft" size={20} color={Theme.colors.primary} />
        </View>
      </InputContainer>
    )
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <SafeAreaView>
        <HeaderContainer>
          <BackButton activeOpacity={0.9} onPress={close}>
            <Icon name="back" size={24} color={Theme.colors.disable} />
          </BackButton>
          <Title>{title}</Title>
        </HeaderContainer>
      </SafeAreaView>

      <View>
        <FlatList data={data} keyExtractor={item => item} renderItem={renderItem} />
      </View>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.background};
`

const HeaderContainer = styled(View)`
  padding: 30px 24px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const BackButton = styled(TouchableOpacity)`
  margin-bottom: 10px;
`

const InputContainer = styled(TouchableOpacity)<{ selected: boolean }>`
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  margin: 0px 20px;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;

  border: ${props => (props.selected ? props.theme.colors.primaryLight : 'transparent')} 2px solid;
`

const InputTextContainer = styled(View)`
  margin-right: 20px;
  flex: 1;
`

const InputTitle = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.secondaryText};
  text-transform: uppercase;
`

let root: RootComponent
let rootActive = false

const FieldSelect = {
  open: (conf: Props) => {
    if (!rootActive) {
      rootActive = true
      root = new RootComponent(<Component {...conf} />)

      BackHandler.addEventListener('hardwareBackPress', FieldSelect.onRequestClose)
    }
  },

  close: () => {
    if (rootActive && root) {
      root.destroy()
      rootActive = false
    }
  },

  onRequestClose: () => {
    BackHandler.removeEventListener('hardwareBackPress', FieldSelect.onRequestClose)
    FieldSelect.close()

    return true
  },
}

export { Component }
export default FieldSelect
