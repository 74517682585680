import { createEvent, createStore } from 'effector'

export const actions = {
  setUser: createEvent<UserInfo>('setUser'),
  resetUser: createEvent('resetUser'),
  setUseGeoloc: createEvent<boolean>('setUseGeoloc'),
}

const initialState: UserState = {
  user: undefined,
  privacyAccepted: false,
  useGeoloc: true,
}

export const store = createStore<UserState>(initialState)
  .on(actions.setUser, (s, user) => ({ ...s, user }))
  .on(actions.resetUser, () => ({ ...initialState }))
  .on(actions.setUseGeoloc, (s, useGeoloc) => ({ ...s, useGeoloc }))
