import { createEvent, createStore } from 'effector'
import { addSeconds } from 'date-fns'

interface AuthBody {
  credentials: CredentialsOAuth2
  username: string
}

export const actions = {
  setAuth: createEvent<AuthBody>('setAuth'),
  resetAuth: createEvent('resetAuth'),
  setTokenStatus: createEvent<TokenStatus>('setTokenStatus'),
  setLogged: createEvent<boolean>('setLogged'),
}

const initialState: AuthState = {
  credentials: undefined,
  username: undefined,
  expirationDate: undefined,
  tokenStatus: 'none',
  logged: false,
}

export const store = createStore<AuthState>(initialState)
  .on(actions.setAuth, (s, authBody) => ({
    ...s,
    credentials: authBody.credentials,
    username: authBody.username,
    expirationDate: addSeconds(new Date(), authBody.credentials.expires_in),
  }))
  .on(actions.resetAuth, () => ({ ...initialState }))
  .on(actions.setTokenStatus, (s, status) => ({ ...s, tokenStatus: status }))
  .on(actions.setLogged, (s, logged) => ({ ...s, logged }))
