import * as React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import styled from '@theme/styled-components'

interface Props {
  value: boolean
  label: string
  onChange: (value: boolean) => void
}

const Check = ({ value, label, onChange }: Props) => {
  return (
    <Line activeOpacity={0.8} onPress={() => onChange(!value)}>
      <RadioContainer>
        <Radio selected={value} />
      </RadioContainer>
      <RadioValue>{label}</RadioValue>
    </Line>
  )
}

export default Check

const Line = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin: 5px;
  flex: 1;
`

const RadioContainer = styled(View)`
  background-color: ${props => props.theme.colors.primaryLight};
  height: 24px;
  width: 24px;
  border-radius: 16px;
  margin-right: 15px;
`

const Radio = styled(View)<{ selected: boolean }>`
  background-color: ${props => (props.selected ? props.theme.colors.primary : 'transparent')};
  height: 16px;
  width: 16px;
  border-radius: 12px;

  position: absolute;
  top: 4px;
  left: 4px;
`

const RadioValue = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
`
