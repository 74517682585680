import * as React from 'react'
import { View, Text, Image, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'

import api from '@api/api'
import { getEarliestBooking, filterPastBookings } from './utils'
import { useFocusEffect } from '@react-navigation/native'

import Root from '@components/root/Root'
import { Animation } from '@components/root/Animators'
import Button from '@components/button/RectangleButton'
import BookingInfo from './BookingInfo'

interface Props {
  navigator: Navigation
}

const BookingScreen = ({ navigator }: Props) => {
  const i18n = useI18n()
  const [allPros, setAllPros] = React.useState<ProfessionalItem[]>([]) // TODO - à remplacer quand le ws est séparé en deux
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [nextBooking, setNextBooking] = React.useState<BookingItem>()

  React.useEffect(() => {
    api.booking
      .getAllPros()
      .then(res => setAllPros(res.professionals))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [])

  useFocusEffect(
    React.useCallback(() => {
      api.booking
        .getUsersBookings()
        .then(res =>
          setNextBooking(getEarliestBooking(res.bookings.filter(b => !b.cancelled).filter(filterPastBookings)))
        )
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    }, [])
  )

  const renderFeatureLogo = () => {
    return (
      <FeatureLogoContainer>
        <Animation animation="fade_top">
          <FeatureLogo resizeMode="contain" source={require('@assets/images/booking.png')} />
        </Animation>
      </FeatureLogoContainer>
    )
  }

  const RenderNextBooking = ({ navigator }: Props) => (
    <NextBookingContainer>
      <Title>{i18n.t('screens.booking.nextBooking')}</Title>
      {!!nextBooking ? (
        <>
          <BookingInfo booking={nextBooking} clickable navigator={navigator} />
          <ButtonContainer>
            <Button
              label={i18n.t('screens.booking.seeAllBookings')}
              color="white"
              size="tall"
              onPress={() => navigator.push('myBookings')}
              boldText
            />
          </ButtonContainer>
        </>
      ) : (
        <EmptyMessage>{i18n.t('screens.booking.noNextBooking')}</EmptyMessage>
      )}
    </NextBookingContainer>
  )

  const renderProfessional = ({ item }: { item: ProfessionalItem }) => (
    <ProfesionnalContainer
      key={item.id}
      activeOpacity={0.9}
      onPress={() => navigator.push('bookingPro', { proId: item.id, proName: item.name })}
    >
      <Title>{item.name}</Title>

      <ProfessionDescription>{item.profession}</ProfessionDescription>
    </ProfesionnalContainer>
  )

  if (loading) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  if (error) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <EmptyContainer>
          <ErrorText>{i18n.t('errors.tryAgain')}</ErrorText>
        </EmptyContainer>
      </Root>
    )
  }

  return (
    <React.Fragment>
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <Animation>
          <ListContainer>
            <FlatList
              ListHeaderComponent={<RenderNextBooking navigator={navigator} />}
              data={allPros}
              keyExtractor={item => item.id || ''}
              showsVerticalScrollIndicator={false}
              renderItem={renderProfessional}
            />
          </ListContainer>
        </Animation>
      </Root>

      {renderFeatureLogo()}
    </React.Fragment>
  )
}

// CONTAINERS

const NextBookingContainer = styled(View)`
  margin-bottom: 24px;
  padding: 0px 24px 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors.separator};
`
const ListContainer = styled(View)`
  padding: 24px 0px 0px;
`
const FeatureLogoContainer = styled(View)`
  position: absolute;
  top: 0px;
  right: 30px;
`
const ProfesionnalContainer = styled(TouchableOpacity)`
  background-color: ${props => props.theme.colors.counterPrimary};
  padding: 31px 26px;
  margin: 0px 24px;
  border-radius: 10px;
  margin-bottom: 30px;
`
const ButtonContainer = styled(View)`
  margin-top: 16px;
`
const EmptyContainer = styled(View)`
  padding: 24px;
  justify-content: center;
  align-items: center;
`

// TEXTES

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryDark};
`
const EmptyMessage = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.secondaryText};
  text-transform: uppercase;
  margin-top: 18px;
`
const ProfessionDescription = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.secondaryText};
  margin-top: 4px;
`
const ErrorText = styled(Text)`
  ${props => props.theme.fonts.genericText};
`

// AUTRES

const FeatureLogo = styled(Image)`
  width: 52px;
  height: 102px;
`

export default BookingScreen
