import * as React from 'react'
import { Text, View, TouchableOpacity, ActivityIndicator } from 'react-native'

import Icon from '@components/icons/Icons'

import styled from '@theme/styled-components'
import { Animation } from '@components/root/Animators'

import Svg, { Defs, LinearGradient, Stop, Circle } from 'react-native-svg'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as userStore from '@store/user/user'
import * as restoStore from '@store/resto/resto'

import api from '@api/api'

import { formatAmount } from '@utils/NumberUtils'
import Logger from '@utils/logger'
import theme from '@theme/Theme'

interface BackProps {
  available: boolean
}

const CircleBack = ({ available }: BackProps) => {
  return (
    <Svg height="100%" width="100%">
      <Defs>
        <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="100%">
          <Stop offset="0" stopColor={available ? '#3D61E0' : '#EDEEF1'} stopOpacity="1" />
          <Stop offset="1" stopColor={available ? '#3D61E0' : '#EDEEF1'} stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <Circle cx="50%" cy="50%" r="78" fill="url(#grad)" />
    </Svg>
  )
}

interface Props {
  navigator: Navigation
}

const PLUS_BUTTON_WIDTH = 40

const RestaurantHeaderRefill = ({ navigator }: Props) => {
  const i18n = useI18n()

  const [loading, setLoading] = React.useState(false)
  const [available, setAvailable] = React.useState(true)
  const user = useReducer(userStore.store, s => s.user)
  const balance = useReducer(restoStore.store, s => s.balance)

  const badge = (user && user.badge) || ''
  const lastName = (user && user.lastName) || ''

  React.useEffect(() => {
    setLoading(true)
    api.resto
      .token()
      .then(token => api.resto.balance(token.token))
      .then(balance => {
        setLoading(false)
        // Une balance à undefined ou à null indique un badge invalide
        if (balance.balance !== undefined && balance.balance !== null) {
          restoStore.actions.setBalance(balance.balance)
          setAvailable(true)
        } else {
          restoStore.actions.resetBalance()
          setAvailable(false)
        }
      })
      .catch(err => {
        setLoading(false)
        setAvailable(false)
        Logger.error(err)
      })
  }, [badge, lastName])

  return (
    <Animation animation="fade">
      <MainContainer>
        <FillView />
        <ContentView>
          <Back>
            <CircleBack available={available} />
          </Back>
          {available ? (
            <TextContainer>
              <Label>{i18n.t('components.card.amount')}</Label>
              {loading ? (
                <CustomActivityIndicator color={theme.colors.secondary} />
              ) : (
                <Amount>{formatAmount(balance || 0)} €</Amount>
              )}
            </TextContainer>
          ) : (
            <TextContainer>
              <UnavailableTitle>{i18n.t('components.refill.unavailable.title')}</UnavailableTitle>
              <UnavailableText>{i18n.t('components.refill.unavailable.message')}</UnavailableText>
            </TextContainer>
          )}
        </ContentView>
        <Touchable activeOpacity={0.9} onPress={() => navigator.push('badge')}>
          <Button>
            <Icon name="plus" size={17} color="#fff" />
          </Button>
        </Touchable>
      </MainContainer>
    </Animation>
  )
}

export default RestaurantHeaderRefill

const MainContainer = styled(View)`
  flex-direction: row;
`
const ContentView = styled(View)`
  height: 156px;
  width: 156px;

  justify-content: flex-end;
  align-items: center;
`
const FillView = styled(View)`
  height: 156px;
  width: ${PLUS_BUTTON_WIDTH / 2}px;
`
const Back = styled(View)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`

// TEXT

const TextContainer = styled(View)`
  margin-bottom: 30px;
`

const Label = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.authInfo};
  text-transform: uppercase;
`

const Amount = styled(Text)`
  ${props => props.theme.fonts.amountDetail};
`

const UnavailableTitle = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

const UnavailableText = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  text-align: center;
`

// BUTTON

const Touchable = styled(TouchableOpacity)`
  position: absolute;
  bottom: 40px;
  left: 0px;
`

const Button = styled(View)`
  height: ${PLUS_BUTTON_WIDTH}px;
  width: ${PLUS_BUTTON_WIDTH}px;
  border-radius: ${PLUS_BUTTON_WIDTH / 2}px;
  background-color: ${props => props.theme.colors.secondary};

  align-items: center;
  justify-content: center;
`
const CustomActivityIndicator = styled(ActivityIndicator)`
  margin-top: 10px;
`
