import * as React from 'react'
import styled from '@theme/styled-components'

import { History } from 'history'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Screens from './Screens'
import Menu from './Menu'
import MiniMenu from './MiniMenu'
import { View } from 'react-native'
import Login from '@screens/login/login.web'
import useBreakPoints from './breakpoint'

import useReducer from '@store/useReducer'
import * as authStore from '@store/auth/auth'

const enhanceNavigator = (h: History): Navigation => {
  return {
    push: (name: string, params?: { [key: string]: any }) => {
      const s = Screens[name]
      if (s) {
        h.push(s.path, params)
      }
    },
    pushPath: h.push,
    goBack: h.goBack,
    navigate: (name: string, params?: { [key: string]: any }) => {
      const s = Screens[name]
      if (s) {
        h.push(s.path, params)
      }
    },
    openDrawer: () => null,
  }
}

const MainLayout = () => {
  const isConnected = useReducer(authStore.store, s => s.logged)
  const [breakPoint] = useBreakPoints()

  return (
    <Router>
      <Route path="/">
        {({ history }) => {
          const navigator = enhanceNavigator(history)
          if (isConnected) {
            return (
              <MainContainer>
                {breakPoint >= 800 ? <Menu navigator={navigator} /> : <MiniMenu navigator={navigator} />}
                <ScreenContainer>
                  <Switch>
                    {Object.keys(Screens)
                      .filter(k => !Screens[k].platform || Screens[k].platform === 'web')
                      .map(k => {
                        const scr = Screens[k]

                        return (
                          <Route path={scr.path} exact key={scr.name}>
                            {({ history, match }) => {
                              const matchParams = match && match.params ? match.params : {}
                              const params = history.location.state || {}
                              return <scr.screen navigator={navigator} {...params} {...matchParams} />
                            }}
                          </Route>
                        )
                      })}
                    <Redirect to="/" />
                  </Switch>
                </ScreenContainer>
              </MainContainer>
            )
          }

          return (
            <MainContainer>
              <ScreenContainer>
                <Switch>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                  <Redirect to="/login" />
                </Switch>
              </ScreenContainer>
            </MainContainer>
          )
        }}
      </Route>
    </Router>
  )
}

export default MainLayout

const MainContainer = styled(View)`
  flex: 1;
  flex-direction: row;
  background-color: ${props => props.theme.colors.background};
`

const ScreenContainer = styled(View)`
  flex: 1;
`
