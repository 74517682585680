import { createEvent, createStore } from 'effector'

export const actions = {
  setTermsOfService: createEvent<string>('setTermsOfService'),
}

const initialState: TermsOfServiceState = {
  termsOfService: '',
}

export const store = createStore<TermsOfServiceState>(initialState).on(actions.setTermsOfService, (s, content) => ({
  ...s,
  termsOfService: content,
}))
