import * as React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import styled from '@theme/styled-components'

import useI18n from '@store/i18n/useI18n'

import Button from '@components/button/RectangleButton'

interface Props {
  booking: BookingItem
  clickable?: boolean
  navigator: Navigation

  onCancel?: (bookingId: string) => void
}

const BookingInfo = ({ booking, clickable = false, navigator, onCancel }: Props) => {
  const i18n = useI18n()

  return (
    <React.Fragment>
      <MainContainer
        activeOpacity={0.9}
        disabled={!clickable}
        clickable={clickable}
        onPress={() => navigator.push('bookingDetails', { booking: booking })}
      >
        <AlignLine>
          <ProfessionalName clickable={clickable}>{booking.name}</ProfessionalName>

          {!!booking.price && (
            <AdditionalInfo clickable={clickable}>
              {i18n.t('screens.booking.price', { price: booking.price })}
            </AdditionalInfo>
          )}
        </AlignLine>

        <AlignLine>
          <TimeInfoText clickable={clickable}>
            {i18n.t('screens.booking.fullDate', { date: new Date(booking.date) })}
          </TimeInfoText>
        </AlignLine>

        <AlignLine>
          <TimeInfoText clickable={clickable}>
            {i18n.t('screens.booking.scheduleTime', {
              date1: new Date(booking.startHour),
              date2: new Date(booking.endHour),
            })}
          </TimeInfoText>

          {!!booking.activity && <AdditionalInfo clickable={clickable}>{booking.activity}</AdditionalInfo>}
        </AlignLine>
      </MainContainer>

      {(booking.closed || booking.expired) && (
        <ErrorMessage>{i18n.t(`screens.booking.${booking.closed ? 'closed' : 'expired'}Error`)}</ErrorMessage>
      )}

      {!clickable && !!onCancel && (
        <Buttons>
          {!(booking.closed || booking.cancelled || booking.expired) && (
            <DetailsButtonContainer>
              <Button
                label={i18n.t('actions.gotToDetails')}
                color="blue"
                size="tall"
                onPress={() => navigator.push('bookingDetails', { booking: booking })}
                boldText
              />
            </DetailsButtonContainer>
          )}

          <CancelButtonContainer onlyOne={booking.closed || booking.cancelled || booking.expired}>
            <Button
              label={i18n.t('common.cancel')}
              color="white"
              size="tall"
              onPress={() => onCancel(booking.id)}
              boldText
            />
          </CancelButtonContainer>
        </Buttons>
      )}
    </React.Fragment>
  )
}

// CONTAINERS

const MainContainer = styled(TouchableOpacity)<{ clickable: boolean }>`
  margin-top: 24px;
  padding: 16px 22px;
  background-color: ${props => (props.clickable ? props.theme.colors.primary : props.theme.colors.counterPrimary)};
  border-radius: 12px;
`
const AlignLine = styled(View)`
  flex-direction: row;
  margin: 5px 0px;
  justify-content: space-between;
`
const Buttons = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 24px 0px;
`
const DetailsButtonContainer = styled(View)`
  flex: 1;
  margin: 0px 20px 0px 0px;
`
const CancelButtonContainer = styled(View)<{ onlyOne: boolean }>`
  ${props => !props.onlyOne && 'flex: 1'};
  margin: 0px;
`

// TEXTES

const ProfessionalName = styled(Text)<{ clickable: boolean }>`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => (props.clickable ? props.theme.colors.counterPrimary : props.theme.colors.primaryText)};
`
const TimeInfoText = styled(Text)<{ clickable: boolean }>`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => (props.clickable ? props.theme.colors.counterPrimary : props.theme.colors.primaryText)};
  text-transform: capitalize;
`
const AdditionalInfo = styled(Text)<{ clickable: boolean }>`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => (props.clickable ? props.theme.colors.counterPrimary : props.theme.colors.primaryText)};
`
const ErrorMessage = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.info};
  margin-top: 12px;
`

export default BookingInfo
