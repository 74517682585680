import * as React from 'react'

import { View, ScrollView, ViewProperties, ScrollViewProperties } from 'react-native'
import styled from '@theme/styled-components'

import useBreakPoints from '@layout/breakpoint'

interface Props extends ViewProperties, ScrollViewProperties {
  children: (JSX.Element | boolean)[] | JSX.Element
  scrollable?: boolean
  hPadding?: number
}

const ResponsiveMainContainer = ({ children, scrollable, hPadding, ...props }: Props) => {
  const [, breakConf] = useBreakPoints()

  if (scrollable) {
    return (
      <ResponsiveScrollView {...props} paddingV={breakConf.paddingV} paddingH={hPadding || breakConf.paddingH}>
        {children}
      </ResponsiveScrollView>
    )
  }

  return (
    <ResponsiveView {...props} paddingV={breakConf.paddingV} paddingH={hPadding || breakConf.paddingH}>
      {children}
    </ResponsiveView>
  )
}

export default ResponsiveMainContainer

const ResponsiveView = styled(View)<{ paddingV: number; paddingH: number }>`
  padding: ${props => `${props.paddingV}px ${props.paddingH}px`};
`
const ResponsiveScrollView = styled(ScrollView)<{ paddingV: number; paddingH: number }>`
  padding: ${props => `0px ${props.paddingH}px ${props.paddingV}px`};
  margin: ${props => props.paddingV}px 0px 0px;
`
