import * as React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styled from '@theme/styled-components'

import useI18n from '@store/i18n/useI18n'
import { ItineraryLockPoint } from './MapScreen'

interface Props {
  place: Place

  setStartDirection: (b: boolean) => void
  setTo: (to?: Place) => void
  setLocked: (l: ItineraryLockPoint) => void
}

const MapDetails = ({ place, setStartDirection, setTo, setLocked }: Props) => {
  const i18n = useI18n()

  return (
    <MainContainer>
      <InfoContainer>
        <PlaceName>{place.name}</PlaceName>
      </InfoContainer>

      <Action
        activeOpacity={0.9}
        onPress={() => {
          setStartDirection(true)
          setTo({ name: place.name, id: place.id })
          setLocked('TO')
        }}
      >
        <ActionText>{i18n.t('actions.itinerary')}</ActionText>
      </Action>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled(View)`
  margin: 15px 25px 25px 25px;
`
const InfoContainer = styled(View)`
  margin-bottom: 15px;
  padding: 25px;
  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 12px;

  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
`
const Action = styled(TouchableOpacity)`
  padding: 15px;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 12px;

  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
`

// TEXTES

const PlaceName = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryDark};
`
const ActionText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.counterPrimary};
  font-weight: bold;
`

export default MapDetails
