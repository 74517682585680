import * as React from 'react'
import { View, TouchableOpacity, Text, FlatList, ScrollView, Animated } from 'react-native'
import styled from '@theme/styled-components'
import theme from '@theme/Theme'

import { useLocation } from 'react-router-dom'

import Icon from '@components/icons/Icons'
import CircleIcon from '@components/icons/CircleIcon'
import RectangleButton from '@components/button/RectangleButton'
import Alert from '@components/alert/Alert'
import EmergencyAlert from '@components/alert/EmergencyAlert'

import useI18n from '@store/i18n/useI18n'
import * as userStore from '@store/user/user'
import * as authStore from '@store/auth/auth'
import { useStore } from 'effector-react'

import config from '@app/config'

interface Props {
  navigator: Navigation
}

const userShortcuts: DrawerItem[] = [
  { name: 'account', icon: 'user', iconColor: theme.colors.tertiary, bgColor: theme.colors.tertiaryLight },
  // { name: 'parameters', icon: 'settings', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'privacy', icon: 'lock', iconColor: theme.colors.tertiary, bgColor: theme.colors.tertiaryLight },
]

const features: DrawerItem[] = [
  { name: 'home', icon: 'home', iconColor: theme.colors.primary, bgColor: theme.colors.primaryLight },
  { name: 'restaurant', icon: 'restaurant', iconColor: theme.colors.primary, bgColor: theme.colors.primaryLight },
  { name: 'bookingRoom', icon: 'room', iconColor: theme.colors.primary, bgColor: theme.colors.primaryLight },
  { name: 'sitePlan', icon: 'map', iconColor: theme.colors.primary, bgColor: theme.colors.primaryLight },
  // { name: 'incident', icon: 'alert', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },

  {
    name: 'boxNServices',
    icon: 'box-n-services',
    iconColor: theme.colors.boxNServices,
    bgColor: theme.colors.infoLight,
    link: 'https://portail.boxnservices.fr/',
  },
  { name: 'info', icon: 'infos', iconColor: theme.colors.info, bgColor: theme.colors.infoLight },
]

const Menu = ({ navigator }: Props) => {
  const i18n = useI18n()
  const currentScreen = useLocation().pathname.substr(1)
  const [shortcutsVisible, setShortcutsVisible] = React.useState(false)

  const user = useStore(userStore.store).user
  const username = user ? `${user.firstName} ${user.lastName.slice(0, 1)}.` : ''

  const callEmergency = () => {
    EmergencyAlert.open({
      phone: config.constants.emergency,
    })
  }

  const renderEmergency = () => {
    return (
      <FunctionalityContainer activeOpacity={0.9} onPress={callEmergency}>
        <CircleIcon size={18} iconColor={theme.colors.info} bgColor={theme.colors.infoLight} name="phone" />
        <Label isCurrentScreen={false}>{i18n.t(`drawer.titles.emergency`)}</Label>
      </FunctionalityContainer>
    )
  }

  const renderShortcut = ({ name, icon, iconColor, bgColor, link }: DrawerItem) => {
    const isCurrentScreen = currentScreen.includes(name) || (!currentScreen && name === 'home')

    return (
      <FunctionalityContainer
        activeOpacity={0.9}
        onPress={() => {
          if (!!link) {
            window.open(link)
          } else {
            navigator.navigate(name)
          }
        }}
      >
        <CircleIcon size={18} iconColor={iconColor} bgColor={bgColor} name={icon} />
        <Label isCurrentScreen={isCurrentScreen}>{i18n.t(`drawer.titles.${name}`)}</Label>
      </FunctionalityContainer>
    )
  }

  const shortcutsAnimation = React.useMemo(() => new Animated.Value(0), [])
  const rotateAnim = shortcutsAnimation.interpolate({ inputRange: [0, 1], outputRange: ['0deg', '-180deg'] })
  const slideDownAnim = shortcutsAnimation.interpolate({ inputRange: [0, 1], outputRange: [0, 300] })
  const opacityAnim = shortcutsAnimation.interpolate({ inputRange: [0, 1], outputRange: [0, 1] })

  const displayUserShortcuts = () => {
    if (!shortcutsVisible) {
      setShortcutsVisible(!shortcutsVisible)
      Animated.timing(shortcutsAnimation, {
        toValue: 1,
        duration: 500,
      }).start()
    } else {
      Animated.timing(shortcutsAnimation, {
        toValue: 0,
        duration: 500,
      }).start(() => setShortcutsVisible(!shortcutsVisible))
    }
  }

  const logout = () => {
    Alert.open({
      title: i18n.t('actions.logout'),
      text: i18n.t('screens.account.logout'),
      buttons: [
        {
          label: i18n.t('common.yes'),
          action: () => {
            userStore.actions.resetUser()
            authStore.actions.setTokenStatus('none')
            authStore.actions.setLogged(false)

            Alert.close()
          },
        },
        { label: i18n.t('common.no'), action: Alert.close },
      ],
      cancelable: true,
    })
  }

  return (
    <MainContainer>
      <TouchableOpacity activeOpacity={0.9} onPress={() => navigator.navigate('home')}>
        <Icon name="logo" size={100} />
      </TouchableOpacity>

      <ShortcutsView>
        <FunctionalityContainer activeOpacity={0.9} onPress={displayUserShortcuts}>
          <CircleIcon size={18} iconColor={theme.colors.tertiary} bgColor={theme.colors.tertiaryLight} name="user" />
          <Name isCurrentScreen>{username}</Name>

          <Animated.View style={{ transform: [{ rotate: rotateAnim }] }}>
            <Icon name="chevronDown" color={theme.colors.primaryDark} size={16} />
          </Animated.View>
        </FunctionalityContainer>

        {shortcutsVisible && (
          <Animated.FlatList
            style={{ opacity: opacityAnim, maxHeight: slideDownAnim }}
            data={userShortcuts}
            renderItem={({ item }: { item: DrawerItem }) => renderShortcut(item)}
            extraData={currentScreen}
            keyExtractor={(item: DrawerItem) => item.name}
          />
        )}

        <Separator />
        {renderShortcut(features[0])}
        {renderEmergency()}
        <FlatList
          data={features.slice(1)}
          renderItem={({ item }) => renderShortcut(item)}
          extraData={currentScreen}
          keyExtractor={(item: DrawerItem) => item.name}
        />
      </ShortcutsView>
      <Footer>
        <RectangleButton label={i18n.t('drawer.logout')} size="small" color="orange" uppercase onPress={logout} />
        <AboutContainer onPress={() => navigator.navigate('privacy')}>
          <Icon size={14} name="infos" color={theme.colors.secondaryText} />
          <About>{i18n.t('drawer.titles.privacy')}</About>
        </AboutContainer>
      </Footer>
    </MainContainer>
  )
}

export default Menu

const MainContainer = styled(View)`
  width: 300px;
  padding: 65px 2px 25px 48px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  z-index: 1;
`

const FunctionalityContainer = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin: 16px 0px;
`
const Label = styled(Text)<{ isCurrentScreen: boolean }>`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => (props.isCurrentScreen ? props.theme.colors.primaryDark : props.theme.colors.secondaryText)};
  font-weight: bold;
  margin-left: 12px;
  margin-right: 7px;
`

const Name = styled(Label)<{ isCurrentScreen: boolean }>`
  text-transform: capitalize;
`

const Separator = styled(View)`
  height: 1px;
  background-color: ${props => props.theme.colors.separator};
  margin: 16px 0px;
`

const Footer = styled(View)`
  margin-top: 30px;
  padding-right: 46px;
`

const AboutContainer = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

const About = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.secondaryText};
  text-transform: uppercase;
  padding-left: 9px;
`

const ShortcutsView = styled(ScrollView)`
  flex: 1;
`
