import * as React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styled from '@theme/styled-components'

import useMap from './useMap.web'
import useI18n from '@store/i18n/useI18n'
import api from '@api/api'
import { ItineraryLockPoint } from './MapScreen'

import MapDetails from './MapDetails'
import MapHeader from './MapHeader.web'

interface Props {
  hash: string
  siteId: string
  destination?: any
}

const Map = ({ hash, siteId, destination }: Props) => {
  const i18n = useI18n()
  const [ref, , building, floor, place, buildings, actions, , , nav] = useMap(hash, i18n.lang)

  const [startDirection, setStartDirection] = React.useState(false)
  const [opened, setOpened] = React.useState(false)
  const [locked, setLocked] = React.useState<ItineraryLockPoint>()
  const [to, setTo] = React.useState<Place>()
  const [places, setPlaces] = React.useState<Place[]>([])

  const currentBuilding = React.useMemo(() => buildings.find(b => b.id === building), [building, buildings])
  const currentFloor = React.useMemo(
    () => currentBuilding && currentBuilding.floors.find(f => f.id === floor),
    [currentBuilding, floor]
  )
  const allFloors = React.useMemo(() => {
    if (currentBuilding) {
      return currentBuilding.floors.sort((a, b) => b.index - a.index)
    }
    return []
  }, [currentBuilding])

  React.useEffect(() => {
    api.map.getAllPlaces(siteId).then(res => {
      setPlaces(res.places)
      actions.updatePOIs(res.places)
    })
  }, [])

  return (
    <MainContainer>
      <SafeContainer>
        <MapHeader
          places={places}
          startDirection={startDirection}
          setStartDirection={setStartDirection}
          opened={opened}
          setOpened={setOpened}
          locked={locked}
          setLocked={setLocked}
          to={to}
          setTo={setTo}
          destination={destination}
          computeRoute={actions.computeRoute}
          nav={nav}
          stopRoute={actions.stopRoute}
          nextRoute={actions.nextRoute}
          previousRoute={actions.previousRoute}
        />
      </SafeContainer>

      <div style={{ flex: 1 }} ref={ref} />

      <Floors>
        {allFloors.map(f => (
          <Floor
            key={f.id}
            focused={!!currentFloor && currentFloor.id === f.id}
            activeOpacity={0.9}
            onPress={() => {
              if (!!currentBuilding) {
                actions.goTo(currentBuilding.id, f.id)
              }
            }}
          >
            <FloorText>{f.name[0] || f.id}</FloorText>
          </Floor>
        ))}
      </Floors>

      {!!place && (
        <DetailsContainer>
          <MapDetails place={place} setStartDirection={setStartDirection} setTo={setTo} setLocked={setLocked} />
        </DetailsContainer>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled(View)`
  flex: 1;
  position: relative;
`
const Floors = styled(View)`
  position: absolute;
  right: 0;
  bottom: 0;
`
const Floor = styled(TouchableOpacity)<{ focused: boolean }>`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: ${props => (props.focused ? props.theme.colors.greyMenu : props.theme.colors.counterPrimary)};

  align-items: center;
  justify-content: center;

  margin: 0px 10px;
  margin-bottom: 10px;

  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
`
const DetailsContainer = styled(View)`
  max-width: 600px;

  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
`
const SafeContainer = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-color: ${props => props.theme.colors.background};
  border: 1px solid ${props => props.theme.colors.separator};
  ${props => props.theme.constants.shadow};
`

// TEXTES

const FloorText = styled(Text)`
  ${props => props.theme.fonts.genericButton};
  color: ${props => props.theme.colors.primaryDark};
  text-transform: capitalize;
`

export default Map
