import * as React from 'react'
import { View, Text } from 'react-native'

import styled from '@theme/styled-components'

interface Props {
  headerTitle?: string
}

const RootHeader = ({ headerTitle }: Props) => {
  return (
    <MainContainer>
      <Title>{headerTitle}</Title>
    </MainContainer>
  )
}

export default RootHeader

const MainContainer = styled(View)`
  flex-direction: row;
  padding: 30px 24px;
  background-color: ${props => props.theme.colors.background};
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`
