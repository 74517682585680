import config from '@app/config'
import ws from '@api/webservice'

const api = {
  all: (): Promise<EditorialList> => {
    return ws(`${config.ws.edito.all}`, 'GET')
  },
  get: (id: string): Promise<EditorialDetail> => {
    return ws(`${config.ws.edito.get.replace('{id}', id)}`, 'GET')
  },
}

export default api
