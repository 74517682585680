import * as React from 'react'
import { View, Animated, TouchableOpacity, TextInput, Text, FlatList } from 'react-native'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'

import ReactDOM from 'react-dom'

import sanitize from '@utils/Sanitizer'

import Icon from '@components/icons/Icons'

interface Props {
  i18n: I18nState
  onItemSelected: (item: Place) => void
  places: Place[]
}

const WIDTH = 400
const MIN_SEARCH_CHAR = 3

const Component = ({ i18n, onItemSelected, places }: Props) => {
  const anim = React.useMemo(() => new Animated.Value(0), [])

  const [search, setSearch] = React.useState('')
  const [filteredPlaces, setFilteredPlaces] = React.useState<Place[]>(places)

  const close = () => {
    Animated.timing(anim, { toValue: 0, duration: 250, useNativeDriver: true }).start(MapSearch.close)
  }

  React.useEffect(() => {
    Animated.timing(anim, { toValue: 1, duration: 250, useNativeDriver: true }).start()
  }, [anim])

  React.useEffect(() => {
    if (search.length >= MIN_SEARCH_CHAR) {
      setFilteredPlaces(
        places
          .map(fp => ({ id: fp.id, name: fp.name || fp.id, searchable: fp.searchable })) // Au cas où name est undefined
          .filter(p => sanitize.sanitize(p.name).includes(sanitize.sanitize(search)) && !!p.searchable)
      )
    } else {
      setFilteredPlaces(
        places.map(fp => ({ id: fp.id, name: fp.name || fp.id, searchable: fp.searchable })).filter(p => !!p.searchable)
      ) // Le .map -> Au cas où name est undefined)
    }
  }, [search])

  const renderItem = ({ item }: { item: Place }) => {
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          onItemSelected({ id: item.id, name: item.name })
          close()
        }}
      >
        <Space>{item.name}</Space>
      </TouchableOpacity>
    )
  }

  const translate = anim.interpolate({ inputRange: [0, 1], outputRange: [WIDTH, 0] })

  return (
    <MainContainer style={{ opacity: anim }}>
      <Back activeOpacity={1} onPress={close} />

      <Container style={{ transform: [{ translateX: translate }] }}>
        <HeaderContainer>
          <TouchableOpacity activeOpacity={0.9} onPress={close}>
            <Icon name="back" size={25} />
          </TouchableOpacity>

          <Title>{i18n.t(`screens.map.itinerary`)}</Title>
        </HeaderContainer>
        <Input value={search} onChangeText={setSearch} placeholder={i18n.t('screens.map.search')} />
        <ContentContainer>
          <FlatList
            data={filteredPlaces}
            keyExtractor={item => item.id}
            renderItem={renderItem}
            ItemSeparatorComponent={() => <Separator />}
            keyboardShouldPersistTaps="handled"
          />
        </ContentContainer>
      </Container>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Back = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #0004;
`

const Container = styled(Animated.View)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  width: 400px;

  background-color: ${props => props.theme.colors.background};
`

const HeaderContainer = styled(Animated.View)`
  flex-direction: column;
  padding: 30px 24px;
`

const Title = styled(Animated.Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const Input = styled(TextInput)`
  height: 40px;
  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 7px;
  border: 1px solid ${props => props.theme.colors.inputBorder};
  padding: 0px 20px;
  margin: 0px 24px;
`

const Space = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.secondaryText};
  font-weight: bold;
  padding: 15px;
`

const Separator = styled(View)`
  height: 1px;
  background-color: ${props => props.theme.colors.greyMenu};
`

const ContentContainer = styled(View)`
  flex: 1;
  flex-direction: column;
  padding: 15px 24px;
`

let modalRoot: any

const MapSearch = {
  open: (props: Props) => {
    modalRoot = document.getElementById('modal_root')

    if (modalRoot) {
      ReactDOM.render(
        <ThemeProvider theme={Theme}>
          <Component {...props} />
        </ThemeProvider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default MapSearch
