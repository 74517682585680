import * as React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  BackHandler,
  Animated,
  Easing,
  Keyboard,
  Platform,
  SafeAreaView,
} from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'
import RootComponent from 'react-native-root-siblings'
import Animators from '@components/root/Animators'

interface Props {
  i18n: I18nState
  onValidate: (previous: string, value: string) => void
  onClose?: () => void
}

const Component = ({ i18n, onValidate, onClose }: Props) => {
  const anim = React.useRef(new Animated.Value(0))

  const [previous, setPrevious] = React.useState('')
  const [current, setCurrent] = React.useState('')
  const [confirm, setConfirm] = React.useState('')

  const [error, setError] = React.useState<string>()

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Keyboard.dismiss()

    if (onClose) {
      onClose()
    }

    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      FieldPassword.close
    )
  }

  const checkPassword = (pwd: string) => {
    return !!pwd.match(
      /^(?=.*[a-zA-Z])(?=.*[$&+,:;=?@#|'<>.\\-^*()%!])(?=.*[0-9]).[a-zA-Z0-9$&+,:;=?@#|'<>.\\-^*()%!]{7,49}$/
    )
  }

  const submit = () => {
    if (current !== confirm) {
      setError('errors.field.passwordMissmatch')
      return
    }

    if (!checkPassword(current)) {
      setError('errors.field.passwordFormat')
      return
    }

    setError(undefined)
    onValidate(previous, current)
    close()
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <SafeAreaView>
        <HeaderContainer>
          <BackButton activeOpacity={0.9} onPress={close}>
            <Icon name="back" size={24} color={Theme.colors.disable} />
          </BackButton>
          <Title>{i18n.t('screens.account.password.title')}</Title>
        </HeaderContainer>
      </SafeAreaView>

      <FormContainer>
        <Animators>
          <InputTitle>{i18n.t('screens.account.password.previous')}</InputTitle>
          <InputContainer>
            <Input value={previous} onChangeText={setPrevious} secureTextEntry />
          </InputContainer>

          <InputTitle>{i18n.t('screens.account.password.current')}</InputTitle>
          <InputContainer>
            <Input value={current} onChangeText={setCurrent} secureTextEntry />
          </InputContainer>

          <InputTitle>{i18n.t('screens.account.password.confirm')}</InputTitle>
          <InputContainer>
            <Input value={confirm} onChangeText={setConfirm} secureTextEntry />
          </InputContainer>
        </Animators>
        {!!error && (
          <WarningContainer>
            <Icon name="alert" color={Theme.colors.info} size={20} />
            <WarningText>{i18n.t(error)}</WarningText>
          </WarningContainer>
        )}
      </FormContainer>

      <Validate activeOpacity={0.8} onPress={submit}>
        <SafeAreaView>
          <ValidateText>{i18n.t('actions.validate')}</ValidateText>
        </SafeAreaView>
      </Validate>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.background};
`

const HeaderContainer = styled(View)`
  padding: 30px 24px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const BackButton = styled(TouchableOpacity)`
  margin-bottom: 10px;
`

const FormContainer = styled(View)`
  ${Platform.OS === 'web' ? undefined : 'flex: 1'};

  margin: 0px 25px;
  margin-bottom: 25px;
`

const InputTitle = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.secondaryText};

  margin-bottom: 5px;
  text-transform: uppercase;
`

const InputContainer = styled(View)`
  height: 40px;
  justify-content: center;
  padding: 0px 15px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 7px;

  margin-bottom: 30px;
`

const Input = styled(TextInput)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
`

const Validate = styled(TouchableOpacity)`
  align-items: center;
  padding: 15px;
  border-radius: ${Platform.OS === 'web' ? 12 : 0}px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: ${props => props.theme.colors.primary};

  margin: 0px ${Platform.OS === 'web' ? 100 : 0}px;
`

const ValidateText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: #fff;
  font-weight: bold;
`

const WarningContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`

const WarningText = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.info};
  font-weight: bold;
  margin: 0px 15px;
`

let root: RootComponent
let rootActive = false

const FieldPassword = {
  open: (conf: Props) => {
    if (!rootActive) {
      rootActive = true
      root = new RootComponent(<Component {...conf} />)

      BackHandler.addEventListener('hardwareBackPress', FieldPassword.onRequestClose)
    }
  },

  close: () => {
    if (rootActive && root) {
      root.destroy()
      rootActive = false
    }
  },

  onRequestClose: () => {
    BackHandler.removeEventListener('hardwareBackPress', FieldPassword.onRequestClose)
    FieldPassword.close()

    return true
  },
}

export { Component }
export default FieldPassword
