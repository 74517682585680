import * as React from 'react'
import { View, ScrollView, ScrollViewProperties } from 'react-native'
import styled from '@theme/styled-components'

import RootHeader from './RootHeader.web'

import useBreakPoints from '@layout/breakpoint'

interface Props extends ScrollViewProperties {
  children?: JSX.Element
  renderContent?: () => JSX.Element
  flexContent?: number
  centered?: boolean
  fixed?: boolean

  header?: boolean
  headerTitle?: string
  hPadding?: number
}

const Root = ({
  renderContent,
  children,
  centered,
  fixed,
  header,
  headerTitle,
  flexContent,
  hPadding,
  ...rest
}: Props) => {
  const [breakPoints] = useBreakPoints()

  const renderMain = () => {
    if (centered) {
      return (
        <CenteredContainer breakPoint={breakPoints}>
          {header && <RootHeader headerTitle={headerTitle} />}
          {children}
        </CenteredContainer>
      )
    }

    if (fixed) {
      return (
        <Container breakPoint={breakPoints}>
          {header && <RootHeader headerTitle={headerTitle} />}
          {children}
        </Container>
      )
    }

    return (
      <ScrollContainer breakPoint={breakPoints} scrollIndicatorInsets={{ right: 1 }} bounces={false} {...rest}>
        {header && <RootHeader headerTitle={headerTitle} />}
        {children}
      </ScrollContainer>
    )
  }

  if (renderContent) {
    return (
      <DualContainer breakPoint={breakPoints}>
        {renderMain()}

        <Separator breakPoint={breakPoints} />

        {renderContent && (
          <LateralContainer breakPoint={breakPoints} flexContent={flexContent} hPadding={hPadding}>
            {renderContent()}
          </LateralContainer>
        )}
      </DualContainer>
    )
  }

  return renderMain()
}

export default Root

const CenteredContainer = styled(View)<{ breakPoint: number }>`
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${props => props.theme.colors.webBackground};
  ${props => (props.breakPoint > 800 ? 'padding: 0px 100px;' : 'padding: 0px 10px;')}
`

const ScrollContainer = styled(ScrollView)<{ breakPoint: number }>`
  flex: 1;
  background-color: ${props => props.theme.colors.webBackground};
  ${props => (props.breakPoint > 800 ? 'padding: 0px 100px;' : 'padding: 0px 10px;')}
`

const Container = styled(View)<{ breakPoint: number }>`
  flex: 1;
  background-color: ${props => props.theme.colors.webBackground};
  ${props => (props.breakPoint > 800 ? 'padding: 0px 10px 0px 40px;' : 'padding: 0px 10px;')}
`

const DualContainer = styled(View)<{ breakPoint: number }>`
  flex: 1;
  ${props => props.breakPoint > 800 && 'flex-direction: row;'}
  background-color: ${props => props.theme.colors.webBackground};
`

const Separator = styled(View)<{ breakPoint: number }>`
  ${props => (props.breakPoint > 800 ? 'width: 1px; margin: 65px 0px;' : 'height: 1px; margin: 15px 0px;')}
  background-color: #dedede;
`

const LateralContainer = styled(View)<{ breakPoint: number; flexContent?: number; hPadding?: number }>`
  flex: ${props => (props.flexContent ? props.flexContent : 1)};
  background-color: ${props => props.theme.colors.webBackground};
  ${props => (props.breakPoint > 800 ? `padding: 0px ${props.hPadding || 40}px;` : 'padding: 0px 10px;')}
`
