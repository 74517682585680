import * as React from 'react'
import { View, Text, Image, TouchableOpacity, FlatList, Platform, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Root from '@components/root/Root'
import { Animation } from '@components/root/Animators'

import useI18n from '@store/i18n/useI18n'
import { isOpen } from './RestoUtils'

import api from '@api/api'

import useBreakPoints from '@layout/breakpoint'

import analytics from '@utils/analytics'

interface Props {
  navigator: Navigation
}

const RestaurantScreen = ({ navigator }: Props) => {
  const i18n = useI18n()
  const [restaurants, setRestaurants] = React.useState<RestaurantDetails[]>([])
  const [loading, setLoading] = React.useState(true)

  const [breakPoint] = useBreakPoints()

  React.useEffect(() => {
    analytics.onScreen('Restaurants', 'RestaurantScreen')

    api.resto.list().then(res => {
      setRestaurants(res.sort((a, b) => a.restaurant.name.localeCompare(b.restaurant.name)))
      setLoading(false)
    })
  }, [])

  const renderRestaurant = ({ item }: { item: RestaurantDetails }) => {
    const open = isOpen(item.restaurant.opening_time, item.restaurant.closing_time)

    return (
      <Card
        breakPoint={breakPoint}
        key={item.restaurant.id}
        activeOpacity={0.9}
        onPress={() => {
          if (Platform.OS === 'web') {
            navigator.pushPath(`restaurant/${item.restaurant.id}`)
          } else {
            navigator.push('restaurantDetail', { restoId: item.restaurant.id })
          }
        }}
      >
        {!!item.restaurant.image && <Picture source={{ uri: item.restaurant.image }} />}
        <InfoContainer>
          <Name>{item.restaurant.name}</Name>
          <Location>{/** A compléter quand le serveur enverra l'info */}</Location>
          <StatusContainer>
            <StatusCircle open={open} />
            <Status open={open}>{i18n.t(`screens.restaurant.${open ? 'open' : 'closed'}`)}</Status>
          </StatusContainer>
        </InfoContainer>
      </Card>
    )
  }

  if (loading) {
    return (
      <Root
        navigator={navigator}
        centered
        header={Platform.OS !== 'web'}
        headerTitle={i18n.t('screens.restaurant.title')}
      >
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  if (restaurants.length <= 0) {
    return (
      <Root navigator={navigator} header headerTitle={i18n.t('screens.restaurant.title')}>
        <EmptyContainer>
          <EmptyText>{i18n.t('errors.tryAgain')}</EmptyText>
        </EmptyContainer>
      </Root>
    )
  }

  return (
    <React.Fragment>
      <Root fixed={Platform.OS !== 'web'} navigator={navigator} header headerTitle={i18n.t('screens.restaurant.title')}>
        <Animation>
          <CardsContainer>
            <FlatList
              data={restaurants}
              keyExtractor={item => item.restaurant.id}
              contentContainerStyle={{ paddingBottom: 56 }}
              showsVerticalScrollIndicator={false}
              numColumns={Platform.OS === 'web' && breakPoint > 800 ? 2 : 1}
              renderItem={renderRestaurant}
              key={breakPoint}
            />
          </CardsContainer>
        </Animation>
      </Root>
      {Platform.OS !== 'web' && (
        <ForkContainer>
          <Animation animation="fade_top">
            <Fork resizeMode="contain" source={require('@assets/images/fork.png')} />
          </Animation>
        </ForkContainer>
      )}
    </React.Fragment>
  )
}

export default RestaurantScreen

const EmptyContainer = styled(View)`
  padding: 24px;
  justify-content: center;
  align-items: center;
`

const CardsContainer = styled(View)`
  padding: 20px;
`

const Card = styled(TouchableOpacity)<{ breakPoint: number }>`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
  ${props => Platform.OS === 'web' && props.breakPoint >= 800 && 'width: 350px; margin-right: 50px'};
`

const InfoContainer = styled(View)`
  padding: 16px 22px 21px;
`

const Name = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryDark};
`

const Location = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.disable};
`

const StatusContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`

const Status = styled(Text)<{ open: boolean }>`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => (props.open ? props.theme.colors.open : props.theme.colors.closed)};
  text-transform: uppercase;
`

const StatusCircle = styled(View)<{ open: boolean }>`
  background-color: ${props => (props.open ? props.theme.colors.open : props.theme.colors.closed)};
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 8px;
`

const EmptyText = styled(Text)`
  ${props => props.theme.fonts.genericText};
`

const Picture = styled(Image)`
  height: 150px;
  resize-mode: cover;
`

const ForkContainer = styled(View)`
  position: absolute;
  top: 0px;
  right: 31px;
`

const Fork = styled(Image)`
  width: 52px;
  height: 118px;
`
