import useResizeWindow from './useResizeWindow'
interface BreakConf {
  margin: number
  paddingV: number
  paddingH: number
}
const BREAK_POINTS = [0, 800, 1260]
const useBreakPoints = (): [number, BreakConf] => {
  const [, width] = useResizeWindow()
  const breakp = BREAK_POINTS.findIndex((b, idx) => {
    return idx === BREAK_POINTS.length - 1 || (width >= b && width < BREAK_POINTS[idx + 1])
  })
  return [BREAK_POINTS[breakp], BREAK_CONFIGS[breakp]]
}
export default useBreakPoints
const BREAK_CONFIGS: BreakConf[] = [
  /* 0 to 800 */
  {
    margin: 30,
    paddingV: 20,
    paddingH: 10,
  },
  /* 800 to 1260 */
  {
    margin: 18,
    paddingV: 18,
    paddingH: 54,
  },
  /* 1260 + */
  {
    margin: 100,
    paddingV: 70,
    paddingH: 100,
  },
]
