import * as React from 'react'
import { View, Text, TouchableOpacity, Image, GestureResponderEvent, Platform } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import CircleIcon from '@components/icons/CircleIcon'
import NewsDetail from './NewsDetail'

import api from '@api/api'
import * as newsStore from '@store/news/news'
import useReducer from '@store/useReducer'

import config from '@app/config'

import useBreakPoints from '@app/ui/layout/breakpoint'

interface Props {
  news: Article
  i18n: I18nState
  onItemPressed?: () => void
  scrollOffset?: number
}

type PicturePosition = {
  actuId: string
  x: number
  y: number
}

const HomeNewsItem = ({ news, i18n, onItemPressed, scrollOffset }: Props) => {
  const likedNews = useReducer(newsStore.store, s => s.likedNews)
  const drawerHeight = useReducer(newsStore.store, s => s.drawerHeight)
  const isLiked = !!likedNews && likedNews.find(n => n === news.id)
  const [breakPoint] = useBreakPoints()
  const [picturePosition, setPicturePosition] = React.useState<PicturePosition>()

  const onItemSelectedItem = (evt: GestureResponderEvent, news: Article) => {
    if (onItemPressed) {
      onItemPressed()
      return
    } else {
      api.news.get(news.id).then(detail => {
        NewsDetail.open({
          news: detail,
          //position X de départ de l'animation zoom de l'image
          startX: !!picturePosition ? picturePosition.x : 1,
          //position Y de départ de l'animation zoom de l'image
          startY: !!picturePosition ? picturePosition.y : 1,
        })
      })
    }
  }

  const pictureRefTimer = React.useRef<NodeJS.Timeout | undefined>()

  const onPictureRef = React.useMemo(
    () => (Picture: Image | null) => {
      //TimeOut pour éviter de mettre à jour de trop nombreuses fois la position des images lors d'un Scroll
      if (pictureRefTimer.current) {
        // Si il y a déjà un Timer en cours, on le remet à 0
        clearTimeout(pictureRefTimer.current)
      }
      pictureRefTimer.current = setTimeout(() => {
        //Quand le Timer arrive à 0, on mesure la position X et Y de l'image
        pictureRefTimer.current = undefined
        !!Picture &&
          Picture.measureInWindow(
            (x, y) =>
              // Cette ligne pour éviter de mettre à jour ce state indéfiniment
              (x !== picturePosition?.x || y !== picturePosition?.y) &&
              // On renseigne dans le state les coordonnées récupérées. actuId renseigné dans le cas où il y a plusieurs items
              setPicturePosition({ actuId: news.id, x: x, y: y })
          )
      }, 100)
    },
    //scrollOffset en dépendance pour mesurer à nouveau la position des images à chaque Scroll
    [setPicturePosition, scrollOffset, drawerHeight]
  )

  return (
    <Item activeOpacity={0.9} onPress={evt => onItemSelectedItem(evt, news)}>
      <Picture
        // On récupère la position x et y de l'image pour savoir où demarrer l'animation de zoom
        ref={onPictureRef}
        source={{ uri: news.photo ? `${config.SERVER_PREFIX}${news.photo.url}` : '' }}
        resizeMode="cover"
      />
      <ItemContent>
        <Tag type={news.type}>
          <TagText type={news.type}>{i18n.t(`screens.home.actu.type.${news.type}`)}</TagText>
        </Tag>
        <ItemTitle numberOfLines={Platform.OS === 'web' && breakPoint <= 800 ? 1 : 2}>{news.title}</ItemTitle>
        <Line>
          <ItemDate numberOfLines={1}>
            {i18n.t('screens.home.actu.date', { date: new Date(news.publicationDate) })}
          </ItemDate>
          <CircleIcon
            name="heart"
            bgColor={isLiked ? Theme.colors.tertiaryLight : Theme.colors.greyMenu}
            iconColor={isLiked ? Theme.colors.info : Theme.colors.secondaryText}
            size={12}
            offset={5}
          />
          <Likes numberOfLines={1}>
            {i18n.t('screens.home.actu.like', { count: news.nbLikes > 0 ? news.nbLikes : 0 })}
          </Likes>
        </Line>
      </ItemContent>
    </Item>
  )
}

export default HomeNewsItem

const Item = styled(TouchableOpacity)`
  margin-top: 30px;
  flex-direction: row;
`

const Picture = styled(Image)`
  height: 110px;
  width: 110px;
  border-radius: 15px;
  margin-right: 30px;
`

const ItemTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  margin: 8px 0px;
  flex: 1;
`

const ItemContent = styled(View)`
  flex: 1;
  align-items: flex-start;
`

const ItemDate = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  margin-right: 15px;
`

const Likes = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  margin-left: 5px;
`

const Tag = styled(View)<{ type: string; inactive?: boolean }>`
  padding: 5px 15px;
  border-radius: 15px;
  background-color: ${props =>
    props.inactive
      ? props.theme.colors.greyMenu
      : props.type === 'event'
      ? props.theme.colors.primaryLight
      : props.theme.colors.tertiaryLight};
`

const TagText = styled(Text)<{ type: string; inactive?: boolean }>`
  ${props => props.theme.fonts.genericButton};
  color: ${props =>
    props.inactive
      ? props.theme.colors.primaryDark
      : props.type === 'event'
      ? props.theme.colors.primary
      : props.theme.colors.tertiary};
  font-weight: bold;
`

const Line = styled(View)`
  flex-direction: row;
  align-items: center;
`
