import * as React from 'react'
import styled from '@theme/styled-components'

import AuthenticationScreen from './AuthenticationScreen'
import { View, Image } from 'react-native'

import useBreakPoints from '@app/ui/layout/breakpoint'

const Login = () => {
  const [breakPoint] = useBreakPoints()

  return (
    <MainContainer>
      {breakPoint >= 800 && (
        <ImageContainer>
          <Picture source={require('@assets/images/coeurnet.png')} resizeMode="cover" />
        </ImageContainer>
      )}

      <AuthContainer>
        <AuthenticationScreen />
      </AuthContainer>
    </MainContainer>
  )
}

export default Login

const MainContainer = styled(View)`
  flex: 1;
  flex-direction: row;
`

const ImageContainer = styled(View)`
  flex: 1;
`

const Picture = styled(Image)`
  flex: 1;
`

const AuthContainer = styled(View)`
  flex: 1;
`
