import * as React from 'react'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'
import { TouchableOpacity, Image, ImageSourcePropType, Animated, Easing, Dimensions } from 'react-native'

import ReactDOM from 'react-dom'

interface Props {
  source: ImageSourcePropType
}

const WIDTH = Dimensions.get('window').width
const HEIGHT = Dimensions.get('window').height

const Component = ({ source }: Props) => {
  const anim = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      ImageModal.close
    )
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <Container activeOpacity={0.8} onPress={close}>
        <Img source={source} resizeMode="contain" />
      </Container>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Container = styled(TouchableOpacity)`
  flex: 1;
  background-color: #fff;
  justify-content: center;
  align-items: center;
`

const Img = styled(Image)`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`

let alertRoot: any

const ImageModal = {
  open: (conf: Props) => {
    alertRoot = document.getElementById('alert_root')
    if (alertRoot) {
      ReactDOM.render(
        <ThemeProvider theme={Theme}>
          <Component {...conf} />
        </ThemeProvider>,
        alertRoot
      )
    }
  },
  close: () => {
    if (alertRoot) {
      ReactDOM.unmountComponentAtNode(alertRoot)
    }
  },
}

export default ImageModal
