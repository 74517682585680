import { createEvent, createStore } from 'effector'

export const actions = {
  like: createEvent<string>('like'),
  dislike: createEvent<string>('dislike'),
}

const initialState: DomoticState = {
  likedControllers: [],
}

export const store = createStore<DomoticState>(initialState)
  .on(actions.like, (s, id) => ({
    ...s,
    likedControllers: [...s.likedControllers, id],
  }))
  .on(actions.dislike, (s, id) => ({
    ...s,
    likedControllers: s.likedControllers.filter(s => s !== id),
  }))
