import * as React from 'react'
import { View, Text, FlatList, ScrollView, TouchableOpacity } from 'react-native'
import styled from '@theme/styled-components'

import Root from '@components/root/Root'
import RestaurantHeaderRefill from '@screens/badge/RestaurantHeaderRefill'
import { Animation } from '@components/root/Animators'
// import RectangleButton from '@components/button/RectangleButton'
import MenuAndDay from '@components/restauration/MenuAndDay'

import { isOpen } from './RestoUtils'
import useI18n from '@store/i18n/useI18n'
import { isToday } from 'date-fns'

import LottieView from 'lottie-react-native'

import api from '@api/api'

interface RestaurantSchedule {
  openingTime: string
  closingTime: string
  day: string
}

interface Props {
  navigator: Navigation
  restoId: string
}

const RestaurantDetailScreen = ({ navigator, restoId }: Props) => {
  const [resto, setResto] = React.useState<RestaurantDetails>()
  const [selectedCat, setSelectedCat] = React.useState(0)
  const menuListRef = React.useRef<FlatList>(null)
  const i18n = useI18n()
  const [leftPanelWidth, setLeftPanelWidth] = React.useState(0)

  React.useEffect(() => {
    api.resto.get(restoId).then(setResto)
  }, [])

  if (!resto) {
    return null
  }

  const renderMenuBar = (catNames: string[]) => {
    return (
      <MenuTab key={selectedCat}>
        {catNames.map((cn, index) => {
          const selected = selectedCat === index
          const tabWidth = cn.length * 10 + 50
          return (
            <MenuCell
              width={tabWidth}
              activeOpacity={0.8}
              key={cn}
              onPress={() => {
                if (menuListRef.current) {
                  menuListRef.current.scrollToOffset({ offset: index * leftPanelWidth, animated: true })
                  setSelectedCat(index)
                }
              }}
            >
              <MenuText numberOfLines={1} selected={selected}>
                {cn}
              </MenuText>
              <SubView selected={selected} width={tabWidth} />
            </MenuCell>
          )
        })}
      </MenuTab>
    )
  }

  const renderMeal = (item: Recipes) => {
    const energy = item.nutritional_values.find(n => n.type === 'Energie kcal')
    const fat = item.nutritional_values.find(n => n.type === 'Matières grasses')
    return (
      <Card key={item.label} width={leftPanelWidth}>
        <RecipeName>{item.label}</RecipeName>
        {item.allergens.length > 0 && (
          <Allergens>{i18n.t('screens.restaurant.detail.allergens', { items: item.allergens })}</Allergens>
        )}
        <NutrionnalValuesContainer>
          {energy && (
            <NutrionalValues>
              <InfoText>{i18n.t('screens.restaurant.detail.energy')}</InfoText>
              <InfoValueText>{energy.value}</InfoValueText>
            </NutrionalValues>
          )}
          {fat && (
            <NutrionalValues>
              <InfoText>{i18n.t('screens.restaurant.detail.fat')}</InfoText>
              <InfoValueText>{fat.value}</InfoValueText>
            </NutrionalValues>
          )}
        </NutrionnalValuesContainer>
      </Card>
    )
  }

  const renderMeals = ({ item }: { item: Recipes[] }) => {
    return (
      <ScrollView contentContainerStyle={{ paddingBottom: 120 }} scrollEnabled={false}>
        {item.map(renderMeal)}
      </ScrollView>
    )
  }

  const renderMenu = () => {
    const todayMeals = resto.current_week_meals.find(cwm => isToday(new Date(cwm.date)))

    if (!todayMeals) {
      return null
    }

    const categories = todayMeals.meals.reduce((acc, curr) => acc.concat(curr.categories), [] as Category[])

    const filteredCategories = categories.reduce((acc, curr) => {
      if (curr.name === "Dessert'Bar") {
        return { ...acc, Desserts: acc.Desserts.concat(curr.recipes) }
      }
      return { ...acc, [curr.name]: curr.recipes }
    }, {} as Categories)

    return (
      <View
        onLayout={e => {
          setLeftPanelWidth(e.nativeEvent.layout.width)
        }}
      >
        <MenuAndDay />
        {renderMenuBar(Object.keys(filteredCategories))}
        <FlatList
          ref={menuListRef}
          horizontal
          pagingEnabled
          data={Object.values(filteredCategories)}
          renderItem={item => renderMeals(item)}
          showsHorizontalScrollIndicator={false}
          scrollEnabled={false}
          style={{ alignSelf: 'center', width: leftPanelWidth + 48 }}
        />
      </View>
    )
  }

  const renderOccupancy = (affluence: Affluence) => {
    const lottie =
      affluence.label === 'SATURATED' ? (
        <LottieView autoSize autoPlay loop source={require('@assets/lotties/saturated.json')} />
      ) : affluence.label === 'SLOW' ? (
        <LottieView autoSize autoPlay loop source={require('@assets/lotties/slow.json')} />
      ) : (
        <LottieView autoSize autoPlay loop source={require('@assets/lotties/fluid.json')} />
      )
    return (
      <OccupancyContainer>
        {lottie}
        <OccupancyTextContainer>
          <OccupancySubtitle>{i18n.t('screens.restaurant.detail.currently')}</OccupancySubtitle>
          <OccupancyStatus>{i18n.t(`screens.restaurant.detail.affluence.${affluence.label}`)}</OccupancyStatus>
        </OccupancyTextContainer>
      </OccupancyContainer>
    )
  }

  const renderHours = ({ item }: { item: RestaurantSchedule }) => {
    return (
      <DayContainer>
        <DayText>{i18n.t(`screens.restaurant.detail.days.${item.day}`)}</DayText>
        <ScheduleText>
          {i18n.t('screens.restaurant.detail.schedule', {
            openingTime: item.openingTime.slice(0, -3),
            closingTime: item.closingTime.slice(0, -3),
          })}
        </ScheduleText>
      </DayContainer>
    )
  }

  const renderSchedule = () => {
    /** A remplacer lorsqu'on aura les informations pour toute la semaine */
    const hours: RestaurantSchedule[] = [
      {
        openingTime: resto.restaurant.opening_time,
        closingTime: resto.restaurant.closing_time,
        day: 'monday',
      },
      {
        openingTime: resto.restaurant.opening_time,
        closingTime: resto.restaurant.closing_time,
        day: 'tuesday',
      },
      {
        openingTime: resto.restaurant.opening_time,
        closingTime: resto.restaurant.closing_time,
        day: 'wednesday',
      },
      {
        openingTime: resto.restaurant.opening_time,
        closingTime: resto.restaurant.closing_time,
        day: 'thursday',
      },
      {
        openingTime: resto.restaurant.opening_time,
        closingTime: resto.restaurant.closing_time,
        day: 'friday',
      },
    ]

    return (
      <ScheduleContainer>
        <FlatList
          data={hours}
          renderItem={renderHours}
          ItemSeparatorComponent={() => <Separator />}
          // eslint-disable-next-line react/prop-types
          keyExtractor={item => item.day}
        />
      </ScheduleContainer>
    )
  }

  const { restaurant } = resto
  const open = isOpen(restaurant.opening_time, restaurant.closing_time)
  const { fluidity } = restaurant

  const renderInfos = () => {
    return (
      <Content>
        <Animation animation="fade_right">
          <Title>{i18n.t('screens.restaurant.detail.hours')}</Title>
          {!!fluidity && fluidity.label !== 'UNKNOWN' && fluidity.label !== 'ERROR' && renderOccupancy(fluidity)}
          {renderSchedule()}
          {/* !!restaurant.mapwizeId && (
            <GotoContainer>
              <RectangleButton
                label={i18n.t('actions.itinerary')}
                color="orange"
                size="tall"
                onPress={() =>
                  // eslint-disable-next-line react/prop-types
                  navigator.navigate('sitePlan', { destination: { name: restaurant.name, id: restaurant.mapwizeId } })
                }
              />
            </GotoContainer>
              ) */}
        </Animation>
      </Content>
    )
  }

  return (
    <React.Fragment>
      <Root navigator={navigator} renderContent={renderInfos}>
        <Animation animation="fade_right">
          <Content>
            <Title>{restaurant.name}</Title>
            <StatusContainer>
              <StatusCircle open={open} />
              <Status open={open}>{i18n.t(`screens.restaurant.${open ? 'open' : 'closed'}`)}</Status>
            </StatusContainer>

            {!!restaurant.description && <Description>{restaurant.description}</Description>}

            {renderMenu()}
          </Content>
        </Animation>
      </Root>
      <RefillContainer>
        <RestaurantHeaderRefill navigator={navigator} />
      </RefillContainer>
    </React.Fragment>
  )
}

export default RestaurantDetailScreen

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 14px;
`

const Content = styled(View)`
  padding: 60px 25px 25px;
`

const StatusContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`

const Status = styled(Text)<{ open: boolean }>`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => (props.open ? props.theme.colors.open : props.theme.colors.closed)};
  text-transform: uppercase;
`

const StatusCircle = styled(View)<{ open: boolean }>`
  background-color: ${props => (props.open ? props.theme.colors.open : props.theme.colors.closed)};
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 8px;
`

const Description = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  margin-top: 30px;
  margin-bottom: 15px;
`

const RefillContainer = styled(View)`
  position: absolute;
  top: -40px;
  right: -30px;
  height: 156px;
`

// SCHEDULE

const ScheduleContainer = styled(View)`
  padding: 4px 0px;
`

const DayContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
`

const Separator = styled(View)`
  height: 1px;
  background-color: ${props => props.theme.colors.disableItem};
`

const DayText = styled(Text)<{ closed?: boolean }>`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => (props.closed ? props.theme.colors.closed : props.theme.colors.primary)};
`

const ScheduleText = styled(Text)<{ closed?: boolean }>`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => (props.closed ? props.theme.colors.closed : props.theme.colors.primaryDark)};
`

//OCCUPANCY

const OccupancyContainer = styled(View)`
  margin: 44px 0px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

const OccupancyTextContainer = styled(View)`
  align-items: center;
  flex: 1;
`

const OccupancySubtitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.primary};
  margin-top: 15px;
`

const OccupancyStatus = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryText};
`

// MenuTabBar

const MenuTab = styled(View)`
  flex-direction: row;
  margin: 16px 0px 32px 0px;
`

const MenuCell = styled(TouchableOpacity)<{ width: number }>`
  width: ${props => props.width}px;
  padding-bottom: 8px;
  align-items: center;
`

const MenuText = styled(Text)<{ selected: boolean }>`
  ${props => (props.selected ? props.theme.fonts.buttonLabel : props.theme.fonts.genericBody3)};
  color: ${props => (props.selected ? props.theme.colors.primaryDark : props.theme.colors.secondaryText)};
`

const SubView = styled(View)<{ selected: boolean; width: number }>`
  margin-top: ${props => (props.selected ? 5 : 6)}px;
  height: ${props => (props.selected ? 3 : 1)}px;
  border-radius: ${props => (props.selected ? 1 : 0)}px;
  width: ${props => props.width}px;
  background-color: ${props => (props.selected ? props.theme.colors.primary : props.theme.colors.sublineBlue)};
`

// Details Menu

const Card = styled(View)<{ width: number }>`
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 16px;

  width: ${props => props.width}px;
  margin: 0px 24px;
  margin-bottom: 24px;
`

const RecipeName = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 16px;
`

const InfoText = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
`

const Allergens = styled(InfoText)`
  margin-bottom: 20px;
`

const InfoValueText = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.primaryDark};
  text-transform: uppercase;
  margin-top: 2px;
`

const NutrionnalValuesContainer = styled(View)`
  flex-direction: row;
`

const NutrionalValues = styled(View)`
  margin-right: 30px;
`

//GOTO

// const GotoContainer = styled(View)`
//   padding: 40px 40px 20px;
//   justify-content: center;
//   align-items: center;
// `
