import * as React from 'react'
import { View, Text, TouchableOpacity, FlatList, Animated, Easing, Platform, ScrollView } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'
import HomeNewsItem from './HomeNewsItem'

import { useStore } from 'effector-react'
import * as newsStore from '@store/news/news'
import useI18n from '@store/i18n/useI18n'
import api from '@api/api'

import { isAfter } from 'date-fns'

const TYPES = ['event', 'info', 'emergency']

const PADDING_MAIN_CONTAINER = 25

interface Props {
  navigator: Navigation
}

const HomeNews = ({ navigator }: Props) => {
  const anim = React.useRef(new Animated.Value(0))
  const inter = anim.current.interpolate({ inputRange: [0, 1], outputRange: [0, 45] })

  const { news } = useStore(newsStore.store)

  const [openFilter, setOpenFilter] = React.useState(false)
  const [activeFilters, setActiveFilters] = React.useState<string[]>(TYPES)
  const [scrollOffset, setScrollOffset] = React.useState(0)

  const i18n = useI18n()

  React.useEffect(() => {
    api.news
      .all()
      .then(res => newsStore.actions.setNews(res.results))
      .catch(() => newsStore.actions.setNews([]))
  }, [])

  React.useEffect(() => {
    Animated.timing(anim.current, {
      toValue: openFilter ? 1 : 0,
      duration: 100,
      easing: Easing.linear,
    }).start()
  }, [openFilter])

  const toggleFilter = (filter: string) => {
    if (activeFilters.indexOf(filter) >= 0) {
      setActiveFilters(activeFilters.filter(f => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const WebTransition = (news: Article) => {
    if (navigator) {
      navigator.pushPath(`newsDetail/${news.id}`)
    }
  }

  return (
    <MainContainer
      //Cette ligne permet de déclencher un calcul de la position Y des images à chaque scroll
      onScroll={event => setScrollOffset(event.nativeEvent.contentOffset.y)}
    >
      <Line>
        <Title>{i18n.t('screens.home.actu.title')}</Title>
        <TouchableOpacity activeOpacity={0.9} onPress={() => setOpenFilter(!openFilter)}>
          <Icon
            name="sliders"
            size={24}
            color={activeFilters.length === TYPES.length ? Theme.colors.disable : Theme.colors.primary}
          />
        </TouchableOpacity>
      </Line>
      <Filters style={{ height: inter, opacity: anim.current }}>
        {/* event */}
        <Filter activeOpacity={0.9} onPress={() => toggleFilter('event')}>
          <Tag type="event" inactive={activeFilters.indexOf('event') < 0}>
            <TagText type="event" inactive={activeFilters.indexOf('event') < 0}>
              {i18n.t(`screens.home.actu.type.event`)}
            </TagText>
          </Tag>
        </Filter>

        {/* info */}
        <Filter activeOpacity={0.9} onPress={() => toggleFilter('info')}>
          <Tag type="info" inactive={activeFilters.indexOf('info') < 0}>
            <TagText type="info" inactive={activeFilters.indexOf('info') < 0}>
              {i18n.t(`screens.home.actu.type.info`)}
            </TagText>
          </Tag>
        </Filter>
      </Filters>

      {/* news list */}
      {news.length > 0 ? (
        <FlatList
          data={news
            .filter(n => activeFilters.indexOf(n.type) >= 0)
            .sort((a, b) => (isAfter(new Date(a.publicationDate), new Date(b.publicationDate)) ? -1 : 1))}
          keyExtractor={item => item.id}
          renderItem={({ item }) => (
            <HomeNewsItem
              news={item}
              // en passant 'undefined' dans cette prop, on indique que l'on est sur mobile. Le traitement suit
              onItemPressed={Platform.OS === 'web' ? () => WebTransition(item) : undefined}
              i18n={i18n}
              scrollOffset={scrollOffset}
            />
          )}
          contentContainerStyle={{ paddingBottom: 40 }}
        />
      ) : (
        <NoNewsText>{i18n.t('screens.home.actu.noNews')}</NoNewsText>
      )}
    </MainContainer>
  )
}

export default HomeNews

const MainContainer = styled(ScrollView)`
  padding: 0px 20px;
  margin-bottom: ${PADDING_MAIN_CONTAINER}px;
`

const Line = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle2};
  color: ${props => props.theme.colors.primaryDark};
  flex: 1;
`

const Tag = styled(View)<{ type: string; inactive?: boolean }>`
  padding: 5px 15px;
  border-radius: 15px;
  background-color: ${props =>
    props.inactive
      ? props.theme.colors.greyMenu
      : props.type === 'event'
      ? props.theme.colors.primaryLight
      : props.theme.colors.tertiaryLight};
`

const TagText = styled(Text)<{ type: string; inactive?: boolean }>`
  ${props => props.theme.fonts.genericButton};
  color: ${props =>
    props.inactive
      ? props.theme.colors.primaryDark
      : props.type === 'event'
      ? props.theme.colors.primary
      : props.theme.colors.tertiary};
  font-weight: bold;
`

const Filters = styled(Animated.View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`

const Filter = styled(TouchableOpacity)`
  margin-right: 10px;
  margin-top: 20px;
`

const NoNewsText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  align-self: center;
  margin-top: 50px;
`
