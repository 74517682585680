export const formatAmount = (amount: number) => {
  return amount.toFixed(2).replace('.', ',')
}

export const formatAmountBalance = (amount: number) => {
  const abs = Math.abs(amount)

  return `${amount < 0 ? '-' : '+'}${abs.toFixed(2).replace('.', ',')}`
}

export const scale = (num: number, inputMin: number, inputMax: number, outputMin: number, outputMax: number) => {
  return ((num - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin
}
