import axios from 'axios'
import config from '@app/config'
import OAuth from '@api/auth/authApi'
import CredentialService from '@api/CredentialService'

import * as userStore from '@store/user/user'
import * as authStore from '@store/auth/auth'

import { i18nItem } from '@store/i18n/i18n'

const MAX_TIMEOUT = 15000

type CODE = 'network' | 'server' | 'client' | 'session'

class ApiError extends Error {
  constructor(message: string, code: CODE, data?: any) {
    super(message)
  }
}

const execute = <T>(
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  body?: any,
  needAuth = true,
  sodexoToken?: string
): Promise<T> => {
  const { username, credentials } = CredentialService.get()

  const tokenPromise =
    needAuth && username && credentials
      ? OAuth.refresh(username, credentials.refresh_token)
      : (Promise.resolve() as Promise<CredentialsOAuth2 | void>)
  return tokenPromise.then(res => {
    const headers: { [key: string]: string } = {
      'content-type': 'application/json',
      'Accept-Language': i18nItem.lang,
      'x-Api-Key': config.apiKey,
      Authorization: res ? `Bearer ${res.access_token}` : '',
      'X-Sodexo-Token': sodexoToken || '',
    }

    // cancel Token used for Timeout
    const cancelToken = axios.CancelToken.source()
    setTimeout(cancelToken.cancel, MAX_TIMEOUT)
    return axios
      .request<T>({
        cancelToken: cancelToken.token,
        url: config.SERVER_PREFIX + path,
        method: method || (body ? 'POST' : 'GET'),
        headers,
        data: body ? JSON.stringify(body) : undefined,
        timeout: MAX_TIMEOUT,
      })
      .then(res => {
        if (res.status === 401 || res.status === 403) {
          // Authentication or right eroor
          authStore.actions.resetAuth()
          userStore.actions.resetUser()
          authStore.actions.setTokenStatus('expired')
          throw new ApiError('Expired session ?', 'session', res.data)
        }
        if (res.status >= 400 && res.status < 500) {
          // Query Error
          throw new ApiError(
            'Unable to query ' + path + ' : ' + res.status + ' / ' + res.statusText,
            'client',
            res.data
          )
        }
        if (res.status !== 200) {
          // Technical error (Internal Server Error, ...)
          throw new ApiError(
            'Unable to query ' + path + ' : ' + res.status + ' / ' + res.statusText,
            'server',
            res.data
          )
        }

        return res.data
      })
      .catch(err => {
        const { response } = err
        if (response.status === 401 || response.status === 403) {
          authStore.actions.resetAuth()
          userStore.actions.resetUser()
          authStore.actions.setTokenStatus('expired')
          throw new ApiError('Expired session', 'session', response.data)
        }
        return err
      })
  })
}

export default execute
