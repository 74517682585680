import * as React from 'react'
import { Text, View, Image } from 'react-native'

import Root from '@components/root/Root'

import useI18n from '@store/i18n/useI18n'

import styled from '@theme/styled-components'

interface Props {
  navigator: Navigation
}

const WellBeingScreen = ({ navigator }: Props) => {
  const i18n = useI18n()
  return (
    <Root header headerTitle={i18n.t('drawer.titles.wellBeing')} navigator={navigator}>
      <MainContainer>
        <Paragraph>
          <BoldItalicText>{i18n.t('screens.wellBeing.1')}</BoldItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.2')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <Img source={require('@assets/images/conciergerie.png')} />
          <ItalicText>{i18n.t('screens.wellBeing.3')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <BoldItalicText>{i18n.t('screens.wellBeing.4')}</BoldItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.5')}</ItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.6')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <ItalicText>{i18n.t('screens.wellBeing.7')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <BoldItalicText>{i18n.t('screens.wellBeing.8')}</BoldItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.9')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <BoldItalicText>{i18n.t('screens.wellBeing.10')}</BoldItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.11')}</ItalicText>
        </Paragraph>
        <Paragraph>
          <BoldItalicText>{i18n.t('screens.wellBeing.12')}</BoldItalicText>
          <ItalicText>{i18n.t('screens.wellBeing.13')}</ItalicText>
        </Paragraph>
      </MainContainer>
    </Root>
  )
}
export default WellBeingScreen
const MainContainer = styled(View)`
  flex: 1;
`
const BoldItalicText = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.primaryText};
`
const ItalicText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryText};
`
const Paragraph = styled(View)`
  margin: 15px;
`
const Img = styled(Image)`
  height: 205px;
  width: 241px;
  margin: auto;
  margin-bottom: 15px;
`
