interface CredFromStore {
  username?: string
  credentials?: CredentialsOAuth2
  expirationDate?: Date
}

let credFromStore: CredFromStore = { username: undefined, credentials: undefined, expirationDate: undefined }

const service = {
  configure: (username?: string, credentials?: CredentialsOAuth2, expirationDate?: Date) => {
    credFromStore = { username, credentials, expirationDate }
  },
  get: (): CredFromStore => {
    return credFromStore
  },
  reset: () => {
    credFromStore = { username: undefined, credentials: undefined, expirationDate: undefined }
  },
}

export default service
