import * as React from 'react'
import { Platform, ScrollView, Text, View } from 'react-native'

import Animators from '@components/root/Animators'
import ResponsiveMainContainer from '@components/responsive/ResponsiveMainContainer'

import { formatAmountBalance } from '@utils/NumberUtils'

import styled from '@theme/styled-components'

import useI18n from '@store/i18n/useI18n'

interface Props {
  history: AmountHistory[]
}

const BadgeHistory = ({ history }: Props) => {
  // On n'affiche que les historiques 'débit' ou 'credit'
  const historyCreditOrDebit = history.filter(
    transaction => transaction.label === 'debit' || transaction.label === 'credit'
  )

  const i18n = useI18n()

  const renderHistoryItem = (item: AmountHistory) => {
    return (
      <Line>
        <ItemContainer>
          <ItemTitle numberOfLines={1}>{i18n.t(`screens.badge.${item.label}`)}</ItemTitle>
          <ItemDate>{i18n.t('screens.home.historyDate', { date: new Date(item.date) })}</ItemDate>
        </ItemContainer>
        <ItemAmount pos={item.amount > 0}>{`${formatAmountBalance(item.amount)}€`}</ItemAmount>
      </Line>
    )
  }

  if (Platform.OS !== 'web') {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <Animators animation="fade_bottom">{historyCreditOrDebit.map(renderHistoryItem)}</Animators>
      </ScrollView>
    )
  } else {
    return (
      <ResponsiveMainContainer scrollable>
        <Title>{i18n.t('components.card.history')}</Title>
        <LateralHistoryContainer>
          <Animators animation="fade_right">{historyCreditOrDebit.map(renderHistoryItem)}</Animators>
        </LateralHistoryContainer>
      </ResponsiveMainContainer>
    )
  }
}
export default BadgeHistory
const Line = styled(View)`
  align-items: center;
  flex-direction: row;

  padding: 20px 0px;
  border: 0px solid #3331;
  border-bottom-width: 1px;
`

const ItemContainer = styled(View)`
  flex: 1;
`

const ItemTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
`

const ItemDate = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => props.theme.colors.secondaryText};
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
`

const ItemAmount = styled(Text)<{ pos: boolean }>`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => (props.pos ? props.theme.colors.secondary : props.theme.colors.primary)};
`
const LateralHistoryContainer = styled(View)`
  margin: 30px 0px;
`
const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`
