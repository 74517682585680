import * as React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  BackHandler,
  Animated,
  Easing,
  Keyboard,
  Platform,
  SafeAreaView,
} from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'
import RootComponent from 'react-native-root-siblings'
import KeyboardAvoid from '@components/keyboard/KeyboardAvoid'

interface Props {
  i18n: I18nState
  title: string
  value: string
  onValidate: (value: string) => void
  withSpecial?: boolean
  onClose?: () => void
  apostropheAuthorized?: boolean
}

const Component = ({ i18n, title, value, onValidate, withSpecial, onClose, apostropheAuthorized }: Props) => {
  const anim = React.useRef(new Animated.Value(0))
  const InputRef = React.useRef<TextInput | null>(null)

  const [val, setVal] = React.useState(value)
  const [error, setError] = React.useState<string>()
  const [lock, setLock] = React.useState(true)

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
    onBlur()
  }, [])

  const close = () => {
    setLock(false)
    Keyboard.dismiss()
    if (InputRef.current) {
      InputRef.current.blur()
    }

    if (onClose) {
      onClose()
    }
    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      FieldInput.close
    )
  }

  const onBlur = React.useCallback(() => {
    if (InputRef.current && lock) {
      InputRef.current.focus()
    }
  }, [lock, InputRef])

  const setValue = (v: string) => {
    setVal(v)

    if (!withSpecial) {
      if (apostropheAuthorized ? v.match(/[^A-zÀ-ÿ- ']/g) : v.match(/[^A-zÀ-ÿ- ]/g)) {
        setError('errors.field.specialChar')
      } else {
        setError(undefined)
      }
    } else {
      setError(undefined)
    }
  }

  const submit = () => {
    if (!error) {
      onValidate(val)
      close()
    }
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <KeyboardAvoid>
        <SafeAreaView>
          <HeaderContainer>
            <BackButton activeOpacity={0.9} onPress={close}>
              <Icon name="back" size={24} color={Theme.colors.disable} />
            </BackButton>
            <Title>{title}</Title>
          </HeaderContainer>
        </SafeAreaView>

        <InputContainer>
          {!!error && (
            <WarningContainer>
              <Icon name="alert" color={Theme.colors.info} size={20} />
              <WarningText>{i18n.t(error)}</WarningText>
            </WarningContainer>
          )}

          <Input ref={InputRef} value={val} onChangeText={setValue} />
        </InputContainer>

        <Validate activeOpacity={0.8} onPress={submit}>
          <SafeAreaView>
            <ValidateText>{i18n.t('actions.validate')}</ValidateText>
          </SafeAreaView>
        </Validate>
      </KeyboardAvoid>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.background};
`

const HeaderContainer = styled(View)`
  padding: 30px 24px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const BackButton = styled(TouchableOpacity)`
  margin-bottom: 10px;
`

const InputContainer = styled(View)`
  flex: ${Platform.OS === 'web' ? 0 : 1};
  padding: ${Platform.OS === 'web' ? 135 : 20}px 20px;

  align-items: center;
  justify-content: center;
`

const Input = styled(TextInput)`
  ${props => props.theme.fonts.accountInput};
  color: ${props => props.theme.colors.primary};

  max-width: 400px;
  text-align: center;

  :focus {
    outline: none;
    border: transparent;
  }
`

const Validate = styled(TouchableOpacity)`
  align-items: center;
  padding: 15px;
  border-radius: ${Platform.OS === 'web' ? 12 : 0}px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: ${props => props.theme.colors.primary};

  margin: 0px ${Platform.OS === 'web' ? 100 : 0}px;
`

const ValidateText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: #fff;
  font-weight: bold;
`

const WarningContainer = styled(View)`
  position: absolute;
  top: 0;
  left: 25;

  flex-direction: row;
  align-items: center;
`

const WarningText = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.info};
  font-weight: bold;
  margin: 0px 15px;
`

let root: RootComponent
let rootActive = false

const FieldInput = {
  open: (conf: Props) => {
    if (!rootActive) {
      rootActive = true
      root = new RootComponent(<Component {...conf} />)

      BackHandler.addEventListener('hardwareBackPress', FieldInput.onRequestClose)
    }
  },

  close: () => {
    if (rootActive && root) {
      root.destroy()
      rootActive = false
    }
  },

  onRequestClose: () => {
    BackHandler.removeEventListener('hardwareBackPress', FieldInput.onRequestClose)
    FieldInput.close()

    return true
  },
}

export { Component }
export default FieldInput
