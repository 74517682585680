import analytics from '@react-native-firebase/analytics'
import { Platform } from 'react-native'

export default {
  onScreen: (name: string, clss: string) => {
    if (Platform.OS !== 'web') {
      // eslint-disable-next-line @typescript-eslint/camelcase
      analytics().logScreenView({ screen_name: name, screen_class: clss })
    }
  },
}
