import * as React from 'react'
import { View, Animated, Easing, Text, TouchableOpacity, ScrollView } from 'react-native'
import styled from '@theme/styled-components'

import Root from 'react-native-root-siblings'

import useI18n from '@store/i18n/useI18n'

export interface Option {
  label: string
  value: any
}

interface Props {
  title: string
  text?: string
  data: Option[]
  onItemSelected: (opt: Option) => void
}

const Component = ({ title, text, data, onItemSelected }: Props) => {
  const anim = React.useMemo(() => new Animated.Value(0), [])
  const i18n = useI18n()

  React.useEffect(() => {
    Animated.timing(anim, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Animated.timing(anim, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      Alert.close
    )
  }

  return (
    <MainContainer style={{ opacity: anim }}>
      <Back activeOpacity={1} onPress={close} />

      <PopupContainer style={{ opacity: anim }}>
        <Content>
          <Title>{title}</Title>
          {!!text && <Description>{text}</Description>}
          <ScrollView>
            {data.map(d => (
              <Button
                key={d.label}
                activeOpacity={0.9}
                onPress={() => {
                  close()
                  onItemSelected(d)
                }}
              >
                <ButtonText>{d.label}</ButtonText>
              </Button>
            ))}
          </ScrollView>
        </Content>

        <Cancel activeOpacity={0.9} onPress={close}>
          <CancelText>{i18n.t('common.cancel')}</CancelText>
        </Cancel>
      </PopupContainer>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
`

const Back = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.darkenedBackground};
`

// COMPONENT

const PopupContainer = styled(Animated.View)`
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px;
`

const Content = styled(View)`
  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 13px;
  margin-bottom: 10px;
  max-height: 400px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryText};
  margin: 15px 20px;
  align-self: center;
`

const Description = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  margin: 0px 50px;
  margin-bottom: 20px;
  align-self: center;
  text-align: center;
`

const Button = styled(TouchableOpacity)`
  padding: 15px 10px;
  align-items: center;
  border: 0px solid ${props => props.theme.colors.background};
  border-top-width: 1px;
`

const ButtonText = styled(Text)`
  ${props => props.theme.fonts.genericTitle4};
  color: ${props => props.theme.colors.primary};
`

const Cancel = styled(TouchableOpacity)`
  background-color: ${props => props.theme.colors.counterPrimary};
  border-radius: 13px;
  padding: 15px 10px;
  align-items: center;
`

const CancelText = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.primary};
`

let root: Root
let active = false

const Alert = {
  open: (conf: Props) => {
    if (!active) {
      root = new Root(<Component {...conf} />)
      active = true
    }
  },
  close: () => {
    if (root) {
      root.destroy()
      active = false
    }
  },
}

export default Alert
