import { Platform } from 'react-native'

const Fonts = {
  beVietnamRegular: 'BeVietnam-Regular',
  beVietnamBold: 'BeVietnam-Bold',
  interRegular: 'Inter-Regular',
  interBold: 'Inter-Bold',
}

const emptyTheme: Theme = {
  colors: {},
  fonts: {},
  constants: {},
}

const theme: Theme =
  Platform.OS !== 'web'
    ? emptyTheme
    : {
        colors: {},
        fonts: {
          genericTitle3: {
            fontSize: 15,
            lineHeight: 24,
            fontFamily: Fonts.beVietnamBold,
            fontWeight: 'bold',
            letterSpacing: -0.5,
            color: '#C4C4C4',
          },
        },
        constants: {
          shadow: {
            'box-shadow': '5px 5px 15px rgba(0,0,0,0.2)',
          },
        },
      }

export default theme
