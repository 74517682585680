import * as React from 'react'
import { View, Animated, Easing, TouchableOpacity, useWindowDimensions } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'
import { Moment } from 'moment'

import Root from 'react-native-root-siblings'

import DatePicker from 'react-native-calendar-picker'

const MONTHS_ABREV = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
const WEEK_ABREV = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

interface Props {
  defaultSelected?: string | Date
  selectedDate?: string | Date
  onSelect: (d: string | Date) => void

  minDate?: string | Date
  maxDate?: string | Date
  excludedDates?: (string | Date)[]
}

const CalendarDatePicker = ({
  defaultSelected = new Date(),
  selectedDate,
  onSelect,
  minDate,
  maxDate,
  excludedDates = [],
}: Props) => {
  const i18n = useI18n()
  const anim = React.useMemo(() => new Animated.Value(0), [])
  const WIDTH = useWindowDimensions().width

  React.useEffect(() => {
    Animated.timing(anim, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Animated.timing(anim, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      Alert.close
    )
  }

  const onDateSelected = (date: Moment) => {
    onSelect(date.format())
    close()
  }

  return (
    <MainContainer style={{ opacity: anim }}>
      <Back activeOpacity={1} onAccessibilityAction={close} onPress={close} />

      <CalendarContainer>
        <DatePicker
          onDateChange={onDateSelected}
          disabledDates={[...excludedDates]}
          minDate={minDate}
          maxDate={maxDate}
          selectedStartDate={selectedDate || defaultSelected}
          selectedDayColor={Theme.colors.primary}
          selectedDayTextColor={Theme.colors.counterPrimary}
          previousTitle={i18n.t('components.datePicker.previous')}
          nextTitle={i18n.t('components.datePicker.next')}
          months={MONTHS_ABREV.map(m => i18n.t(`components.datePicker.months.${m}`))}
          weekdays={WEEK_ABREV.map(w => i18n.t(`components.datePicker.weekdays.${w}`))}
          todayBackgroundColor="white"
          width={WIDTH - 50}
          restrictMonthNavigation
          scrollable
        />
      </CalendarContainer>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
`
const Back = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.darkenedBackground};
`
const CalendarContainer = styled(View)`
  background-color: ${props => props.theme.colors.counterPrimary};
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
`

let root: Root
let active = false

const Alert = {
  open: (conf: Props) => {
    if (!active) {
      root = new Root(<CalendarDatePicker {...conf} />)
      active = true
    }
  },
  close: () => {
    if (root) {
      root.destroy()
      active = false
    }
  },
}

export default Alert
