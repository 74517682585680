import oAuth2 from '@api/OAuth'
import config from '@app/config'

import { isAfter } from 'date-fns'

import * as authStore from '@store/auth/auth'
import * as userStore from '@store/user/user'

import CredentialService from '@api/CredentialService'

const api = {
  login: (email: string, password: string): Promise<CredentialsOAuth2> => {
    const bodyOAuth2 = new FormData()
    bodyOAuth2.append('grant_type', 'password')
    bodyOAuth2.append('username', email)
    bodyOAuth2.append('password', password)
    return oAuth2<CredentialsOAuth2>(`${config.ws.auth.login}`, 'POST', bodyOAuth2)
      .then(res => {
        authStore.actions.setAuth({ credentials: res, username: email })
        authStore.actions.setTokenStatus('ok')
        authStore.actions.setLogged(true)
        CredentialService.configure(email, res)
        return res
      })
      .catch(err => {
        authStore.actions.resetAuth()
        CredentialService.reset()
        throw err
      })
  },
  refresh: (email: string, refreshToken: string): Promise<CredentialsOAuth2> => {
    const { credentials, username, expirationDate } = CredentialService.get()

    if (!credentials || !username) {
      authStore.actions.resetAuth()
      userStore.actions.resetUser()
      CredentialService.reset()
      return Promise.reject('need authentication')
    }
    if (credentials && expirationDate && isAfter(expirationDate, new Date())) {
      return Promise.resolve(credentials)
    }

    const bodyOAuth2 = new FormData()
    bodyOAuth2.append('grant_type', 'refresh_token')
    bodyOAuth2.append('username', email)
    bodyOAuth2.append('refresh_token', refreshToken)
    return oAuth2<CredentialsOAuth2>(`${config.ws.auth.refresh}`, 'POST', bodyOAuth2)
      .then(res => {
        authStore.actions.setAuth({ credentials: res, username: email })
        authStore.actions.setTokenStatus('ok')
        CredentialService.configure(email, res)
        return res
      })
      .catch(err => {
        authStore.actions.resetAuth()
        authStore.actions.setTokenStatus('expired')
        userStore.actions.resetUser()
        CredentialService.reset()
        throw err
      })
  },
}

export default api
