import axios from 'axios'
import config from '@app/config'

const MAX_TIMEOUT = 15000

type CODE = 'network' | 'server' | 'client' | 'session'

class ApiError extends Error {
  constructor(message: string, code: CODE) {
    super(message)
  }
}

const execute = <T>(path: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', bodyOAuth2?: any): Promise<T> => {
  const headers: { [key: string]: string } = {
    'content-type': 'multipart/form-data',
    'x-Api-Key': config.apiKey,
    Authorization: 'Basic Y29ldXJsaXZlOg==',
  }

  // cancel Token used for Timeout
  const cancelToken = axios.CancelToken.source()
  setTimeout(cancelToken.cancel, MAX_TIMEOUT)

  return axios
    .request<T>({
      cancelToken: cancelToken.token,
      url: config.SERVER_PREFIX + path,
      method: method || (bodyOAuth2 ? 'POST' : 'GET'),
      headers,
      data: bodyOAuth2 ? bodyOAuth2 : undefined,
      timeout: MAX_TIMEOUT,
    })
    .then(res => {
      if (res.status !== 200) {
        // Technical error (Internal Server Error, ...)
        throw new ApiError('Unable to query ' + path + ' : ' + res.status + ' / ' + res.statusText, 'server')
      }

      return res.data
    })
    .catch(err => {
      const res = err.response.data

      if (res.status === 401 || res.status === 403) {
        // Authentication or right eroor
        throw new ApiError(res.message ? res.message : 'Expired session ?', 'session')
      }
      if (res.status >= 400 && res.status < 500) {
        // Query Error
        throw new ApiError('Unable to query ' + path + ' : ' + res.status + ' / ' + res.statusText, 'client')
      }
      if (res.status !== 200) {
        // Technical error (Internal Server Error, ...)
        throw new ApiError('Unable to query ' + path + ' : ' + res.status + ' / ' + res.statusText, 'server')
      }
      // Network error (no internet, server down, ...)
      throw new ApiError(err.message, 'network')
    })
}

export default execute
