import news from './news/newsApi'
import resto from './resto/restoApi'
import account from './account/accountApi'
import user from './user/userApi'
import edito from './edito/editoApi'
import auth from './auth/authApi'
import booking from './booking/bookingApi'
import map from './visioglobe/api'

// fill with apis
const apis = {
  news,
  resto,
  account,
  user,
  edito,
  auth,
  booking,
  map,
}

export default apis
