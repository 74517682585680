import * as React from 'react'
import { Animated, Easing, Image, Platform, TouchableOpacity, ImageSourcePropType } from 'react-native'

import ImageModal from '@components/image/ImageModal'

import styled from '@theme/styled-components'

interface Props {
  pictures: string[]
  width: number
  height: number
}

const PICTURE_DELAY = 5000
export const OFFSET = 20

const Carousel = ({ pictures, width, height }: Props) => {
  const anim = React.useRef(new Animated.Value(0))
  const [current, setCurrent] = React.useState(0)

  const animate = () => {
    Animated.timing(anim.current, { toValue: 1, duration: 1000, easing: Easing.linear }).start(() => {
      setTimeout(() => {
        resetAnimation()
        setCurrent(prev => prev + 1)
      }, 1000)
    })
  }

  const resetAnimation = () => {
    anim.current.setValue(0)
  }

  React.useEffect(() => {
    setTimeout(() => animate(), PICTURE_DELAY)
  }, [current])

  const translate = anim.current.interpolate({ inputRange: [0, 1], outputRange: [0, -OFFSET] })

  const currentPictureURI: ImageSourcePropType = { uri: pictures[current % pictures.length] }

  // does not require carousel
  if (pictures.length < 2) {
    return (
      <ModalTouchable height={height} width={width} onPress={() => ImageModal.open({ source: currentPictureURI })}>
        <CurrentImage source={{ uri: pictures[0] }} height={height} width={width} />
      </ModalTouchable>
    )
  }

  return (
    <ModalTouchable
      height={height}
      width={width}
      onPress={() => ImageModal.open({ source: currentPictureURI })}
      activeOpacity={1}
    >
      <CurrentImage
        height={height}
        width={width + 2 * OFFSET}
        style={{ transform: [{ translateX: -OFFSET }] }}
        source={{ uri: pictures[current % pictures.length] }}
        resizeMode="cover"
      />
      <AnimatedImage
        source={{ uri: pictures[(current + 1) % pictures.length] }}
        style={{ transform: [{ translateX: translate }], opacity: anim.current, height, width: width + 2 * OFFSET }}
        resizeMode="cover"
      />
    </ModalTouchable>
  )
}

export default Carousel

const CurrentImage = styled(Image)`
  height: ${props => props.height}px;
  width: ${props => props.width}px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: ${Platform.OS === 'web' ? 5 : 0}px;
`

const AnimatedImage = styled(Animated.Image)`
  border-radius: ${Platform.OS === 'web' ? 5 : 0}px;
`

const ModalTouchable = styled(TouchableOpacity)<{ height: number; width: number }>`
  background-color: ${props => props.theme.colors.background};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`
