import config from '@app/config'
import ws from '@api/webservice'

const api = {
  getUsersBookings: (): Promise<BookingList> => {
    return ws(`${config.ws.booking.userBookings}`, 'GET')
  },
  createBooking: (bookingCreateRequest: BookingCreate): Promise<BookingItem> => {
    return ws(`${config.ws.booking.create}`, 'POST', bookingCreateRequest)
  },
  cancelBooking: (idBooking: string): Promise<BookingItem> => {
    return ws(`${config.ws.booking.cancel.replace('{idBooking}', idBooking)}`, 'POST')
  },
  getCalendar: (idProfessional: string): Promise<CalendarList> => {
    return ws(`${config.ws.booking.getCalendar.replace('{idProfessional}', idProfessional)}`, 'GET')
  },
  getAllPros: (): Promise<ProfessionalList> => {
    return ws(`${config.ws.booking.getAllPros}`, 'GET')
  },
}

export default api
