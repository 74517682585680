import * as React from 'react'
import { View, Animated, Easing } from 'react-native'

import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

interface Props {
  value: number /* between 0 and 1 */
  color?: string
  trackColor?: string
}

const ProgressBar = ({ value, color = Theme.colors.primary, trackColor = Theme.colors.primaryLight }: Props) => {
  const anim = React.useMemo(() => new Animated.Value(0), [])
  const [width, setWidth] = React.useState(0)

  React.useEffect(() => {
    if (width) {
      Animated.timing(anim, { toValue: value, duration: 300, easing: Easing.linear }).start()
    }
  }, [value, width, anim])

  const progressWidth = anim.interpolate({ inputRange: [0, 1], outputRange: [0, width] })

  return (
    <Container onLayout={evt => setWidth(evt.nativeEvent.layout.width)}>
      <Track color={trackColor} style={{ width }} />
      <Progress color={color} style={{ width: progressWidth }} />
    </Container>
  )
}

const Container = styled(View)`
  justify-content: center;
`

const Track = styled(View)<{ color: string }>`
  height: 1px;
  border-radius: 1px;
  background-color: ${props => props.color};

  position: absolute;
`

const Progress = styled(Animated.View)<{ color: string }>`
  height: 3px;
  border-radius: 2px;
  background-color: ${props => props.color};
`

export default ProgressBar
