import { createEvent, createStore } from 'effector'

export const actions = {
  setInfos: createEvent<EditorialSimple[]>('setInfos'),
}

const initialState: InfoState = {
  infos: [],
}

export const store = createStore<InfoState>(initialState).on(actions.setInfos, (s, infos) => ({ ...s, infos }))
