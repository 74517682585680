import * as React from 'react'
import { View, Text, TouchableOpacity, Platform, Animated, Dimensions } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Svg, { Defs, LinearGradient, Stop, Rect } from 'react-native-svg'

import Icon from '@components/icons/Icons'
import ReloadScreen from '@screens/reload/ReloadScreen'

import useI18n from '@store/i18n/useI18n'

const SMALL_SCREEN = Dimensions.get('window').height < Theme.constants.smallScreenBreakpoint

interface Props {
  amount: string
  available: boolean
  addBadge?: () => void
  customAction?: () => void
  hideAddButton?: boolean
}

const MIN_CARD_SIZE = SMALL_SCREEN ? 100 : 170

const CardBack = ({ available }: { available: boolean }) => {
  const stopColors = available ? ['#3D61E0', '#183FC9'] : ['#EDEEF1', '#EDEEF1']
  const rectColor = available ? 'rgba(255, 255, 255, 0.08)' : '#DEDEDE'

  return (
    <Svg height="100%" width="100%">
      <Defs>
        <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="100%">
          <Stop offset="0" stopColor={stopColors[0]} stopOpacity="1" />
          <Stop offset="1" stopColor={stopColors[1]} stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <Rect rx="12" ry="12" x="0" y="0" height="100%" width="100%" fill="url(#grad)" />
      <Rect rx="10%" ry="10%" x="70%" y="-10%" height="50%" width="10%" fill={rectColor} />
      <Rect rx="10%" ry="10%" x="80%" y="60%" height="50%" width="10%" fill={rectColor} />
    </Svg>
  )
}

const Card = ({ amount, available, addBadge, customAction, hideAddButton }: Props) => {
  const i18n = useI18n()
  const [viewHeight, setViewHeight] = React.useState(0)
  const [isCardSmall, setIsCardSmall] = React.useState(false)

  const refillButtonAnim = React.useMemo(() => new Animated.Value(1), [])

  const cardHeight = Math.max(MIN_CARD_SIZE, viewHeight)

  React.useEffect(() => {
    if (!hideAddButton) {
      Animated.timing(refillButtonAnim, { toValue: 1, duration: 250 }).start()
    } else {
      Animated.timing(refillButtonAnim, { toValue: 0, duration: 250 }).start()
    }
  }, [hideAddButton])

  const onRefillClick = () => {
    if (addBadge && !available) {
      addBadge()
    } else if (customAction) {
      customAction()
    } else {
      ReloadScreen.open({ amount })
    }
  }

  return (
    <View onLayout={event => setIsCardSmall(event.nativeEvent.layout.width < 220)}>
      <BackContainer>
        <CardBack available={available} />
      </BackContainer>

      {available ? (
        <Container
          style={{ elevation: 8 }}
          minHeight={cardHeight}
          onLayout={event => {
            if (event.nativeEvent.layout.height > viewHeight) {
              setViewHeight(event.nativeEvent.layout.height)
            }
          }}
        >
          <AmountText>{i18n.t('components.card.amount')}</AmountText>
          <Amount isCardSmall={isCardSmall}>
            {amount}
            <Unit> €</Unit>
          </Amount>
        </Container>
      ) : (
        <UnavailableContainer
          style={{ elevation: 8 }}
          minHeight={cardHeight}
          onLayout={event => {
            if (event.nativeEvent.layout.height > viewHeight) {
              setViewHeight(event.nativeEvent.layout.height)
            }
          }}
        >
          <UnavailableTitle>{i18n.t('components.card.unavailable.title')}</UnavailableTitle>
          <UnavailableText>{i18n.t('components.card.unavailable.message')}</UnavailableText>
        </UnavailableContainer>
      )}
      <Touchable disabled={hideAddButton} activeOpacity={0.9} style={{ elevation: 8 }} onPress={onRefillClick}>
        <ButtonContainer style={{ opacity: refillButtonAnim }}>
          <Button available={available}>
            <Icon name="plus" size={24} color="#fff" />
          </Button>
          <ButtonText available={available}>{i18n.t(`actions.${available ? 'reload' : 'addBadge'}`)}</ButtonText>
        </ButtonContainer>
      </Touchable>
    </View>
  )
}

export default Card

const BackContainer = styled(View)`
  position: absolute;
  top: 0;
  bottom: 50px;
  left: 0;
  right: 0;

  background-color: ${props => props.theme.colors.primary};
  border-radius: 13px;

  ${props => props.theme.constants.shadow};
`

const Container = styled(View)<{ minHeight: number }>`
  padding: 25px;
  min-height: ${props => props.minHeight}px;
`

const AmountText = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  color: #fff8;
  text-transform: uppercase;
  margin-top: 10px;
`

const Amount = styled(Text)<{ isCardSmall: boolean }>`
  ${props => (props.isCardSmall && Platform.OS === 'web' ? props.theme.fonts.amountUnit : props.theme.fonts.amount)};
  ${SMALL_SCREEN && 'font-size: 40px; line-height: 40px;'};
  color: #fff;
`

const Unit = styled(Text)`
  ${props => props.theme.fonts.amountUnit}
`

const Touchable = styled(TouchableOpacity)`
  align-items: center;
  align-self: center;
`

const ButtonContainer = styled(Animated.View)`
  align-items: center;
`

const Button = styled(View)<{ available: boolean }>`
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: ${props => (props.available ? props.theme.colors.secondary : props.theme.colors.primary)};

  align-items: center;
  justify-content: center;
`

const ButtonText = styled(Text)<{ available: boolean }>`
  ${props => props.theme.fonts.genericButton};
  color: ${props => (props.available ? props.theme.colors.secondary : props.theme.colors.primary)};
  font-weight: bold;
  margin-top: 10px;
`

const UnavailableContainer = styled(View)<{ minHeight: number }>`
  padding: ${Platform.OS === 'web' ? '60px 30px 40px' : '20px 30px 10px'};
  justify-content: center;
  align-items: center;
  min-height: ${props => props.minHeight}px;
`

const UnavailableTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.secondaryText};
  margin-bottom: 10px;
  text-align: center;
`

const UnavailableText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  text-align: center;
`
