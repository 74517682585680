import * as React from 'react'
import { Keyboard, Platform, View, ViewProps } from 'react-native'

interface Props extends ViewProps {
  children: any
}

const KeyboardAvoid = (props: Props) => {
  const [bottom, setBottom] = React.useState(0)

  React.useEffect(() => {
    if (Platform.OS === 'ios') {
      const appearlistener = Keyboard.addListener('keyboardWillShow', evt => {
        setBottom(evt.endCoordinates.height)
      })

      const disappearlistener = Keyboard.addListener('keyboardWillHide', evt => {
        setBottom(0)
      })

      return () => {
        appearlistener.remove()
        disappearlistener.remove()
      }
    }
  }, [])

  return <View {...props} style={[props.style, { paddingBottom: bottom, flex: 1 }]} />
}

export default KeyboardAvoid
