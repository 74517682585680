import HomeScreen from '@screens/home/HomeScreen'
import AccountScreen from '@screens/account/AccountScreen'
import InfoScreen from '@screens/info/InfoScreen'
import MapScreen from '@screens/map/visioglobe/MapScreen'
import RestaurantScreen from '@screens/restauration/RestaurantScreen'
import RestaurantDetailScreen from '@screens/restauration/RestaurantDetailScreen'
import DomoticScreen from '@app/ui/screens/domotic/DomoticScreen'
import DomoticDetailScreen from '@app/ui/screens/domotic/DomoticDetailScreen'
import BookingRoomScreen from '@screens/bookingRoom/BookingRoomScreen'
import BadgeScreen from '@screens/badge/BadgeScreen'
import TodoScreen from '@screens/todo/TodoScreen'
import WellBeingScreen from '@screens/wellBeing/WellBeingScreen'
import PrivacyScreen from '@screens/account/PrivacyScreen'
import BookingScreen from '@app/ui/screens/booking/BookingScreen'
import BookingProfessional from '@screens/booking/BookingProfessional'
import BookingDetails from '@screens/booking/BookingDetails'
import MyBookings from '@screens/booking/MyBookings'

import ScreenWeb from './ScreensWeb'

const screens: { [key: string]: ScreenDefinition } = {
  home: {
    name: 'home',
    icon: 'home',
    screen: HomeScreen as unknown as RootScreen,
    path: '/',
    drawer: false,
  },
  bookingRoom: {
    name: 'bookingRoom',
    icon: 'room',
    screen: BookingRoomScreen as unknown as RootScreen,
    path: '/bookingRoom',
    drawer: true,
  },
  incident: {
    name: 'incident',
    icon: 'alert',
    screen: TodoScreen as unknown as RootScreen,
    path: '/incident',
    drawer: true,
  },
  account: {
    name: 'account',
    icon: 'user',
    screen: AccountScreen as unknown as RootScreen,
    path: '/account',
    drawer: true,
  },
  parameters: {
    name: 'parameters',
    icon: 'settings',
    screen: TodoScreen as unknown as RootScreen,
    path: '/parameters',
    drawer: true,
  },
  privacy: {
    name: 'privacy',
    icon: 'lock',
    screen: PrivacyScreen as unknown as RootScreen,
    path: '/privacy',
    drawer: true,
  },
  sitePlan: {
    name: 'sitePlan',
    icon: 'map',
    screen: MapScreen as unknown as RootScreen,
    path: '/sitePlan',
    drawer: true,
    tab: ['sitePlan'],
  },
  restaurant: {
    name: 'restaurant',
    icon: 'restaurant',
    screen: RestaurantScreen as unknown as RootScreen,
    path: '/restaurant',
    drawer: true,
    tab: ['restaurant'],
  },
  restaurantDetail: {
    name: 'restaurantDetail',
    icon: 'restaurant',
    screen: RestaurantDetailScreen as unknown as RootScreen,
    path: '/restaurant/:restoId',
    drawer: false,
    tab: ['restaurant'],
  },
  info: {
    name: 'info',
    icon: 'infos',
    screen: InfoScreen as unknown as RootScreen,
    path: '/info',
    drawer: true,
  },
  domotic: {
    name: 'domotic',
    icon: 'domotic',
    screen: DomoticScreen as unknown as RootScreen,
    path: '/domotic',
    drawer: true,
    platform: 'native',
  },
  domoticDetail: {
    name: 'domoticDetail',
    icon: 'domotic',
    screen: DomoticDetailScreen as unknown as RootScreen,
    path: '/domoticDetail',
    drawer: false,
    platform: 'native',
  },
  badge: {
    name: 'badge',
    icon: 'home',
    screen: BadgeScreen as unknown as RootScreen,
    path: '/badge',
    drawer: false,
    tab: ['home', 'restaurant'],
  },
  wellBeing: {
    name: 'wellBeing',
    icon: 'close',
    screen: WellBeingScreen as unknown as RootScreen,
    path: '/wellBeing',
    drawer: true,
  },
  booking: {
    name: 'booking',
    icon: 'wellBeing',
    screen: BookingScreen as unknown as RootScreen,
    path: '/booking',
    drawer: true,
  },
  bookingPro: {
    name: 'bookingPro',
    icon: 'wellBeing',
    screen: BookingProfessional as unknown as RootScreen,
    path: '/bookingPro',
    drawer: true,
  },
  bookingDetails: {
    name: 'bookingDetails',
    icon: 'wellBeing',
    screen: BookingDetails as unknown as RootScreen,
    path: '/bookingDetails',
    drawer: true,
  },
  myBookings: {
    name: 'myBookings',
    icon: 'wellBeing',
    screen: MyBookings as unknown as RootScreen,
    path: '/myBookings',
    drawer: true,
  },

  ...ScreenWeb,
}

export default screens
