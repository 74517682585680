import { isWithinInterval } from 'date-fns'

// Format de date attendu: "HH:mm:ss"
const dateStringToNumber = (date: string) =>
  3600 * Number(date.slice(0, 2)) + 60 * Number(date.slice(3, 5)) + Number(date.slice(6))

const removeOneHour = (date: number) => date - 3600

const dateNumberToString = (date: number) => {
  const hours = Math.floor(date / 3600)
  const minutes = Math.floor((date - 3600 * hours) / 60)
  const seconds = date - 3600 * hours - 60 * minutes
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`
}

export const isOpen = (openingTime: string, closingTime: string) => {
  const date = new Date().toISOString().split('T')[0]

  const frenchOpeningTime = dateNumberToString(removeOneHour(dateStringToNumber(openingTime)))
  const frenchClosingTime = dateNumberToString(removeOneHour(dateStringToNumber(closingTime)))

  const opening = new Date(`${date}T${frenchOpeningTime}`)
  const closing = new Date(`${date}T${frenchClosingTime}`)

  return isWithinInterval(new Date(), {
    start: opening,
    end: closing,
  })
}

export const TAGS = [
  { id: 'streetFood', emoji: '🌭' },
  { id: 'clickNcollect', emoji: '👆' },
  { id: 'takeAway', emoji: '🥡' },
  { id: 'asian', emoji: '🍱' },
  { id: 'burger', emoji: '🍔' },
  { id: 'veggie', emoji: '🥦' },
  { id: 'snackBar', emoji: '🍫' },
  { id: 'cafe', emoji: '☕' },
  { id: 'pastries', emoji: '🍰' },
  { id: 'frenchFood', emoji: '🇫🇷' },
  { id: 'thematicStands', emoji: '🧑‍🍳' },
  { id: 'saladBar', emoji: '🥗' },
  { id: 'bbq', emoji: '🥓' },
  { id: 'italianFood', emoji: '🇮🇹' },
  { id: 'tableService', emoji: '🍽️' },
  { id: 'pizzaNpasta', emoji: '🍕' },
  { id: 'dolci', emoji: '🧁' },
  { id: 'authentic', emoji: '😊' },
  { id: 'rotisserie', emoji: '🍖' },
]
