import * as React from 'react'
import { ScrollView, Platform, ActivityIndicator } from 'react-native'

import apis from '@api/api'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as termsOfService from '@store/termsOfService/termsOfService'

import styled from '@theme/styled-components'

import Root from '@components/root/Root'
import HTML from '@components/html/HTMLContent'

import Logger from '@utils/logger'
import Theme from '@theme/Theme'

interface Props {
  navigator: Navigation
}

const PrivacyScreen = ({ navigator }: Props) => {
  const i18n = useI18n()
  const privacyContent = useReducer(termsOfService.store, s => s.termsOfService)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (!privacyContent) {
      setLoading(true)
      apis.user
        .cgu()
        .then(res => {
          const cgu = res.find(cgu => cgu.language === i18n.lang)
          !!cgu && termsOfService.actions.setTermsOfService(cgu.content)
          setLoading(false)
        })
        .catch(err => {
          Logger.error(err)
          termsOfService.actions.setTermsOfService(i18n.t('screens.privacy.error'))
          setLoading(false)
        })
    }
  }, [privacyContent])
  if (loading) {
    return (
      <Root navigator={navigator} centered header={Platform.OS !== 'web'} headerTitle={i18n.t('drawer.titles.privacy')}>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }
  return (
    <Root header headerTitle={i18n.t('drawer.titles.privacy')} navigator={navigator}>
      <MainContainer>
        <HTML html={privacyContent} />
      </MainContainer>
    </Root>
  )
}
export default PrivacyScreen
const MainContainer = styled(ScrollView)`
  margin: 0px 5px;
`
