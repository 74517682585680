import NewsDetail from '@screens/home/NewsDetail.web'

const screens: { [key: string]: ScreenDefinition } = {
  newsDetail: {
    name: 'newsDetail',
    icon: 'home',
    screen: NewsDetail as unknown as RootScreen,
    path: '/newsDetail/:newsId',
    drawer: false,
  },
}

export default screens
