import config from '@app/config'
import ws from '@api/webservice'

const api = {
  buildings: (): Promise<Buildings> => {
    return ws(`${config.ws.account.buildings}`, 'GET')
  },
  floors: (): Promise<Floors> => {
    return ws(`${config.ws.account.floors}`, 'GET')
  },
  get: (): Promise<UserInfo> => {
    return ws(`${config.ws.account.get}`, 'GET')
  },
  update: (body: UserUpdate): Promise<void> => {
    return ws(`${config.ws.account.update}`, 'PUT', body)
  },
  delete: (): Promise<void> => {
    return ws(`${config.ws.account.delete}`, 'DELETE')
  },
}

export default api
