import * as React from 'react'
import { View, TouchableOpacity, FlatList, ScrollView } from 'react-native'
import styled from '@theme/styled-components'
import { useLocation } from 'react-router-dom'

import Icon from '@components/icons/Icons'
import CircleIcon from '@components/icons/CircleIcon'
import Alert from '@components/alert/Alert'

import useI18n from '@store/i18n/useI18n'
import * as userStore from '@store/user/user'
import * as authStore from '@store/auth/auth'

import theme from '@theme/Theme'

interface Props {
  navigator: Navigation
}

const userShortcuts: DrawerItem[] = [
  { name: 'account', icon: 'user', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  // { name: 'parameters', icon: 'settings', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'privacy', icon: 'lock', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
]

const features: DrawerItem[] = [
  { name: 'home', icon: 'home', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'restaurant', icon: 'restaurant', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'bookingRoom', icon: 'room', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'sitePlan', icon: 'map', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  // { name: 'incident', icon: 'alert', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  { name: 'info', icon: 'infos', iconColor: theme.colors.disable, bgColor: theme.colors.greyMenu },
  {
    name: 'boxNServices',
    icon: 'box-n-services',
    iconColor: theme.colors.disable,
    bgColor: theme.colors.greyMenu,
    link: 'https://portail.boxnservices.fr/',
  },
]

const MiniMenu = ({ navigator }: Props) => {
  const i18n = useI18n()
  const currentScreen = useLocation().pathname.substr(1)

  const renderShortcut = ({ name, icon, iconColor, bgColor, link }: DrawerItem) => {
    const isCurrentScreen = currentScreen.includes(name) || (!currentScreen && name === 'home')
    const iconCol = isCurrentScreen ? theme.colors.primary : iconColor
    const bgCol = isCurrentScreen ? theme.colors.primaryLight : bgColor

    return (
      <FunctionalityContainer
        activeOpacity={0.9}
        onPress={() => {
          if (!!link) {
            window.open(link)
          } else {
            navigator.navigate(name)
          }
        }}
      >
        <CircleIcon size={18} iconColor={iconCol} bgColor={bgCol} name={icon} />
      </FunctionalityContainer>
    )
  }

  const logout = () => {
    Alert.open({
      title: i18n.t('actions.logout'),
      text: i18n.t('screens.account.logout'),
      buttons: [
        {
          label: i18n.t('common.yes'),
          action: () => {
            userStore.actions.resetUser()
            authStore.actions.setTokenStatus('none')
            authStore.actions.setLogged(false)

            Alert.close()
          },
        },
        { label: i18n.t('common.no'), action: Alert.close },
      ],
      cancelable: true,
    })
  }

  return (
    <MainContainer>
      <TouchableOpacity activeOpacity={0.9} onPress={() => navigator.navigate('home')}>
        <Icon name="logo" size={40} />
      </TouchableOpacity>

      <ShortcutsView showsVerticalScrollIndicator={false}>
        <FlatList
          data={userShortcuts}
          renderItem={({ item }) => renderShortcut(item)}
          extraData={currentScreen}
          keyExtractor={(item: DrawerItem) => item.name}
        />
        <Separator />
        <FlatList
          data={features}
          renderItem={({ item }) => renderShortcut(item)}
          extraData={currentScreen}
          keyExtractor={(item: DrawerItem) => item.name}
        />
      </ShortcutsView>
      <Footer>
        <FunctionalityContainer activeOpacity={0.9} onPress={logout}>
          <CircleIcon size={18} iconColor={theme.colors.tertiary} bgColor={theme.colors.tertiaryLight} name="power" />
        </FunctionalityContainer>
        <AboutContainer onPress={() => navigator.navigate('privacy')}>
          <Icon size={16} name="infos" color={theme.colors.secondaryText} />
        </AboutContainer>
      </Footer>
    </MainContainer>
  )
}

export default MiniMenu

const MainContainer = styled(View)`
  padding: 10px 15px 15px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  z-index: 1;
`

const Separator = styled(View)`
  height: 1px;
  background-color: ${props => props.theme.colors.separator};
  margin: 16px 0px;
`

const FunctionalityContainer = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
`

const Footer = styled(View)`
  margin-top: 10px;
`

const AboutContainer = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const ShortcutsView = styled(ScrollView)`
  flex: 1;
`
