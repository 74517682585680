import * as React from 'react'
import { MapBuilding, MovePosition } from './types'
import { VGWindow, EventActionCallback, Mapviewer, Route, RouteNavigation } from './visioweb'

import useResize from './useResize'

const BASE_URL = 'https://mapserver.visioglobe.com/'

const OPTIMIZATIONS = {
  batchTexts: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: false,
  },
  batchIcons: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: true,
  },
  unloadDelay: 2000,
}

type Status = 'initialized' | 'init'

export type Mode = 'nav' | 'place'

export const PMR_EXCLUDE = ['stairway', 'escalator', 'stair']

const useMap = (hash: string, lang: string) => {
  const MapViewer = React.useMemo(() => {
    const ret = new ((window as unknown as VGWindow).visioweb as any).Mapviewer() as Mapviewer
    ;(window as any).MAPVIEWER = ret
    return ret
  }, [])
  const ref = React.useRef<HTMLDivElement>(null)

  const [height, width] = useResize()

  const [status, setStatus] = React.useState<Status>('init')
  const [building, setBuilding] = React.useState<string>()
  const [floor, setFloor] = React.useState<string>()
  const [place, setPlace] = React.useState<Place>()

  const [from, setFrom] = React.useState<Place>()
  const [to, setTo] = React.useState<Place>()

  const [buildings, setBuildings] = React.useState<MapBuilding[]>([])
  const [places, setPlaces] = React.useState<Place[]>()

  const [route, setRoute] = React.useState<Route>()
  const [nav, setNav] = React.useState<RouteNavigation>()
  const [mode, setMode] = React.useState<Mode>()

  /**
   * callback called when map is fully loaded and venue is ready
   */
  const onVenueLoaded = () => {
    const datas =
      MapViewer.getExtraData().resources[lang]?.localized.locale[lang] ||
      MapViewer.getExtraData().resources.default.localized.locale.default
    const bs = MapViewer.multiBuildingView.getVenueLayout().buildings

    const next: MapBuilding[] = bs.map(b => ({
      id: b.id,
      name: datas.venueLayout[b.id] ? datas.venueLayout[b.id].name : b.id,
      floors: b.floors.map(f => ({
        id: f.id,
        name: datas.venueLayout[f.id] ? datas.venueLayout[f.id].shortName : f.id,
        index: f.levelIndex,
      })),
    }))

    // get all buildings for venue
    setBuildings(next)
  }

  /**
   * callback called when map change building or floor
   * @param move the moving object from visioglobe
   */
  const onMove: EventActionCallback = move => {
    if (move.type === 'exploreStateWillChange') {
      setBuilding((move.args.target as MovePosition).buildingID)
      setFloor((move.args.target as MovePosition).floorID)
    }
  }

  const goTo = (building: string, floor?: string, place?: string) => {
    const placeName = !!place ? MapViewer.getPlaceName(place) : undefined
    setPlace(!!place ? { id: place, name: !!placeName ? placeName : place } : undefined)

    setFloor(floor)
    setBuilding(building)
  }

  /**
   * update pois on map based on referentials
   * @param pois mapping id -> name
   */
  const updatePOIs = (pois: Place[]) => {
    if (pois) {
      setPlaces(pois.map(p => ({ name: p.name ? p.name : p.id, id: p.id })))
      pois.map(p => {
        const pl = MapViewer.getPlace(p.id)

        if (pl) {
          if (p.name) {
            pl.setName(p.name)
          }
        }
      })
    }
  }

  /**
   * start route between 2 places
   * @param from starting place
   * @param to ending place
   * @param pmr should use PMR or not ?
   * @param lng translation for instructions
   */
  const computeRoute = (from: string, to: string, pmr: boolean, lng: string) => {
    // calculate route information
    return MapViewer.computeRoute({
      src: from,
      dst: to,
      language: lng,
      computeNavigation: true,
      routingParameters: {
        requestType: 'fastest',
        excludedAttributes: pmr ? PMR_EXCLUDE : [],
      },
    }).then(res => {
      // create route
      const r = /* TODO MBA */ new ((window as unknown as VGWindow).visioweb as any).Route(MapViewer, res.data) as Route

      // route invalid
      if (!r.isValid() || res.data.status !== 200) {
        throw new Error('route invalid')
      }

      // navigate to start
      setBuilding('')
      setPlace(undefined)
      setFloor(undefined)
      setMode('nav')

      if (r.initialFloor) {
        MapViewer.multiBuildingView.goTo({ floorID: r.initialFloor, place: from })
      } else {
        MapViewer.multiBuildingView.goTo({ place: from })
      }

      // and show route
      r.show()

      // store route
      setRoute(r)
      setNav(r.navigation)

      // translate route navigation steps
      MapViewer.navigationTranslator.translateInstructions(res.data.navigation, lng)

      return res
    })
  }

  /**
   * navigate to next step
   */
  const nextRoute = () => {
    if (nav) {
      nav.displayNextInstruction()
    }
  }

  /**
   * navigate to previous step
   */
  const previousRoute = () => {
    if (nav) {
      nav.displayPrevInstruction()
    }
  }

  /**
   * stop route and reset navigation
   */
  const stopRoute = () => {
    if (route) {
      route.remove()
    }

    setNav(undefined)
    setRoute(undefined)
  }

  /**
   * Callback called when place is selected
   * @param ev the event
   * @param el the visioglobe element selected
   */
  const onClick = (ev: any, el: any) => {
    const placeName = MapViewer.getPlaceName(el.vg.id)
    setMode('place')
    if (el.vg && el.vg.id) {
      setPlace({ id: el.vg.id, name: !!placeName ? placeName : el.vg.id })
    } else {
      setPlace(undefined)
    }
  }

  /**
   * Callback called when main map system is initialized
   */
  const onInit = () => {
    // start render and give dimensions
    MapViewer.start()
    MapViewer.resize(height, width)

    // set the map as multi building and multi floors
    MapViewer.setupMultiBuildingView({
      viewType: 'multibuilding',
      animationType: 'translation',
      container: ref.current!,
    })

    // set the translator
    MapViewer.setupNavigationTranslator(MapViewer.getPlaces())

    // set camera params
    MapViewer.camera.minRadius = 50.0
    MapViewer.cameraDrivenExplorer.maxExploreDistance = 300.0
    MapViewer.cameraDrivenExplorer.setEnabled(true)

    // add listener on building/floor changed
    MapViewer.on('exploreStateWillChange', onMove)
    // start at global location
    MapViewer.multiBuildingView.goTo({ mode: 'global', noViewpoint: true })

    // venue loaded we try to find informations
    onVenueLoaded()

    // update status at initialized
    setStatus('initialized')
  }

  React.useEffect(() => {
    // add listener on initialization
    MapViewer.on('initializeCompleted', onInit)

    // download map and setup render
    MapViewer.load({
      path: `${BASE_URL}${hash}/descriptor.json`,
      cameraType: 'perspective',
      onObjectMouseUp: onClick,
      optimizations: OPTIMIZATIONS,
    }).then(() => {
      if (ref.current) {
        MapViewer.setupView(ref.current)
      }
      const allPlaces = MapViewer.getPlaces()
      setPlaces(
        Object.keys(allPlaces).map(k => {
          const placeName = MapViewer.getPlaceName(allPlaces[k].vg.id)
          return { id: allPlaces[k].vg.id, name: !!placeName ? placeName : allPlaces[k].vg.id }
        })
      )
    })
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 'initialized') {
      // resize the map when container size changed
      MapViewer.resize(width, height)
    }
    // eslint-disable-next-line
  }, [height, width, status])

  React.useEffect(() => {
    if (buildings.length === 1) {
      setBuilding(buildings[0].id)
    }
  }, [buildings])

  React.useEffect(() => {
    if (place) {
      // MapViewer.multiBuildingView.goTo({ place: place.id })
    } else if (floor) {
      MapViewer.multiBuildingView.goTo({ mode: 'floor', floorID: floor })
    } else if (building) {
      MapViewer.multiBuildingView.goTo({ mode: 'global', buildingID: building })
    }
    // eslint-disable-next-line
  }, [building, floor, place])

  const actions = {
    goTo,
    computeRoute,
    stopRoute,
    nextRoute,
    previousRoute,
    updatePOIs,
    setPlace,
  }

  const itinerary = {
    from,
    setFrom,
    to,
    setTo,
    mode,
    setMode,
  }

  return [ref, status, building, floor, place, buildings, actions, itinerary, places, nav] as const
}

export default useMap
