import * as React from 'react'

interface ResizableWindow extends Window {
  ResizeObserver: any
}

// give dimensions for given ref or give window dimensions
const useResize = (ref?: React.RefObject<HTMLElement>) => {
  const [h, setH] = React.useState(0)
  const [w, setW] = React.useState(0)

  const onResize = () => {
    if (ref) {
      if (ref.current) {
        setH(ref.current.offsetHeight)
        setW(ref.current.offsetWidth)
      }
    } else {
      setH(window.innerHeight)
      setW(window.innerWidth)
    }
  }

  React.useEffect(() => {
    onResize()

    if (ref) {
      if (ref.current) {
        const observer = new (window as unknown as ResizableWindow).ResizeObserver(onResize)
        observer.observe(ref.current)
        return observer.disconnect
      }
    } else {
      window.onresize = onResize
      return () => (window.onresize = null)
    }
  })

  return [h, w] as const
}

export default useResize
