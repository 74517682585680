import * as React from 'react'
import { Platform, Text, View } from 'react-native'

import useI18n from '@store/i18n/useI18n'

import styled from '@theme/styled-components'

const MenuAndDay = () => {
  const i18n = useI18n()
  const currentDay = i18n.t('screens.home.historyDate', { date: new Date() })

  return (
    <MenuView>
      <Menu>{i18n.t('screens.restaurant.detail.menu')}</Menu>
      <CurrentDate>{currentDay.toString()}</CurrentDate>
    </MenuView>
  )
}
export default MenuAndDay
const MenuView = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: ${Platform.OS === 'web' ? '10px 24px' : '0px 24px'};
`
const CurrentDate = styled(Text)`
  ${props => props.theme.fonts.genericSubtitle};
  color: ${props => props.theme.colors.primaryDark};
`
const Menu = styled(Text)`
  ${props => props.theme.fonts.genericTitle2};
  color: ${props => props.theme.colors.primaryDark};
  flex: 1;
`
