import * as React from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'

import styled from '@theme/styled-components'

import useI18n from '@store/i18n/useI18n'

interface Props {
  value: string
  addAmount: () => void
}

const SubmitButton = ({ value, addAmount }: Props) => {
  const i18n = useI18n()
  const [isButtonSmall, setIsButtonSmall] = React.useState(false)

  return (
    <View onLayout={event => setIsButtonSmall(event.nativeEvent.layout.width < 300)}>
      <Button disabled={Number(value) < 20} activeOpacity={0.9} onPress={addAmount}>
        <ButtonText>
          {isButtonSmall && Platform.OS === 'web' ? i18n.t('actions.addAmountReduced') : i18n.t('actions.addAmount')}
        </ButtonText>
      </Button>
    </View>
  )
}
export default SubmitButton
const Button = styled(TouchableOpacity)<{ disabled: boolean }>`
  height: 56px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: ${Platform.OS === 'web' ? '10px' : '0px'};
  border-bottom-left-radius: ${Platform.OS === 'web' ? '10px' : '0px'};
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.disabled ? props.theme.colors.disable : props.theme.colors.primary)};
  margin: ${Platform.OS === 'web' ? '46px 20px' : '0px'};
`

const ButtonText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  font-weight: bold;
  align-self: center;
  color: #fff;
`
