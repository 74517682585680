import * as React from 'react'
import { View } from 'react-native'
import styled from '@theme/styled-components'

import Map from './Map.web'

import api from '@api/api'

interface Props {
  destination?: any
}

const MapScreen = ({ destination }: Props) => {
  const [siteInfo, setSiteInfo] = React.useState<SiteInfo>()

  React.useEffect(() => {
    api.map.getSiteInfo().then(setSiteInfo)
  }, [])

  return (
    <MainContainer>
      {!!siteInfo && <Map hash={siteInfo.webHashMap} destination={destination} siteId={siteInfo.visioglobeSiteId} />}
    </MainContainer>
  )
}

const MainContainer = styled(View)`
  flex: 1;
  position: relative;
  display: flex;
`

export default MapScreen
