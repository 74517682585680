import { addDays, isBefore } from 'date-fns'

export const getExcludedDays = (i18n: I18nState, startDate: string, numberOfDays: number, includedDays: string[]) => {
  const allDays = new Array(numberOfDays).fill(0).map((_, i) => {
    if (i === 0) {
      return startDate
    } else return i18n.t('screens.booking.dateString', { date: addDays(new Date(startDate), i) })
  })

  return allDays.filter(d => !includedDays.includes(d))
}

export const getFirstSelectableDate = (i18n: I18nState, includedDays: string[]) => {
  const earliest = includedDays.reduce((acc, cur) => {
    if (!!acc && isBefore(new Date(acc), new Date(cur))) {
      return acc
    } else return cur
  })
  return i18n.t('screens.booking.dateString', { date: earliest })
}

export const getEarliestBooking = (bookings: BookingItem[]) =>
  bookings.length > 0
    ? bookings.reduce((acc, cur) => {
        if (!!acc && isBefore(new Date(acc.startHour), new Date(cur.startHour))) {
          return acc
        } else return cur
      })
    : undefined

export const filterPastBookings = (b: BookingItem) => {
  const rightNow = new Date()
  return isBefore(rightNow, new Date(b.endHour))
}
