import { NativeModules, Platform } from 'react-native'
const { ConfigModule } = NativeModules

const ENV: Env = (ConfigModule && ConfigModule.ENV) || (Platform.OS === 'web' && process.env.REACT_APP_ENV) || 'release'

const VERSION_PREFIX = '/services/v1'

const CommonConfig: CommonConfig = {
  constants: {
    emergency: '01 49 03 96 07',
    mapwize: {
      apiKey: '93bd064e4dd39b93895bf432cadaf85f',
      venueId: '5ecd1d3d41c69f0016619f4c',
      // apiKey: '4925d309cf563617c08615a2a2d0d099', // cardiweb
      // venueId: '5bc84c6a59b8350012dd8aad', // cardiweb
    },
    polestar: '6s90H2CASTuYuft_v6ORsg@eu',
    // polestar: 'tb8UiVh65NNI8bF9AlUv_w', // cardiweb
    tabBarHeight: 56,
  },
  ws: {
    news: {
      all: VERSION_PREFIX + '/articles',
      get: VERSION_PREFIX + '/articles/{id}',
      like: VERSION_PREFIX + '/articles/{id}/like',
      dislike: VERSION_PREFIX + '/articles/{id}/like',
    },
    account: {
      buildings: VERSION_PREFIX + '/users/buildings',
      floors: VERSION_PREFIX + '/users/floors',
      get: VERSION_PREFIX + '/users/me',
      update: VERSION_PREFIX + '/users/me',
      delete: VERSION_PREFIX + '/users/me',
    },
    auth: {
      login: VERSION_PREFIX + '/public/oauth/token',
      refresh: VERSION_PREFIX + '/public/oauth/token',
      code: VERSION_PREFIX + '/public/codes',
      validateCode: VERSION_PREFIX + '/publi/codes',
      create: VERSION_PREFIX + '/public/users',
      password: VERSION_PREFIX + '/public/users/password',
      validateEmail: VERSION_PREFIX + '/public/validateEmail',
      cgu: VERSION_PREFIX + '/public/cgu',
    },
    edito: {
      all: VERSION_PREFIX + '/editorials',
      get: VERSION_PREFIX + '/editorials/{id}',
    },
    restauration: {
      list: VERSION_PREFIX + '/restaurants',
      get: VERSION_PREFIX + '/restaurants/{restaurantId}',
      token: VERSION_PREFIX + '/restaurants/token',
      badgeCredit: VERSION_PREFIX + '/restaurants/user/badgecredit',
      balance: VERSION_PREFIX + '/restaurants/user/balance',
      history: VERSION_PREFIX + '/restaurants/user/history',
    },
    booking: {
      userBookings: VERSION_PREFIX + '/booking',
      create: VERSION_PREFIX + '/booking/',
      cancel: VERSION_PREFIX + '/booking/{idBooking}/cancel',
      getCalendar: VERSION_PREFIX + '/booking/professional/{idProfessional}/calendar',
      getAllPros: VERSION_PREFIX + '/booking/professionals',
    },
    visioglobe: {
      places: VERSION_PREFIX + '/places',
      siteInfo: VERSION_PREFIX + '/places/siteInfo',
    },
  },
}

const EnvConfig = (env: Env): EnvConfig => {
  switch (env) {
    case 'dev':
      return {
        SERVER_PREFIX: Platform.OS === 'web' ? 'http://localhost:3000' : 'https://coeurlive.int.cardiweb.com',
        logLevel: 'debug',
        apiKey: '99Umh2GXakgmpmUgeKTBKBnHc66c4wfC',
        polestarKey: 'tb8UiVh65NNI8bF9AlUv_w', // Key polestar cardiweb
        contactEmail: 'scaprim@cardiweb.com',
      }
    case 'int':
      return {
        SERVER_PREFIX: 'https://coeurlive.int.cardiweb.com',
        logLevel: 'debug',
        apiKey: '99Umh2GXakgmpmUgeKTBKBnHc66c4wfC',
        polestarKey: 'tb8UiVh65NNI8bF9AlUv_w', // Key polestar cardiweb
        contactEmail: 'scaprim@cardiweb.com',
      }
    case 're7':
      return {
        SERVER_PREFIX: 'https://coeurlive-re7.int.cardiweb.com',
        logLevel: 'info',
        apiKey: '99Umh2GXakgmpmUgeKTBKBnHc66c4wfC',
        polestarKey: '6s90H2CASTuYuft_v6ORsg@eu',
        contactEmail: 'scaprim@cardiweb.com',
      }

    case 'release':
      return {
        SERVER_PREFIX: 'https://coeurlive.fr',
        logLevel: 'warn',
        apiKey: '99Umh2GXakgmpmUgeKTBKBnHc66c4wfC',
        polestarKey: '6s90H2CASTuYuft_v6ORsg@eu',
        contactEmail: 'scaprim@cardiweb.com',
      }
    default: {
      throw Error('Unknown env : ' + ENV)
    }
  }
}

const config: Config = {
  ENV,
  ...CommonConfig,
  ...EnvConfig(ENV),
}

const load = (env: Env) => {
  Object.assign(config, { ENV: env })
  Object.assign(config, CommonConfig)
  Object.assign(config, EnvConfig(env))
}

export default config
export { load }
