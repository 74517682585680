import { createEvent, createStore } from 'effector'

export const actions = {
  setBalance: createEvent<number>('setBalance'),
  resetBalance: createEvent('resetBalance'),
}

const initialState: RestoState = {
  balance: undefined,
}

export const store = createStore<RestoState>(initialState)
  .on(actions.setBalance, (s, balance) => ({ ...s, balance }))
  .on(actions.resetBalance, () => ({ ...initialState }))
