import * as React from 'react'
import { View, Text, TouchableOpacity, Platform, Linking } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Root from '@components/root/Root'
import Animators from '@components/root/Animators'
import ProgressBar from '@components/progress/ProgressBar'
import RadioButtons from '@components/select/RadioButtons'
import Check from '@components/select/Check'
import Icon from '@components/icons/Icons'
import Alert from '@components/alert/Alert'

import FieldInput, { Component as InputComponent } from './FieldInput'
import FieldPassword, { Component as PasswordComponent } from './FieldPassword'
import FieldSelect, { Component as SelectComponent } from './FieldSelect'
import BadgeInput, { Component as BadgeComponent } from '@screens/badge/BadgeInput'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as userStore from '@store/user/user'
import * as authStore from '@store/auth/auth'

import api from '@api/api'
import CredentialService from '@api/CredentialService'
import Logger from '@utils/logger'
import analytics from '@utils/analytics'
import config from '@app/config'

type Field = 'email' | 'firstName' | 'lastName' | 'floor' | 'building' | 'companyName' | 'gender' | 'badge'
type UpdateField = keyof UserUpdate

interface Props {
  navigator: Navigation
}

const GENDERS = ['H', 'F']
const CompletableFields = ['email', 'firstName', 'lastName', 'floor', 'building', 'companyName', 'gender', 'badge']

const AccountScreen = ({ navigator }: Props) => {
  const i18n = useI18n()

  const user = useReducer(userStore.store, s => s.user!)
  const useGeoloc = useReducer(userStore.store, s => s.useGeoloc)

  const [buildings, setBuildings] = React.useState<Buildings>([])
  const [floors, setFloors] = React.useState<Floors>([])
  const [loading, setLoading] = React.useState(false)

  // for web only
  const [current, setCurrent] = React.useState<Field | 'password'>()

  React.useEffect(() => {
    analytics.onScreen('Mon compte', 'AccountScreen')

    Promise.all([api.account.get(), api.account.buildings(), api.account.floors()])
      .then(([us, bu, fl]) => {
        userStore.actions.setUser(us)
        setBuildings(bu)
        setFloors(fl as Floors)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  const updateAccount = (field: UpdateField, value: string) => {
    const fl = floors.find(f => f.name === user.floor)

    const next: UserUpdate = { ...user, floorId: fl && fl.id, [field]: value }

    api.account.update(next).then(() => {
      api.account.get().then(userStore.actions.setUser)
    })
  }

  const changePassword = (newPassword: string) => {
    const { credentials } = CredentialService.get()
    const next: UserUpdate = { ...user, newPassword, newPasswordConfirmation: newPassword }
    if (credentials) {
      api.account.update(next).catch(err => {
        Alert.open({
          title: i18n.t('common.warning'),
          text: i18n.t('errors.tryAgain'),
          buttons: [
            {
              label: i18n.t('common.ok'),
              action: Alert.close,
            },
          ],
          cancelable: true,
        })
        Logger.error(err)
      })
    } else {
      Alert.open({
        title: i18n.t('common.warning'),
        text: i18n.t('errors.tryAgain'),
        buttons: [
          {
            label: i18n.t('common.ok'),
            action: Alert.close,
          },
        ],
        cancelable: true,
      })
    }
  }
  const logout = () => {
    Alert.open({
      title: i18n.t('actions.logout'),
      text: i18n.t('screens.account.logout'),
      buttons: [
        {
          label: i18n.t('common.yes'),
          action: () => {
            userStore.actions.resetUser()
            authStore.actions.setTokenStatus('none')
            authStore.actions.setLogged(false)

            Alert.close()
          },
        },
        { label: i18n.t('common.no'), action: Alert.close },
      ],
      cancelable: true,
    })
  }

  const createDeleteAccountEmail = () => {
    Linking.openURL(
      `mailto:${config.contactEmail}?subject=${i18n.t('screens.account.deleteAccount.subject')}&body=${i18n.t(
        'screens.account.deleteAccount.body'
      )}`
    )
  }

  const openField = (field: Field | 'password') => {
    if (Platform.OS === 'web') {
      setCurrent(field)
    } else {
      switch (field) {
        case 'firstName':
        case 'lastName':
        case 'companyName':
          FieldInput.open({
            i18n,
            title: i18n.t(`screens.account.input.${field}`),
            value: user[field] || '',
            onValidate: val => updateAccount(field, val),
            withSpecial: field === 'companyName',
            apostropheAuthorized: field === 'lastName',
          })
          break
        case 'building':
          FieldSelect.open({
            data: buildings.map(b => b.name),
            title: i18n.t('screens.account.input.tower'),
            value: buildings.findIndex(b => b.name === user.building),
            onValidate: (val, idx) => updateAccount('building', val),
          })
          break
        case 'floor':
          FieldSelect.open({
            data: floors.map(b => b.name),
            title: i18n.t('screens.account.input.stage'),
            value: floors.findIndex(f => f.name === user.floor),
            onValidate: (val, idx) => updateAccount('floorId', floors.find(f => f.name === val)!.id),
          })
          break
        case 'password':
          FieldPassword.open({
            i18n,
            onValidate: (prev, cur) => changePassword(cur),
          })
          break
        case 'badge':
          BadgeInput.open({
            i18n,
            value: user[field] || '',
            onValidate: val => updateAccount('badge', val),
          })
          break
      }
    }
  }

  const renderWebContent = () => {
    switch (current) {
      case 'firstName':
      case 'lastName':
      case 'companyName':
        return (
          <InputComponent
            key={current}
            i18n={i18n}
            title={i18n.t(`screens.account.input.${current}`)}
            onValidate={val => updateAccount(current, val)}
            value={user[current] || ''}
            onClose={() => setCurrent(undefined)}
          />
        )
      case 'building':
        return (
          <SelectComponent
            key="building"
            data={buildings.map(b => b.name)}
            title={i18n.t('screens.account.input.tower')}
            value={buildings.findIndex(b => b.name === user.building)}
            onValidate={(val, idx) => updateAccount('building', val)}
            onClose={() => setCurrent(undefined)}
          />
        )
      case 'floor':
        return (
          <SelectComponent
            key="floor"
            data={floors.map(b => b.name)}
            title={i18n.t('screens.account.input.stage')}
            value={floors.findIndex(f => f.name === user.floor)}
            onValidate={(val, idx) => updateAccount('floorId', floors.find(f => val === f.name)!.id)}
            onClose={() => setCurrent(undefined)}
          />
        )
      case 'password':
        return (
          <PasswordComponent
            i18n={i18n}
            onValidate={(prev, cur) => changePassword(cur)}
            key="password"
            onClose={() => setCurrent(undefined)}
          />
        )
      case 'badge':
        return (
          <BadgeComponent
            i18n={i18n}
            onValidate={val => updateAccount(current, val)}
            value={user[current] || ''}
            key="badge"
            onClose={() => setCurrent(undefined)}
          />
        )
    }

    return undefined
  }

  if (loading) {
    return null
  }

  const completionRate = CompletableFields.filter(f => !!user[f as keyof UserInfo]).length / CompletableFields.length

  const isDisabled = (field: Field | 'password') => Platform.OS === 'web' && !!current && current !== field
  const isSelected = (field: Field | 'password') => Platform.OS === 'web' && !!current && current === field

  return (
    <Root
      header
      headerTitle={i18n.t('screens.account.title')}
      navigator={navigator}
      renderContent={Platform.OS === 'web' ? renderWebContent : undefined}
    >
      <MainContainer>
        <Animators>
          {/* Progress */}
          <Section>
            <SectionTitle>{i18n.t('screens.account.progress')}</SectionTitle>
            <PercentBloc>
              <Percent>{Math.round(completionRate * 100)}</Percent>
              <PercentUnit> %</PercentUnit>
            </PercentBloc>
          </Section>
          <ProgressBar value={completionRate} />
          {/* Profil */}
          <Section>
            <SectionTitle>{i18n.t('screens.account.profil')}</SectionTitle>
          </Section>
          <RadioButtons
            data={GENDERS.map(g => i18n.t(`screens.account.gender.${g}`))}
            value={GENDERS.indexOf(user.gender || '')}
            onItemSelected={(val, idx) => updateAccount('gender', GENDERS[idx])}
          />
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('firstName')}
            selected={isSelected('firstName')}
            disabled={isDisabled('firstName')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('firstName')}>{i18n.t('screens.account.input.firstName')}</InputTitle>
              <InputValue disabled={isDisabled('firstName')}>{user.firstName}</InputValue>
            </InputTextContainer>
            <Icon
              name="pen"
              size={20}
              color={isDisabled('firstName') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('lastName')}
            selected={isSelected('lastName')}
            disabled={isDisabled('lastName')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('lastName')}>{i18n.t('screens.account.input.lastName')}</InputTitle>
              <InputValue disabled={isDisabled('lastName')}>{user.lastName}</InputValue>
            </InputTextContainer>
            <Icon
              name="pen"
              size={20}
              color={isDisabled('lastName') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          <InputContainer activeOpacity={1} onPress={() => null} selected={false} disabled={false}>
            <InputTextContainer>
              <InputTitle disabled={isDisabled('email')}>{i18n.t('screens.account.input.email')}</InputTitle>
              <InputValue disabled={isDisabled('email')}>{user.email}</InputValue>
            </InputTextContainer>
          </InputContainer>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('password')}
            selected={isSelected('password')}
            disabled={isDisabled('password')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('password')}>{i18n.t('screens.account.input.password')}</InputTitle>
            </InputTextContainer>
            <Icon
              name="pen"
              size={20}
              color={isDisabled('password') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          {/* Infos */}
          <Section>
            <SectionTitle>{i18n.t('screens.account.infos')}</SectionTitle>
          </Section>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('companyName')}
            selected={isSelected('companyName')}
            disabled={isDisabled('companyName')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('companyName')}>
                {i18n.t('screens.account.input.companyName')}
              </InputTitle>
              <InputValue disabled={isDisabled('companyName')}>{user.companyName}</InputValue>
            </InputTextContainer>
            <Icon
              name="pen"
              size={20}
              color={isDisabled('companyName') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('building')}
            selected={isSelected('building')}
            disabled={isDisabled('building')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('building')}>
                {/* Si l'utilisateur a une tour renseignée, on afficher Tour : \n'saTour'. Sinon, on affiche 'Ajouter une tour' */}
                {user.building ? i18n.t('screens.account.tower') : i18n.t('screens.account.input.tower')}
              </InputTitle>
              {user.building && <InputValue disabled={isDisabled('building')}>{user.building}</InputValue>}
            </InputTextContainer>
            <Icon
              name={user.building ? 'pen' : 'plus'}
              size={20}
              color={isDisabled('building') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('floor')}
            selected={isSelected('floor')}
            disabled={isDisabled('floor')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('floor')}>
                {/* Si l'utilisateur a un étage renseigné, on afficher Etage : \n'sonEtage'. Sinon, on affiche 'Ajouter un étage' */}
                {user.floor ? i18n.t('screens.account.stage') : i18n.t('screens.account.input.stage')}
              </InputTitle>
              {user.floor && <InputValue disabled={isDisabled('floor')}>{user.floor}</InputValue>}
            </InputTextContainer>
            <Icon
              name={user.floor ? 'pen' : 'plus'}
              size={20}
              color={isDisabled('floor') ? Theme.colors.disableItem : Theme.colors.primary}
            />
          </InputContainer>
          <InputContainer
            activeOpacity={0.8}
            onPress={() => openField('badge')}
            selected={isSelected('badge')}
            disabled={isDisabled('badge')}
          >
            <InputTextContainer>
              <InputTitle disabled={isDisabled('badge')}>{i18n.t('screens.reload.badgeNum')}</InputTitle>
              <InputValue disabled={isDisabled('badge')}>{user.badge}</InputValue>
            </InputTextContainer>
            <Icon name="pen" size={20} color={isDisabled('badge') ? Theme.colors.disableItem : Theme.colors.primary} />
          </InputContainer>
          <CheckContainer>
            <Check
              value={useGeoloc}
              onChange={userStore.actions.setUseGeoloc}
              label={i18n.t('screens.account.input.geoloc')}
            />
          </CheckContainer>
          <Disconnect activeOpacity={0.8} selected={false} onPress={logout}>
            <DisconnectText disabled={false}>{i18n.t('actions.logout')}</DisconnectText>
          </Disconnect>

          {Platform.OS !== 'web' && (
            <DeleteAccountLink onPress={createDeleteAccountEmail}>
              <DeleteAccountText>{i18n.t('screens.account.deleteAccount.title')}</DeleteAccountText>
            </DeleteAccountLink>
          )}
        </Animators>
      </MainContainer>
    </Root>
  )
}

const MainContainer = styled(View)`
  padding: 25px 25px 0px;
  background-color: ${props => props.theme.colors.background};
  flex: 1;
`

const Section = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px;
`

const SectionTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  flex: 1;
`

const PercentBloc = styled(View)`
  flex-direction: row;
  align-items: flex-start;
`

const Percent = styled(Text)`
  ${props => props.theme.fonts.accountPercent};
`

const PercentUnit = styled(Text)`
  ${props => props.theme.fonts.accountUnit};
`

const InputContainer = styled(TouchableOpacity)<{ selected: boolean }>`
  background-color: ${props => (props.selected ? props.theme.colors.primaryLight : '#fff')};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
`

const InputTextContainer = styled(View)`
  margin-right: 20px;
  flex: 1;
`

const InputTitle = styled(Text)<{ disabled: boolean }>`
  ${props => props.theme.fonts.genericLabel};
  color: ${props => (props.disabled ? props.theme.colors.disableItem : props.theme.colors.secondaryText)};
  text-transform: uppercase;
`

const InputValue = styled(Text)<{ disabled: boolean }>`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => (props.disabled ? props.theme.colors.disableItem : props.theme.colors.primaryDark)};
`

const Disconnect = styled(InputContainer)`
  background-color: ${props => props.theme.colors.tertiaryLight};
  margin: 25px 0px;
`

const DisconnectText = styled(InputTitle)`
  color: ${props => props.theme.colors.tertiary};
  opacity: 0.5;
`

const CheckContainer = styled(View)`
  margin-top: 25px;
  margin-right: 25px;
  padding: 0px 0px 0px 0px;
`

const DeleteAccountText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primary};
  margin-left: 5px;
`

const DeleteAccountLink = styled(TouchableOpacity)`
  margin-bottom: 25px;
`

export default AccountScreen
