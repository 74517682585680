import * as React from 'react'
import { Platform } from 'react-native'
const useResizeWindow = () => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeigt] = React.useState(window.innerHeight)

  const onSizeChange: EventListenerOrEventListenerObject = () => {
    setWidth(window.innerWidth)
    setHeigt(window.innerHeight)
  }

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', onSizeChange)
      return () => window.removeEventListener('resize', onSizeChange)
    }
  }, [])

  return [height, width]
}
export default useResizeWindow
