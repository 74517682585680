import * as React from 'react'

interface Props {
  html: string
}

const enhanceContent = (html: string) => html.replace(/<a /g, '<a target="_blank"')

export default ({ html }: Props) => {
  const [size, setSize] = React.useState(0)
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref.current) {
      setSize(ref.current.offsetWidth)
    }
  }, [ref])

  if (!size) {
    return <div ref={ref} />
  }

  return <div style={{ width: size, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: enhanceContent(html) }} />
}
