import * as React from 'react'
import { View, Animated, Easing, Text, TouchableOpacity } from 'react-native'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'

import ReactDOM from 'react-dom'

import useI18n from '@store/i18n/useI18n'

interface Props {
  phone: string
}

const Component = ({ phone }: Props) => {
  const anim = React.useRef(new Animated.Value(0))
  const i18n = useI18n()

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 150, easing: Easing.linear, useNativeDriver: true }).start()
  }, [])

  const close = () => {
    Animated.timing(anim.current, { toValue: 0, duration: 150, easing: Easing.linear, useNativeDriver: true }).start(
      Alert.close
    )
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <Back activeOpacity={1} onPress={close} />

      <PopupContainer>
        <CloseButton activeOpacity={0.9} onPress={close}>
          <Icon name="close" color="white" size={24} />
        </CloseButton>
        <Line>
          <Call>
            <Icon name="phone" color={Theme.colors.info} size={28} />
          </Call>
          <Title>{i18n.t('components.emergencyAlert.title')}</Title>
        </Line>
        <ContentText>{i18n.t('components.emergencyAlert.textWeb')}</ContentText>

        <Phone>{phone}</Phone>
      </PopupContainer>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
`

const Back = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #00000088;
`

// COMPONENT

const PopupContainer = styled(View)`
  background-color: white;
  border-radius: 32px;
  padding: 40px 28px;
  max-width: 700px;
  min-width: 200px;
  justify-content: center;
  margin: 24px;
`

const CloseButton = styled(TouchableOpacity)`
  background-color: ${props => props.theme.colors.info};
  position: absolute;
  right: -8;
  top: -16;
  width: 64;
  height: 64;
  border-radius: 32;
  justify-content: center;
  align-items: center;
`

const Line = styled(View)`
  flex-direction: row;
  margin-right: 15px;
`

const Call = styled(View)`
  margin: 5px 10px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryDark};
  margin-bottom: 35px;
`

const ContentText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
  margin-bottom: 32px;
  text-align: center;
`

const Phone = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.info};
  text-align: center;
`

let alertRoot: any

const Alert = {
  open: (conf: Props) => {
    alertRoot = document.getElementById('alert_root')
    if (alertRoot) {
      ReactDOM.render(
        <ThemeProvider theme={Theme}>
          <Component {...conf} />
        </ThemeProvider>,
        alertRoot
      )
    }
  },
  close: () => {
    if (alertRoot) {
      ReactDOM.unmountComponentAtNode(alertRoot)
    }
  },
}

export default Alert
