import * as React from 'react'
import { View, Platform, Alert, YellowBox } from 'react-native'
import styled, { ThemeProvider } from '@theme/styled-components'
import Theme from '@theme/Theme'

import Splash from 'react-native-splash-screen'
import { setSiblingWrapper } from 'react-native-root-siblings'

import KeyboardManager from 'react-native-keyboard-manager'

import MainLayout from '@layout/MainLayout'

import useReducer from '@store/useReducer'
import { persist } from '@store/store'
import * as authStore from '@store/auth/auth'
import * as userStore from '@store/user/user'

import push from '@utils/push'
import useI18n from '@store/i18n/useI18n'
import logger from '@utils/logger'

import CredentialService from '@api/CredentialService'

// On setup le niveau de log
logger.setLogLevel('debug')
logger.info('App starting')

//Pour désactiver les warning en debug (pop up jaune)
console.disableYellowBox = true
YellowBox.ignoreWarnings([''])

const Layout = React.memo(() => (
  <ThemeProvider theme={Theme}>
    <MainContainer>
      <MainLayout />
    </MainContainer>
  </ThemeProvider>
))

const App = () => {
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const i18n = useI18n()

  React.useEffect(() => {
    if (Platform.OS !== 'web') {
      setSiblingWrapper(sibling => <ThemeProvider theme={Theme}>{sibling}</ThemeProvider>)
    }

    if (Platform.OS === 'ios') {
      KeyboardManager.setEnable(true)
    }

    persist()
      .then(() => {
        setLoaded(true)
      })
      .catch(err => logger.error('cannot create the store', err))
  }, [])

  // On stocke les credentials dans un service afin de pouvoir les récupérer dans les
  // fichiers qui ne permettent pas l'usage des hooks.
  const credentials = useReducer(authStore.store, s => s.credentials)
  const username = useReducer(authStore.store, s => s.username)
  const expirationDate = useReducer(authStore.store, s => s.expirationDate)
  const tokenStatus = useReducer(authStore.store, s => s.tokenStatus)
  const occupationId = useReducer(userStore.store, s => s.user && s.user.occupantId)

  CredentialService.configure(username, credentials, expirationDate)

  React.useEffect(() => {
    if (loaded && Platform.OS !== 'web') {
      Splash.hide()
    }
  }, [loaded])

  React.useEffect(() => {
    if (tokenStatus === 'expired' && !credentials) {
      Alert.alert(i18n.t('common.error'), i18n.t('errors.unexpected_disconnect'), [{ text: i18n.t('common.yes') }])
      authStore.actions.setTokenStatus('none')
    }
  }, [tokenStatus, credentials])

  React.useEffect(() => {
    if (occupationId !== undefined) {
      push.start(occupationId, i18n.lang)
    } else {
      push.stop()
    }
  }, [occupationId])

  if (!loaded) {
    return null
  }

  return <Layout />
}

export default App

const MainContainer = styled(View)`
  flex: 1;
`
