import * as React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  BackHandler,
  Animated,
  Easing,
  Keyboard,
  Platform,
  SafeAreaView,
} from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Icon from '@components/icons/Icons'
import RootComponent from 'react-native-root-siblings'
import KeyboardAvoid from '@components/keyboard/KeyboardAvoid'
import RectangleButton from '@components/button/RectangleButton'

interface Props {
  i18n: I18nState
  value: string
  onValidate: (value: string) => void
  onClose?: () => void
}

const Component = ({ i18n, value, onValidate, onClose }: Props) => {
  const anim = React.useRef(new Animated.Value(0))
  const InputRef = React.useRef<TextInput | null>(null)

  const [val, setVal] = React.useState(value)
  const [error, setError] = React.useState<string>()
  const [displayInfo, setDisplayInfo] = React.useState(false)

  React.useEffect(() => {
    Animated.timing(anim.current, { toValue: 1, duration: 200, easing: Easing.linear, useNativeDriver: true }).start()
    onBlur()
  }, [])

  const close = () => {
    Keyboard.dismiss()
    if (InputRef.current) {
      InputRef.current.blur()
    }
    if (onClose) {
      onClose()
    }
    Animated.timing(anim.current, { toValue: 0, duration: 200, easing: Easing.linear, useNativeDriver: true }).start(
      BadgeInput.close
    )
  }

  const onBlur = React.useCallback(() => {
    if (InputRef.current) {
      InputRef.current.focus()
    }
  }, [InputRef])

  const setValue = (v: string) => {
    setVal(v)

    setError(undefined)
  }

  const submit = () => {
    if (!error) {
      onValidate(val)
      close()
    }
  }

  return (
    <MainContainer style={{ opacity: anim.current }}>
      <KeyboardAvoid>
        <SafeAreaView>
          <HeaderContainer>
            <BackButton activeOpacity={0.9} onPress={close}>
              <Icon name="close" size={24} color={Theme.colors.disable} />
            </BackButton>
            <Title>{i18n.t('actions.addBadge')}</Title>
          </HeaderContainer>
        </SafeAreaView>

        {displayInfo ? (
          <InfoContainer>
            <InstructionBadgeTitle>{i18n.t('screens.reload.whereIsMyNumUp')}</InstructionBadgeTitle>
            <InstructionBadgeText>{i18n.t('screens.reload.badgeInfo')}</InstructionBadgeText>
            <UnderstoodButtonContainer>
              <RectangleButton
                label={i18n.t('screens.reload.understood')}
                onPress={() => setDisplayInfo(false)}
                color="blue"
                size="small"
              />
            </UnderstoodButtonContainer>
          </InfoContainer>
        ) : (
          <React.Fragment>
            <InputContainer>
              {!!error && (
                <WarningContainer>
                  <Icon name="alert" color={Theme.colors.info} size={20} />
                  <WarningText>{i18n.t(error)}</WarningText>
                </WarningContainer>
              )}
              <LabelText>{i18n.t('screens.reload.badgeNum')}</LabelText>
              <Input ref={InputRef} value={val} onChangeText={setValue} onBlur={onBlur} />
              <InfoBadgeContainer activeOpacity={0.8} onPress={() => setDisplayInfo(true)}>
                <InfoBadgeText>{i18n.t('screens.reload.whereIsMyNum')}</InfoBadgeText>
              </InfoBadgeContainer>
              <ImportantInfoContainer>
                <Icon name="alert" size={25} color={Theme.colors.secondaryText} />
                <ImportantInfo>{i18n.t('screens.reload.badgeLastname')}</ImportantInfo>
              </ImportantInfoContainer>
            </InputContainer>

            <Validate activeOpacity={0.8} onPress={submit}>
              <SafeAreaView>
                <ValidateText>{i18n.t('actions.validate')}</ValidateText>
              </SafeAreaView>
            </Validate>
          </React.Fragment>
        )}
      </KeyboardAvoid>
    </MainContainer>
  )
}

const MainContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${props => props.theme.colors.background};
`

const HeaderContainer = styled(View)`
  padding: 30px 24px;
`

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const BackButton = styled(TouchableOpacity)`
  margin-bottom: 10px;
`

const InputContainer = styled(View)`
  flex: ${Platform.OS === 'web' ? 0 : 1};
  padding: ${Platform.OS === 'web' ? 135 : 20}px 20px;

  align-items: center;
  justify-content: center;
`

const Input = styled(TextInput)`
  ${props => props.theme.fonts.accountPercent};
  color: ${props => props.theme.colors.primary};

  max-width: 400px;
  text-align: center;

  border-bottom-width: 2px;
  border-style: solid;
  border-bottom-color: ${props => props.theme.colors.primary};

  :focus {
    outline: none;
    border: transparent;
  }
`

const Validate = styled(TouchableOpacity)`
  align-items: center;
  padding: 15px;
  border-radius: ${Platform.OS === 'web' ? 12 : 0}px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: ${props => props.theme.colors.primary};

  margin: 0px ${Platform.OS === 'web' ? 100 : 0}px;
`

const ValidateText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: #fff;
  font-weight: bold;
`

const WarningContainer = styled(View)`
  position: absolute;
  top: 0;
  left: 25;

  flex-direction: row;
  align-items: center;
`

const WarningText = styled(Text)`
  ${props => props.theme.fonts.genericBody2};
  color: ${props => props.theme.colors.info};
  font-weight: bold;
  margin: 0px 15px;
`

const LabelText = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondaryText};
`

const InfoContainer = styled(View)`
  flex: ${Platform.OS === 'web' ? 0 : 1};
  padding: ${Platform.OS === 'web' ? 135 : 20}px 20px;
`

const InfoBadgeContainer = styled(TouchableOpacity)`
  margin-top: 20px;
  justify-content: center;
`

const InfoBadgeText = styled(Text)`
  ${props => props.theme.fonts.genericLabel};
  text-decoration: underline;
  color: ${props => props.theme.colors.secondaryText};
`

const InstructionBadgeTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle4Bold};
  color: ${props => props.theme.colors.secondaryText};
  margin-bottom: 16px;
`

const InstructionBadgeText = styled(Text)`
  ${props => props.theme.fonts.genericBody3};

  color: ${props => props.theme.colors.secondaryText};
`

const UnderstoodButtonContainer = styled(View)`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`

const ImportantInfoContainer = styled(View)`
  margin-top: 25px;
  align-items: center;
`

const ImportantInfo = styled(InstructionBadgeText)`
  margin-top: 5px;
  text-align: center;
`

let root: RootComponent
let rootActive = false

const BadgeInput = {
  open: (conf: Props) => {
    if (!rootActive) {
      rootActive = true
      root = new RootComponent(<Component {...conf} />)

      BackHandler.addEventListener('hardwareBackPress', BadgeInput.onRequestClose)
    }
  },

  close: () => {
    if (rootActive && root) {
      root.destroy()
      rootActive = false
    }
  },

  onRequestClose: () => {
    BackHandler.removeEventListener('hardwareBackPress', BadgeInput.onRequestClose)
    BadgeInput.close()

    return true
  },
}

export { Component }
export default BadgeInput
