import * as React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import styled from '@theme/styled-components'

interface Props {
  label: string
  color: 'orange' | 'blue' | 'red' | 'white'
  size: 'tall' | 'small'
  onPress: () => void
  uppercase?: boolean
  disabled?: boolean
  boldText?: boolean
}

const RectangleButton = ({ label, color, size, uppercase, onPress, disabled = false, boldText = false }: Props) => {
  const formatedLabel = uppercase ? label.toUpperCase() : label
  return (
    <Container
      activeOpacity={0.8}
      color={color}
      size={size}
      onPress={onPress}
      disabled={!!disabled}
      disabledColor={!!disabled}
    >
      <Label buttonColor={color} size={size} boldText={boldText}>
        {formatedLabel}
      </Label>
    </Container>
  )
}

export default RectangleButton

const Container = styled(TouchableOpacity)<{
  color: 'orange' | 'blue' | 'red' | 'white'
  size: 'tall' | 'small'
  disabledColor: boolean
}>`
  background-color: ${props =>
    props.disabledColor
      ? props.theme.colors.primaryLight
      : props.color === 'orange'
      ? props.theme.colors.tertiaryLight
      : props.color === 'red'
      ? props.theme.colors.info
      : props.color === 'blue'
      ? props.theme.colors.primary
      : props.theme.colors.counterPrimary};
  border-radius: 10px;
  padding: ${props =>
    props.color === 'white'
      ? props.size === 'tall'
        ? '15px 39px'
        : '14px 34px'
      : props.size === 'tall'
      ? '16px 40px'
      : '15px 35px'};
  align-items: center;
  ${props => props.color === 'white' && `border: 1px solid ${props.theme.colors.primary}`}
`

const Label = styled(Text)<{
  size: 'tall' | 'small'
  buttonColor: 'orange' | 'blue' | 'red' | 'white'
  boldText: boolean
}>`
  ${props =>
    props.size === 'tall'
      ? props.boldText
        ? props.theme.fonts.genericBody3Bold
        : props.theme.fonts.genericBody3
      : props.theme.fonts.genericLabel}
  /* La couleur du texte selon la couleur en background du bouton */
  color: ${props =>
    props.buttonColor === 'orange'
      ? props.theme.colors.tertiary
      : props.buttonColor === 'white'
      ? props.theme.colors.primary
      : 'white'};
`
