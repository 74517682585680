import * as React from 'react'
import { View, ScrollView, Text, Image, TouchableOpacity, Platform, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import Root from '@components/root/Root'
import Animators from '@components/root/Animators'
import InfoDetail from './InfoDetail'

import useI18n from '@store/i18n/useI18n'
import { useStore } from 'effector-react'
import * as infoStore from '@store/info/info'

import api from '@api/api'

import config from '@app/config'

import analytics from '@utils/analytics'

interface Props {
  navigator: Navigation
}

type PicturePosition = {
  itemId: string
  x: number
  y: number
}

const InfoItem = ({
  item,
  onPress,
  getImagePosition,
}: {
  item: EditorialSimple
  onPress: () => void
  getImagePosition: (p: PicturePosition) => void
}) => {
  const ref = React.useRef<Image>(null)
  const onLayout = React.useMemo(
    () => () => {
      //Timeout de 0.5 sec pour attendre le positionnement final des images avant de récuperer leur position x et y.
      setTimeout(() => {
        //On récupère la position absolue x et y de l'image de l'article
        ref.current?.measureInWindow((x, y) => {
          getImagePosition({ itemId: item.id, x, y })
        })
      }, 500)
    },
    [ref, getImagePosition]
  )

  return (
    <Item key={item.id} activeOpacity={0.9} onLayout={onLayout} onPress={onPress}>
      <Picture
        ref={ref}
        source={{ uri: item.photos[0] ? `${config.SERVER_PREFIX}${item.photos[0].url}` : '' }}
        resizeMode="cover"
      />
      <ItemContent>
        <ItemTitle numberOfLines={2}>{item.title}</ItemTitle>
        <ItemDescription>{item.shortDescription}</ItemDescription>
      </ItemContent>
    </Item>
  )
}

const InfoScreen = ({ navigator }: Props) => {
  const [loading, setLoading] = React.useState(true)
  const [current, setCurrent] = React.useState<EditorialDetail>() // utilisé uniquement pour le web
  const [picturesPosition, setPicturesPosition] = React.useState<{ [key: string]: PicturePosition }>({}) //contient les positions x et y de l'image de chaque article
  const [scrollOffset, setScrollOffset] = React.useState(0)

  const i18n = useI18n()
  const { infos } = useStore(infoStore.store)

  React.useEffect(() => {
    analytics.onScreen('Infos pratiques', 'InfoScreen')

    api.edito
      .all()
      .then(ed => {
        if (ed.Editorials) {
          // on mets dans le store uniquement les editos qui possèdent une shortDescription.
          infoStore.actions.setInfos(ed.Editorials.filter(e => !!e.shortDescription))
        }
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  const onItemSelected = (edito: EditorialSimple) => {
    // on récupère dans le state la position de l'image de l'article sur lequel l'utilisateur a cliqué
    const currentPicturePosition = picturesPosition[edito.id]
    //On renseigne la position x d'où doit démarrer l'animation
    const startX = !!currentPicturePosition ? currentPicturePosition.x : 1
    //On renseigne la position y d'où doit démarrer l'animation.
    // donc la coordonnée y de l'image - le scrollOffset (si l'utilisateur a scrollé avant de cliquer)
    const startY = !!currentPicturePosition ? Math.max(currentPicturePosition.y - scrollOffset, 1) : 1
    api.edito.get(edito.id).then(detail => {
      if (Platform.OS === 'web') {
        //uniquement sur le web, on met dans le state les détails de l'article
        setCurrent(detail)
      } else {
        //on lance l'animation qui va aggrandir l'image de l'article
        InfoDetail.open({
          info: detail,
          startX,
          startY,
        })
      }
    })
  }

  const WebRender = () => {
    if (current) {
      return InfoDetail.open({ info: current, startX: 0, startY: 0 })
    }

    return undefined
  }

  return (
    <Root
      header
      headerTitle={i18n.t('screens.info.title')}
      navigator={navigator}
      fixed
      renderContent={Platform.OS === 'web' ? WebRender : undefined}
      hPadding={10}
    >
      {loading ? (
        <CenteredContainer>
          <ActivityIndicator size="large" color={Theme.colors.primary} />
        </CenteredContainer>
      ) : infos.length === 0 ? (
        <CenteredContainer>
          <Empty>{i18n.t('screens.info.empty')}</Empty>
        </CenteredContainer>
      ) : (
        <ScrollView
          contentContainerStyle={{ paddingBottom: 20 }}
          // à chaque Scroll de l'uilitsateur, on enregistre dans le state la valeur y du ScrollOffset
          onScroll={event => setScrollOffset(event.nativeEvent.contentOffset.y)}
        >
          {/* Animator sert à faire apparaître les articles progressivement (500 ms) à l'ouverture de la page */}
          <Animators>
            {infos.map(item => (
              <InfoItem
                key={item.id}
                item={item}
                getImagePosition={picturePosition => {
                  //Après avoir récupéré la position x et y de l'image de l'article, on la stocke dans le state
                  setPicturesPosition(picturesPosition => ({ ...picturesPosition, [item.id]: picturePosition }))
                }}
                onPress={() => {
                  onItemSelected(item)
                }}
              />
            ))}
          </Animators>
        </ScrollView>
      )}
    </Root>
  )
}

export default InfoScreen

const CenteredContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const Item = styled(TouchableOpacity)`
  margin: 0px 20px;
  margin-top: 30px;
  flex-direction: row;
`

const Picture = styled(Image)`
  height: 110px;
  width: 110px;
  border-radius: 15px;
  margin-right: 30px;
`

const ItemTitle = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.primaryDark};
  font-weight: bold;
  margin: 8px 0px;
`

const ItemDescription = styled(Text)`
  ${props => props.theme.fonts.genericBody1};
  color: ${props => props.theme.colors.secondaryText};
  flex: 1;
`

const ItemContent = styled(View)`
  flex: 1;
  align-items: flex-start;
`

const Empty = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.secondaryText};
  text-align: center;
  font-weight: bold;
`
