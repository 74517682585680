import * as React from 'react'

import posed from 'react-pose'

interface Props {
  children?: (JSX.Element | undefined | boolean)[]
  animation?: 'fade_bottom' | 'fade_right'
}

const Animators = ({ children = [], animation = 'fade_bottom' }: Props) => {
  const [open, setOpen] = React.useState(false)
  const items = children.filter(Boolean)

  React.useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Overlay key={items.length} pose={open ? 'open' : 'closed'}>
      {items.map((child, idx) =>
        animation === 'fade_bottom' ? (
          <ItemFadeBottom key={`animated_${idx}`}>{child}</ItemFadeBottom>
        ) : (
          <ItemFadeRight key={`animated_${idx}`}>{child}</ItemFadeRight>
        )
      )}
    </Overlay>
  )
}

export default Animators

const Overlay = posed.div({
  open: {
    delayChildren: 200,
    staggerChildren: 50,
  },
})

const ItemFadeBottom = posed.div({
  open: { y: 0, opacity: 1, transition: { duration: 500, type: 'tween', stiffness: 200 } },
  closed: { y: 100, opacity: 0 },
})

const ItemFadeRight = posed.div({
  open: { x: 0, opacity: 1, transition: { duration: 500, type: 'tween', stiffness: 200 } },
  closed: { x: 100, opacity: 0 },
})

const ItemFadeTop = posed.div({
  open: { y: 100, opacity: 1, transition: { duration: 500, type: 'tween', stiffness: 200 } },
  closed: { y: 0, opacity: 0 },
})

const ItemFade = posed.div({
  open: { opacity: 1, transition: { duration: 500, type: 'tween', stiffness: 200 } },
  closed: { opacity: 0 },
})

interface AnimationProps {
  children?: any
  animation?: 'fade_bottom' | 'fade_right' | 'fade_top' | 'fade'
}

export const Animation = ({ animation = 'fade_bottom', children }: AnimationProps) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setOpen(true)
  }, [])

  return animation === 'fade_bottom' ? (
    <ItemFadeBottom pose={open ? 'open' : 'closed'}>{children}</ItemFadeBottom>
  ) : animation === 'fade_right' ? (
    <ItemFadeRight pose={open ? 'open' : 'closed'}>{children}</ItemFadeRight>
  ) : animation === 'fade_top' ? (
    <ItemFadeTop pose={open ? 'open' : 'closed'}>{children}</ItemFadeTop>
  ) : (
    <ItemFade pose={open ? 'open' : 'closed'}>{children}</ItemFade>
  )
}
