import { AppRegistry } from 'react-native'
import App from './src/App'
import { name as appName } from './app.json'
const BeVietnamRegular = require('./android/app/src/main/assets/fonts/BeVietnam-Regular.ttf')
const InterRegular = require('./android/app/src/main/assets/fonts/Inter-Regular.ttf')
const BeVietnamBold = require('./android/app/src/main/assets/fonts/BeVietnam-Bold.ttf')
const InterBold = require('./android/app/src/main/assets/fonts/Inter-Bold.ttf')

const fonts = `
@font-face {
  src: url(${BeVietnamRegular});
  font-family: BeVietnam-Regular;
}
@font-face {
  src: url(${InterRegular});
  font-family: Inter-Regular;
}
@font-face {
  src: url(${BeVietnamBold});
  font-family: BeVietnam-Bold;
}
@font-face {
  src: url(${InterBold});
  font-family: Inter-Bold;
}
`

const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = fonts
} else {
  style.appendChild(document.createTextNode(fonts))
}
document.head.appendChild(style)

AppRegistry.registerComponent(appName, () => App)

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
})
