import * as React from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'

import api from '@api/api'

import Root from '@components/root/Root'
import { Animation } from '@components/root/Animators'
import Button from '@components/button/RectangleButton'
import Icon from '@components/icons/Icons'
import Alert from '@components/alert/Alert'

interface Props {
  navigator: Navigation
  booking: BookingItem
}

const BookingDetails = ({ navigator, booking }: Props) => {
  const i18n = useI18n()
  const [loading, setLoading] = React.useState(false)

  const cancelBooking = () => {
    Alert.open({
      title: i18n.t('screens.booking.cancelBooking'),
      text: i18n.t('screens.booking.confirmCancel'),
      buttons: [
        {
          label: i18n.t('common.yes'),
          action: () => {
            setLoading(true)
            api.booking
              .cancelBooking(booking.id)
              .then(navigator.goBack)
              .catch(() => {
                setLoading(false)
                Alert.close()

                Alert.open({
                  title: i18n.t('common.warning'),
                  text: i18n.t('errors.tryAgain'),
                  buttons: [
                    {
                      label: i18n.t('common.ok'),
                      action: Alert.close,
                    },
                  ],
                  cancelable: true,
                  centerButtons: true,
                  darkBackground: true,
                })
              })
            Alert.close()
          },
        },
        { label: i18n.t('common.no'), action: Alert.close },
      ],
      cancelable: true,
      darkBackground: true,
    })
  }

  if (loading) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.bookingDetails')}>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  return (
    <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.bookingDetails')}>
      <>
        <Animation>
          <MainContainer>
            <BookingDate>{i18n.t('screens.booking.fullDate', { date: new Date(booking.date) })}</BookingDate>

            <AlignIconText>
              <Icon name="location" size={16} />
              <IconDescription>{booking.name}</IconDescription>
            </AlignIconText>

            <AlignIconText>
              <Icon name="clock" size={16} />
              <IconDescription>
                {i18n.t('screens.booking.scheduleTime', {
                  date1: new Date(booking.startHour),
                  date2: new Date(booking.endHour),
                })}
              </IconDescription>
            </AlignIconText>

            {!!booking.activity && (
              <AlignIconText>
                <Icon name="blockchain" size={16} />
                <IconDescription>{booking.activity}</IconDescription>
              </AlignIconText>
            )}

            {!!booking.price && (
              <AlignIconText>
                <Icon name="coins" size={16} />
                <IconDescription>{i18n.t('screens.booking.price', { price: booking.price })}</IconDescription>
              </AlignIconText>
            )}
          </MainContainer>
        </Animation>

        <ButtonContainer>
          <Button
            label={i18n.t('screens.booking.cancelBooking')}
            color="blue"
            size="tall"
            onPress={cancelBooking}
            boldText
          />
        </ButtonContainer>
      </>
    </Root>
  )
}

// CONTAINERS

const MainContainer = styled(View)`
  padding: 24px 24px 0px;
`
const ButtonContainer = styled(View)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  padding: 0px 20px;
`
const AlignIconText = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

// TEXTES

const BookingDate = styled(Text)`
  ${props => props.theme.fonts.genericTitle3Bold};
  color: ${props => props.theme.colors.primaryText};
  text-transform: capitalize;
  margin-bottom: 24px;
`
const IconDescription = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.primaryText};
  margin-left: 6px;
`

export default BookingDetails
