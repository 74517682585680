import { Store, createEvent } from 'effector'
import Storage from './storage'

import * as newsStore from './news/news'
import * as infoStore from './info/info'
import * as authStore from './auth/auth'
import * as userStore from './user/user'
import * as domoticStore from './domotic/domotic'

const stores: Store<any>[] = [newsStore.store, infoStore.store, authStore.store, userStore.store, domoticStore.store]

export const persist = (): Promise<void> => {
  const VERSION = '1'
  const VERSION_KEY = 'persist:version'

  return Storage.getItem(VERSION_KEY).then(version => {
    return (version === VERSION ? Promise.resolve(true) : Storage.setItem(VERSION_KEY, VERSION).then(() => false)).then(
      reload => {
        // inspiré de https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
        const rehydrate = createEvent('REHYDRATE')

        const persistStore = <State>(store: Store<State>): Promise<void> => {
          const name = store.shortName
          const persistKey = `persist:${name}`

          return (
            reload
              ? Storage.getItem(persistKey).then(snapshot => {
                  if (snapshot) {
                    store.on(rehydrate, () => JSON.parse(snapshot))
                    rehydrate()
                  }
                })
              : Promise.resolve()
          ).then(() => {
            store.watch(state => {
              Storage.setItem(persistKey, JSON.stringify(state))
            })
          })
        }

        return Promise.all(stores.map(persistStore)).then()
      }
    )
  })
}

export const reset = () => {
  // put reset actions
}
