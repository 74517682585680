import * as React from 'react'
import { Text, TouchableOpacity, Image, View, Dimensions } from 'react-native'
import styled from '@theme/styled-components'

import Root from '@components/root/Root'
import Animators, { Animation } from '@components/root/Animators'
import ResponsiveMainContainer from '@components/responsive/ResponsiveMainContainer'

import RestaurantHeaderRefill from '@screens/badge/RestaurantHeaderRefill'

import HomeNews from './HomeNews'
import ImageModal from '@components/image/ImageModal'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as userStore from '@store/user/user'

import api from '@api/api'

import useBreakPoints from '@layout/breakpoint'

interface Props {
  navigator: Navigation
}

const HEIGHT = Dimensions.get('window').height

const Home = ({ navigator }: Props) => {
  const user = useReducer(userStore.store, s => s.user)
  const [breakpoints] = useBreakPoints()

  const i18n = useI18n()

  React.useEffect(() => {
    api.account.get().then(userStore.actions.setUser)
  }, [])

  const renderContent = () => {
    return (
      <Responsive scrollable>
        <HomeNews navigator={navigator} />
      </Responsive>
    )
  }

  const renderPageTitle = () => {
    return (
      <>
        <TouchableOpacity activeOpacity={0.9} onPress={() => navigator.push('account')}>
          <Title>
            {i18n.t('screens.home.hello')}
            <Name>{user && user.firstName}</Name>,
          </Title>
        </TouchableOpacity>
        <Welcome>{i18n.t('screens.home.welcome')}</Welcome>
      </>
    )
  }

  const renderRefill = () => {
    return (
      <RefillContainer>
        <RestaurantHeaderRefill navigator={navigator} />
      </RefillContainer>
    )
  }

  if (breakpoints > 850) {
    return (
      <React.Fragment>
        <Root fixed renderContent={renderContent} navigator={navigator} flexContent={2}>
          <Responsive>
            <Animators animation="fade_bottom">
              {renderPageTitle()}
              <ImageContainer
                factor={breakpoints > 850 ? 0.8 : 0.4}
                activeOpacity={0.8}
                onPress={() => ImageModal.open({ source: require('@assets/images/building.png') })}
              >
                <Img
                  factor={breakpoints > 850 ? 0.8 : 0.4}
                  source={require('@assets/images/building.png')}
                  resizeMode="contain"
                />
              </ImageContainer>
            </Animators>
          </Responsive>
        </Root>
        {renderRefill()}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Root fixed navigator={navigator}>
          <Responsive scrollable>
            <Animation animation="fade_bottom">{renderPageTitle()}</Animation>
            <HomeNews navigator={navigator} />
          </Responsive>
        </Root>
        {renderRefill()}
      </React.Fragment>
    )
  }
}

export default Home

const Title = styled(Text)`
  ${props => props.theme.fonts.genericTitle1};
  color: ${props => props.theme.colors.primaryText};
`

const Name = styled(Title)`
  color: ${props => props.theme.colors.primary};
`

const Welcome = styled(Text)`
  ${props => props.theme.fonts.genericBody3};
  color: ${props => props.theme.colors.secondaryText};
`

const Img = styled(Image)<{ factor: number }>`
  flex: 1;
`

const RefillContainer = styled(View)`
  position: absolute;
  top: -40px;
  right: -30px;
  height: 156px;
`
const Responsive = styled(ResponsiveMainContainer)`
  flex: 1;
  padding: 40px 0px;
`

const ImageContainer = styled(TouchableOpacity)<{ factor: number }>`
  height: ${props => props.factor * HEIGHT}px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.counterPrimary};
  overflow: hidden;
  margin: 20px 0px;
`
