import * as React from 'react'
import SVG from 'react-inlinesvg'

interface Props {
  name: IconName
  color?: string
  size?: number
  height?: number
}

const icons: { [key in IconName]: string } = {
  activity: require('./svg/activity.svg'),
  alert: require('./svg/alert.svg'),
  arrowUp: require('./svg/arrow_up.svg'),
  back: require('./svg/back.svg'),
  bluetooth: require('./svg/bluetooth.svg'),
  card: require('./svg/card.svg'),
  chevronDown: require('./svg/chevronDown.svg'),
  chevronRight: require('./svg/chevronRight.svg'),
  chevronLeft: require('./svg/chevronLeft.svg'),
  clock: require('./svg/clock.svg'),
  close: require('./svg/close.svg'),
  domotic: require('./svg/domotic.svg'),
  heart: require('./svg/heart.svg'),
  home: require('./svg/home.svg'),
  infos: require('./svg/infos.svg'),
  invert: require('./svg/invert.svg'),
  lock: require('./svg/lock.svg'),
  logo: require('./svg/logo.svg'),
  map: require('./svg/map.svg'),
  menu: require('./svg/menu.svg'),
  minus: require('./svg/minus.svg'),
  next: require('./svg/next.svg'),
  pen: require('./svg/pen.svg'),
  plus: require('./svg/plus.svg'),
  power: require('./svg/power.svg'),
  restaurant: require('./svg/restaurant.svg'),
  room: require('./svg/room.svg'),
  settings: require('./svg/settings.svg'),
  share: require('./svg/share.svg'),
  sliders: require('./svg/sliders.svg'),
  up: require('./svg/up.svg'),
  user: require('./svg/user.svg'),
  checkFill: require('./svg/checkFill.svg'),
  checkEmpty: require('./svg/checkEmpty.svg'),
  gps: require('./svg/gps.svg'),
  lightbulbOn: require('./svg/lightbulb_on.svg'),
  lightbulbOff: require('./svg/lightbulb_off.svg'),
  wind: require('./svg/wind.svg'),
  pin: require('./svg/pin.svg'),
  addAgenda: require('./svg/add_agenda.svg'),
  phone: require('./svg/phone.svg'),
  wellBeing: require('./svg/well_being.svg'),
  location: require('./svg/location.svg'),
  blockchain: require('./svg/blockchain.svg'),
  coins: require('./svg/coins.svg'),
  'box-n-services': require('./svg/box-n-services.svg'),
}

export default ({ name, size, color, height }: Props) => {
  const iconSrc = icons[name || 'alert']

  return (
    <div style={{ height: size, width: size, color }}>
      <SVG
        src={iconSrc}
        preProcessor={code =>
          code
            .replace(/ width=".*?"/g, ` width="${size}"`)
            .replace(/height=".*?"/g, `height="${height ? height : size}"`)
        }
      />
    </div>
  )
}
