import { isSameDay } from 'date-fns'

interface Props {
  news: ArticleDetail
  i18n: I18nState
}

export const RenderDateAndHourText = ({ news, i18n }: Props) => {
  if (news.type === 'event' && !!news.startDate && !!news.endDate) {
    if (isSameDay(new Date(news.startDate), new Date(news.endDate))) {
      if (!news.entireDay && !!news.endTime && !!news.startTime) {
        // Exemple : le 04 janvier, de 11h00 à 14h00
        return i18n.t('screens.home.actu.dateAndTime.oneDayWithHours', {
          date: new Date(news.startDate),
          startTime: news.startTime,
          endTime: news.endTime,
        })
      } else {
        // Exemple : le 04 janvier
        return i18n.t('screens.home.actu.dateAndTime.oneDayWithoutHours', {
          date: new Date(news.startDate),
        })
      }
    } else {
      if (!news.entireDay && !!news.endTime && !!news.startTime) {
        // Exemple : du 04 janvier à 11h00 au 11 janvier à 09h30
        return i18n.t('screens.home.actu.dateAndTime.severalDaysWithHours', {
          startDate: new Date(news.startDate),
          endDate: new Date(news.endDate),
          startTime: news.startTime,
          endTime: news.endTime,
        })
      } else {
        // Exemple : du 04 janvier au 11 janvier
        return i18n.t('screens.home.actu.dateAndTime.severalDaysWithoutHours', {
          startDate: new Date(news.startDate),
          endDate: new Date(news.endDate),
        })
      }
    }
  } else if (news.type === 'info') {
    return i18n.t('screens.home.actu.publicationDate', { date: new Date(news.publicationDate) })
  }
}
