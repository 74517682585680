import * as React from 'react'
import { View, Text, FlatList, ActivityIndicator } from 'react-native'
import styled from '@theme/styled-components'
import Theme from '@theme/Theme'

import useI18n from '@store/i18n/useI18n'

import api from '@api/api'

import Root from '@components/root/Root'
import { Animation } from '@components/root/Animators'
import BookingInfo from './BookingInfo'
import Alert from '@components/alert/Alert'

interface Props {
  navigator: Navigation
}

const MyBookings = ({ navigator }: Props) => {
  const i18n = useI18n()
  const [bookings, setBookings] = React.useState<BookingItem[]>()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    getMyBookings()
  }, [])

  const getMyBookings = () => {
    api.booking
      .getUsersBookings()
      .then(res => {
        setBookings(res.bookings.filter(b => !b.cancelled))
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  const cancelBooking = (bookingId: string) => {
    Alert.open({
      title: i18n.t('screens.booking.cancelBooking'),
      text: i18n.t('screens.booking.confirmCancel'),
      buttons: [
        {
          label: i18n.t('common.yes'),
          action: () => {
            setLoading(true)
            api.booking
              .cancelBooking(bookingId)
              .then(getMyBookings)
              .catch(() => {
                setLoading(false)
                Alert.close()

                Alert.open({
                  title: i18n.t('common.warning'),
                  text: i18n.t('errors.tryAgain'),
                  buttons: [
                    {
                      label: i18n.t('common.ok'),
                      action: Alert.close,
                    },
                  ],
                  cancelable: true,
                  centerButtons: true,
                  darkBackground: true,
                })
              })
            Alert.close()
          },
        },
        { label: i18n.t('common.no'), action: Alert.close },
      ],
      cancelable: true,
      darkBackground: true,
    })
  }

  const renderBooking = ({ item }: { item: BookingItem }) => (
    <Animation key={item.id}>
      <BookingContainer>
        <BookingInfo navigator={navigator} booking={item} onCancel={cancelBooking} />
      </BookingContainer>
    </Animation>
  )

  if (loading) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <ActivityIndicator size="large" color={Theme.colors.primary} />
      </Root>
    )
  }

  if (error) {
    return (
      <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.title')}>
        <EmptyContainer>
          <ErrorText>{i18n.t('errors.tryAgain')}</ErrorText>
        </EmptyContainer>
      </Root>
    )
  }

  return (
    <Root fixed navigator={navigator} header headerTitle={i18n.t('screens.booking.myBookings')}>
      <FlatList
        data={bookings}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
        renderItem={renderBooking}
        ItemSeparatorComponent={() => (
          <Animation>
            <Separator />
          </Animation>
        )}
      />
    </Root>
  )
}

// CONTAINERS

const BookingContainer = styled(View)`
  padding: 0px 24px;
`
const EmptyContainer = styled(View)`
  padding: 24px;
  justify-content: center;
  align-items: center;
`

// TEXTES

const ErrorText = styled(Text)`
  ${props => props.theme.fonts.genericText};
`

// AUTRES

const Separator = styled(View)`
  height: 1px;
  background-color: ${props => props.theme.colors.separator};
`

export default MyBookings
