import * as React from 'react'
import { View } from 'react-native'
import styled from '@theme/styled-components'

import Icon from '@components/icons/Icons'

interface Props {
  size: number
  name: IconName
  iconColor: string
  bgColor?: string
  offset?: number
}

const CircleIcon = ({ size, name, iconColor, bgColor = '#000', offset = 10 }: Props) => {
  return (
    <Circle width={size + offset * 2} bgColor={bgColor}>
      <Icon name={name} color={iconColor} size={size} />
    </Circle>
  )
}

export default CircleIcon

const Circle = styled(View)<{ width: number; bgColor: string }>`
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor};
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  border-radius: ${props => props.width / 2}px;
`
