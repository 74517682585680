const push = {
  start: (userId: string, lang: string): Promise<void> => {
    return Promise.resolve()
  },

  stop: () => {
    return null
  },

  updateTopics: (userId: string, lang: string) => {
    return Promise.resolve()
  },

  getToken: () => {
    return Promise.resolve('')
  },
}

export default push
