import * as React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import styled from '@theme/styled-components'

interface Props {
  data: string[]
  value: number
  onItemSelected: (item: string, idx: number) => void
}

const RadioButtons = ({ data, value, onItemSelected }: Props) => {
  const renderItem = (item: string, index: number) => {
    return (
      <Line activeOpacity={0.8} onPress={() => onItemSelected(item, index)}>
        <RadioContainer>
          <Radio selected={index === value} />
        </RadioContainer>
        <RadioValue>{item}</RadioValue>
      </Line>
    )
  }

  return <MainContainer>{data.map(renderItem)}</MainContainer>
}

const Line = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin: 5px;
  flex: 1;
`

const RadioContainer = styled(View)`
  background-color: ${props => props.theme.colors.primaryLight};
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-right: 15px;
`

const Radio = styled(View)<{ selected: boolean }>`
  background-color: ${props => (props.selected ? props.theme.colors.primary : 'transparent')};
  height: 24px;
  width: 24px;
  border-radius: 12px;

  position: absolute;
  top: 4px;
  left: 4px;
`

const RadioValue = styled(Text)`
  ${props => props.theme.fonts.genericTitle3};
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

const MainContainer = styled(View)`
  flex-direction: row;
`

export default RadioButtons
